import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { APIURL } from '../../config/config'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import RetailerForm from './RetailerForm'
import { AlertSuccess } from '../Alert/Alert'

class RetailerList extends Component{
  constructor (props) {
    super(props);
    this.state = {
      lists: [],
      load_data: true,
      danger: false,
      retailer_id: 0,
      retailer_name: '',
      action: "บันทึก",
      user: {},
      retail: {} ,
    }

    this.loadData = this.loadData.bind(this);
    this.deleteRetail = this.deleteRetail.bind(this);
    this.editRetail = this.editRetail.bind(this);
    this.toggleDanger = this.toggleDanger.bind(this);
    this.toggleDangerDelete = this.toggleDangerDelete.bind(this);
    this.addRetail = this.addRetail.bind(this);
    this.updateRetail = this.updateRetail.bind(this);
    this.onReset = this.onReset.bind(this);

  }

  componentDidMount () {
    this.loadData()
  }

  componentWillMount () {
    this.setState({
      user: JSON.parse(this.props.user),
    })
  }

  loadData () {
    axios.get(APIURL + '/retailer/')
      .then(res => {
        this.setState({
          lists: res.data,
          load_data: false,
          retailer_id: 0,
          retailer_name: '',
          danger: false,
          retail: {} ,
        })
      }).catch(error => {
      this.setState({lists: [], load_data: true, retailer_id: 0, danger: false, retail: {}})
    })
  }

  deleteRetail (retail) {
    this.setState({
      retailer_id: retail.retailer_id,
      retailer_name: retail.retailer_name
    }, () => this.toggleDanger())

  }

  editRetail (retail) {
    this.setState({
      action: "แก้ไข",
      retail: retail,
      retailer_id: retail.retailer_id
    });
  }

  toggleDanger () {
    this.setState({
      danger: !this.state.danger
    })
  }

  toggleDangerDelete (id) {
    axios.delete(APIURL + '/retailer/' + id).then(res => {
      if (res.status === 200) {
        AlertSuccess('ลบข้อมูลเรียบร้อยแล้ว')
        this.loadData()
        this.setState({action: "บันทึก"});
        this.props.onReloadSparePart()
      }
    })
  }

  addRetail(event){
    event.preventDefault()
    // let that = this;
    let retailer_name = event.target.retailer_name.value
    let retailer_address = event.target.retailer_address.value
    let retailer_tel = event.target.retailer_tel.value
    let username = this.state.user.user

    if(retailer_name===''){
      return ;
    }
    event.target.retailer_name.value = ""
    event.target.retailer_address.value = ""
    event.target.retailer_tel.value = ""

    let data = {
      retailer_name: retailer_name,
      retailer_address: retailer_address,
      retailer_tel : retailer_tel,
      username: username
    }

    // console.log(data)

    axios.post(APIURL + '/retailer', data)
      .then( res => {
        if(res.status === 200) {
          AlertSuccess('บันทึกเรียบร้อยแล้ว');
          this.loadData();
          this.setState({retail: {}, retailer_id: 0, action: "บันทึก"}, () => {
            // event.target.retailer_name.value = ""
            // event.target.retailer_address.value = ""
            // event.target.retailer_tel.value = ""
            this.props.onReloadSparePart()
          });
        }
      })
      .catch(error => {
        console.log('error: ', error)
      })
  }

  updateRetail(event){
    event.preventDefault()
    let that = this ;
    let retailer_name = event.target.retailer_name.value
    let retailer_address = event.target.retailer_address.value
    let retailer_tel = event.target.retailer_tel.value
    let username = this.state.user.user
    let retailer_id = this.state.retailer_id

    if(retailer_name===''){
      return ;
    }
    event.target.retailer_name.value = ""
    event.target.retailer_address.value = ""
    event.target.retailer_tel.value = ""

    axios.put(APIURL + '/retailer/'+retailer_id, {
      retailer_id: retailer_id,
      retailer_name: retailer_name,
      retailer_address: retailer_address,
      retailer_tel : retailer_tel,
      username: username
    })
      .then(res => {
        if(res.status === 200) {
          AlertSuccess('แก้ไขเรียบร้อยแล้ว');
          that.loadData()
          that.setState({retail: {}, retailer_id: 0, action: "บันทึก"});
        }
      })
      .catch(function (error) {
        console.log('error: ', error)
      })

  }

  onReset() {
    this.setState({load_data: true, retailer_id: 0, danger: false, retail: {}, action: "บันทึก"})
  }

  render(){

    let centerList;

    if (this.state.lists.length === 0){
      centerList = <tr><td colSpan={5} className="text-center"><span className="text-danger">ไม่พบข้อมูล</span></td></tr>
    }else {

      centerList = this.state.lists.map((retail, index) => {
        return (
          <tr key={index}>
            <td>{ index+1 }</td>
            <td>{ retail.retailer_name }</td>
            <td>{ retail.retailer_address }</td>
            <td>{ retail.retailer_tel }</td>
            <td className="text-right">


              <button className="btn btn-sm btn-warning btn-md-width"
                      onClick={() => this.editRetail(retail)}
              >
                <i className="fa fa-edit"/> แก้ไข </button>

              <br/>

              <button className="btn btn-sm btn-danger btn-md-width mt-2"
                      onClick={() => this.deleteRetail(retail)}
              >
                <i className="fa fa-remove"/> ลบ</button>

            </td>
          </tr>
        )
      });
    }

    return (
      <div className="card">
        <div className="card-block">
          <div className="row">

            <div className="col-sm-8">
              <div className="card card-accent-warning">
                <div className="card-header card-sparepart">
                  <strong className="text-title">ข้อมูลร้านค้าอะไหล่</strong>
                </div>
                <div className="card-block">
                  <table className="table table-striped">
                    <thead>
                    <tr>
                      <th className="text-left ">#</th>
                      <th className="text-left">ชื่อร้านค้า</th>
                      <th className="text-center">ที่อยู่</th>
                      <th className="text-center">เบอร์โทรศัพท์</th>
                      <th className="text-center">จัดการ</th>
                    </tr>
                    </thead>
                    <tbody>
                    { centerList }
                    </tbody>
                  </table>
                </div>
              </div>

            </div>

            <div className="col-sm-4">
              <RetailerForm
                onRetailSubmit={
                  {
                    add: this.addRetail.bind(this),
                    update: this.updateRetail.bind(this)
                  }
                }
                onReset={this.onReset}
                actionType={this.state.action}
                retail={this.state.action === 'บันทึก' ? {} : this.state.retail}
              />
            </div>
          </div>

          <Modal isOpen={this.state.danger} toggle={this.toggleDanger}
                 className={'modal-danger '}>
            <ModalHeader toggle={this.toggleDanger}>ลบข้อมูล</ModalHeader>
            <ModalBody>
              <strong> คุณต้องการลบข้อมูลร้านอะไหล่ {this.state.retailer_name} ใช่หรือไม่ ?? </strong>
              <br />(ข้อมูลจะไม่สามารถกู้คืนได้)
            </ModalBody>
            <ModalFooter>
              <Button color="primary"
                      onClick={() => this.toggleDangerDelete(this.state.retailer_id) }>ตกลง</Button>{' '}
              <Button color="secondary" onClick={this.toggleDanger}>ยกเลิก</Button>

            </ModalFooter>
          </Modal>

        </div>
      </div>
    )
  }

}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(RetailerList);