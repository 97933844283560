import React, { Component } from 'react'
import axios from 'axios'
import { APIURL } from '../../../config/config'
import {connect} from 'react-redux';
// import MaskedInput  from 'react-maskedinput'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import 'react-datepicker/dist/react-datepicker.css';
import 'moment/locale/th';
import NumberFormat from 'react-number-format';
import { Modal, ModalBody } from 'reactstrap'
import { AlertSuccess } from '../../Alert/Alert'
moment.locale('th');


class CarExpenseFormEdit extends Component {

    constructor (props) {
        super(props)

        this.state = {
            lists: [],
            load_data: true,
            danger: false,
            expense:'',
            branch:[],
            expense_type:[],
            income_expense_item:[],

            // startDateCarExpense: new Date() ,//moment().format('YYYY-MM-DD'),
            startDateExpense: new Date(this.props.expense.expense_date), // this.props.expense.expense_date

            w_expense_image_file: false,
            user: {},
            car: '',
            //selectedItem:'',
          expense_id: this.props.expense.expense_id,
          expense_name: this.props.expense.expense_name,
          expense_type_id: this.props.expense.expense_type_id,
          branch_id: this.props.expense.branch_id,
          expense_amount: this.props.expense.expense_amount,
          expense_amount_old: this.props.expense.expense_amount,
          income_expense_item_id: this.props.expense.income_expense_item_id,
          //
          width: 0,
          height: 0,
        }

        // this.handleDateCarExpense = this.handleDateCarExpense.bind(this)
        this.convertBBtoCC = this.convertBBtoCC.bind(this)
        this.onMaskedDateCarExpense = this.onMaskedDateCarExpense.bind(this)
        this.handleCarExpenseNameChange = this.handleCarExpenseNameChange.bind(this)
        this.handleDateExpense = this.handleDateExpense.bind(this)
        this.handleOnSubmitCarExpenseEdit = this.handleOnSubmitCarExpenseEdit.bind(this)
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  handleOnSubmitCarExpenseEdit(event) {
        event.preventDefault()

        // let that = this ;
        let car_id = this.props.car_id
        let expense_id = this.state.expense_id
        let expense_amount_old = this.state.expense_amount_old
        // let expense_date = this.state.startDateCarExpense
        let expense_date = moment(this.state.startDateExpense).format('YYYY-MM-DD')
        let branch_id = event.target.branch_id.value
        let income_expense_item_id = event.target.income_expense_item_id.value
        let expense_name = event.target.expense_name.value
        let expense_amount = event.target.expense_amount.value
        //let expense_image_file = this.state.expense_image_file
        //let expense_image_file = event.target.expense_image_file.value

        if(expense_amount===''){
            alert('กรุณากรอกจำนวนเงิน')
            this.refs.expense_amount.focus()
            return ;
        }

        if(income_expense_item_id===''){
            alert('กรุณาเลือกหมวดหมู่ค่าใช้จ่าย')
            this.refs.income_expense_item_id.focus()
            return ;
        }

        if(branch_id===''){
            alert('กรุณาเลือกสาขา')
            this.refs.branch_id.focus()
            return ;
        }

      if(event.target.expense_image_file.files.length === 0) { // nofile
        let data = {
          expense_date: expense_date,
          branch_id: branch_id,
          car_id: car_id,
          income_expense_item_id: income_expense_item_id,
          expense_name: expense_name,
          expense_amount: expense_amount,
          expense_type_id: '1', //ของรถ
          user_updated: this.state.user.user,
          expense_id: expense_id,
          expense_amount_old: expense_amount_old,
        }
        console.log(data)
        axios.post(APIURL + '/expense/car/edit', data)
          .then(res => {
            if (res.status === 200) {
              AlertSuccess('แก้ไขข้อมูลสำเร็จ');
              setTimeout(() => {
                this.props.onToggle();
                this.props.onReload();
              }, 1500)
            }
          })
          .catch(function (error) {
            console.log(error)
            //alert("error"+error)
          })
      }

    }

     handleDateExpense(date) {
       this.setState({
         startDateExpense: date
       });
     }


    // handleDateCarExpense(date) {
    //     this.setState({
    //         startDateCarExpense: moment(date).format('YYYY-MM-DD')
    //     });
    // }

    onMaskedDateCarExpense(e){
        let buddhist_date = e.target.value ;
        //alert('buddhist_date='+buddhist_date);
        if(buddhist_date.length===10){
            let cc_date = this.convertBBtoCC(buddhist_date)
            this.handleDateCarExpense(cc_date)
        }
    }

    convertBBtoCC(buddhist_date){ //dd-mm-yyyy to yyyy-mm-dd
        let date_arr=buddhist_date.split('-');
        let dd = date_arr[0];
        let mm = date_arr[1];
        let yyyy = date_arr[2]-543;
        let cc_date= yyyy+'-'+mm+'-'+dd ;
        if(moment(cc_date, 'YYYY-MM-DD', true).isValid()){
            //alert(cc_date);
            return cc_date ;
        }
        return moment() ;
    }

    /*formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    }*/

    /*makeIncomeExpenseItemName() {
        var item = this.state.tmp_income_expense_item

        var expense_name = ''

        if (item != '') {
            if (item.includes(item)) {
                expense_name = item
            }
        }

        this.setState({
            expense_name: expense_name
        });
    }*/

    /*handleIncomeExpenseItemChange (event) {
        //var income_expense_item_id = event.target.value
        var income_expense_item_name = event.target.options[event.target.selectedIndex].text

        var that = this ;

        //alert (income_expense_item_name)

        that.setState({
            expense_name: income_expense_item_name,
        })
    }
*/
    handleCarExpenseNameChange(){
        var expense_name = this.refs.expense_name.value
        this.setState({
            expense_name : expense_name
        });
    }

    loadData () {
        axios.get(APIURL + '/expense/')
            .then(res => {
                //alert("res"+res);
                this.setState({lists: res.data, })
            }).catch(error => {
            //alert("error"+error.message);
            this.setState({lists: []})
        })
    }

    loadBranchData () {
        axios.get(APIURL + '/branch/')
            .then(res => {
                this.setState({branch: res.data})
                //console.log(res.data)
                //alert (res.data)
            }).catch(error => {
            this.setState({branch: []})
        })
    }

    loadExpenseTypeData () {
        axios.get(APIURL + '/expense_type/')
            .then(res => {
                this.setState({expense_type: res.data})
                //console.log(res.data)
                //alert (res.data)
            }).catch(error => {
            this.setState({expense_type: []})
        })
    }

    loadIncomeExpenseItemData () {
        axios.get(APIURL + '/income_expense_item/')
            .then(res => {
                this.setState({income_expense_item: res.data})
                //console.log(res.data)
                //alert (res.data)
            }).catch(error => {
            this.setState({income_expense_item: []})
        })
    }

    componentDidMount() {

      this.setState({
        user: JSON.parse(this.props.user)
      });

      this.updateWindowDimensions();
      window.addEventListener('resize', this.updateWindowDimensions);

      this.loadData()
      this.loadBranchData()
      this.loadExpenseTypeData()
      this.loadIncomeExpenseItemData()

      let expense = this.props.expense
      console.log(expense)

    }

    componentWillMount () {

    }

    render(){

      let width = this.state.width

        // const { history,user } = this.props
        let branch_list = this.state.branch.map((branch,index)=>{
            return(
                <option key={index} value={branch.branch_id}>{branch.branch_name}</option>
            )
        })

        let income_expense_item_list = this.state.income_expense_item.map((income_expense_item,index)=>{
            return(
                <option key={index} value={income_expense_item.income_expense_item_id}>{income_expense_item.income_expense_item_name}</option>
            )
        })

        return (
          <Modal isOpen={this.props.isOpen}
                 toggle={this.props.onToggle}
                 className={'modal-lg ' + this.props.className}
                 style={width<=980 ?{maxWidth:800}:{maxWidth:1200}}>
            <ModalBody>
              <div className="card card-accent-warning">
                <form action="" method="post" onSubmit={this.handleOnSubmitCarExpenseEdit}>
                    <div className="card-header">
                        <strong className="text-title">แก้ไขข้อมูลค่าใช้จ่าย</strong>
                    </div>

                    <div className="card-block">
                        <div className="row">

                            <div className="form-group col-sm-6">
                                <label htmlFor="expense_date_text">วันที่ใช้จ่าย</label><br/>
                                {/*<MaskedInput
                                    mask="11-11-1111"
                                    name="expense_date_text"
                                    placeholder="dd-mm-yyyy"
                                    onChange={this.onMaskedDateCarExpense}
                                    className="form-control"
                                />*/}
                                <DatePicker
                                    type="text"
                                    className="form-control"
                                    name="expense_date_text"
                                    placeholder=""
                                    selected={this.state.startDateExpense}
                                    onChange={this.handleDateExpense}
                                    dateFormat="yyyy-MM-dd"
                                />

                            </div>

                            <div className="form-group col-sm-6">
                                <label htmlFor="branch">สาขา</label>
                                <select className="form-control"
                                        id="branch_id"
                                        ref="branch_id"
                                        value={this.state.branch_id}
                                        onChange={(e) => {
                                          this.setState({
                                            branch_id: Number(e.target.value)
                                          })
                                        }}
                                >
                                    {branch_list}
                                </select>
                            </div>


                            <div className="form-group col-sm-6">
                                <label htmlFor="income_expense_item">หมวดค่าใช้จ่าย</label>
                                <select
                                    className="form-control"
                                    id="income_expense_item_id"
                                    ref="income_expense_item_id"
                                    value={this.state.income_expense_item_id}
                                    onChange={(e) => {
                                      this.setState({
                                        income_expense_item_id: Number(e.target.value)
                                      });
                                    }}
                                    // onChange={this.handleIncomeExpenseItemChange.bind(this)}
                                    // name="form-field-name"
                                    // value={this.state.selectedItem}
                                    // onChange={this.handleOnChange}
                                    // options=
                                >
                                    {income_expense_item_list}
                                </select>
                            </div>

                            <div className="form-group col-sm-6">
                                <label htmlFor="expense_name">รายละเอียดค่าใช้จ่าย (พิมพ์เพิ่มเติมได้)</label>
                                <input type="text"
                                       className="form-control"
                                       id="expense_name"
                                       ref="expense_name"
                                       value={this.state.expense_name}
                                       placeholder=""
                                       onChange={this.handleCarExpenseNameChange}
                                />
                            </div>

                            <div className="form-group col-sm-12">
                                <label htmlFor="expense_amount">ยอดค่าใช้จ่าย</label>
                                <input
                                    type="text"
                                    className="form-control hidden"
                                    name="expense_amount"
                                    placeholder=""
                                    ref="expense_amount"
                                    value={this.state.expense_amount}
                                    onChange={()=>{}}
                                />
                                <NumberFormat
                                    className="form-control"
                                    thousandSeparator={true}
                                    prefix={'฿'}
                                    placeholder="ค่าใช้จ่าย"
                                    min={0}
                                    max={1000000000}
                                    step={1000}
                                    size={10}
                                    allowNegative={false}
                                    value={this.state.expense_amount}
                                    onValueChange = {(values) => {
                                        const {value} = values;
                                        this.setState({expense_amount: value})
                                    }}
                                />
                            </div>

                            <div className="form-group col-md-12 hidden">
                                <label htmlFor="expense_image_file">เอกสารแนบ </label>
                                <input type="file"
                                       id="expense_image_file"
                                       name="expense_image_file"
                                       accept="image/*"
                                       className="form-control"
                                       onChange={(e)=>{
                                           const { target } = e
                                           if(target.value.length > 0){
                                               this.setState({
                                                   w_expense_image_file: false
                                               });
                                           } else {
                                               //target.reset();
                                               this.setState({
                                                   w_expense_image_file: true
                                               });
                                           }

                                       }}
                                />
                                { this.state.w_expense_image_file ? <span  className="help-block text-danger">กรุณาเลือกเอกสารแนบ</span>: null }

                            </div>

                        </div>
                    </div>

                    <div className="card-footer text-right">
                        <button type="reset"
                                    onClick={this.props.onToggle}
                                    className="btn btn-sm btn-danger mr-2">
                                <i className="fa fa-refresh"/> ยกเลิก
                            </button>
                        <button type="submit"
                                className="btn btn-sm btn-warning">
                            <i className="fa fa-pencil"/> แก้ไข
                        </button>
                        {/*<input type="hidden" id="car_id" name="car_id" value={this.state.car.car_id}/>*/}
                    </div>

                </form>
            </div>
            </ModalBody>
          </Modal>
        )

    }
}


const mapStateToProps = (state) => ({
    user: state.auth.user,
    token: state.auth.token,
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(CarExpenseFormEdit);
