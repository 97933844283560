import React, { Component } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import DatePicker from 'react-datepicker'
import NumberFormat from 'react-number-format'
import moment from 'moment'
import axios from 'axios'
import { APIURL } from '../../config/config'
import Loading from '../Loading'
import { save } from 'save-file'
import { AlertError } from '../Alert/Alert'


class AgentByDate extends Component {
  constructor (props) {
    super(props)
    this.state = {
      width: 0,
      height: 0,
      date_start: new Date(),
      date_end: new Date(),
      agentCars: [],
      agentSum: 0
    }

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.handleDateStart = this.handleDateStart.bind(this);
    this.handleDateEnd = this.handleDateEnd.bind(this);
    this.loadSellByAgent = this.loadSellByAgent.bind(this);
    this.exportSellByAgent = this.exportSellByAgent.bind(this);

  }

  componentDidMount () {
    this.loadSellByAgent();
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  handleDateStart(date){
    this.setState({
      date_start: date
    })
  }

  handleDateEnd(date){
    this.setState({
      date_end: date
    })
  }

  loadSellByAgent() {
    if(this.state.date_start > this.state.date_end){
      AlertError('วันที่เริ่มต้น มากกว่า วันที่สิ้นสุด กรุณาตรวจสอบ')
      return
    }

    let start_date = moment(this.state.date_start).format('YYYY-MM-DD')
    let end_date = moment(this.state.date_end).format('YYYY-MM-DD')

    this.setState({
      isLoading: true
    })

    axios.get(APIURL + '/agent/bydate/'+start_date+'/'+end_date)
      .then(res => {
        let agentSum = 0

        if(res.data.length > 0) {
          agentSum =  res.data.reduce((acc, next) => acc + next.car_external_commission, 0)
        }

        this.setState({
          agentCars: res.data,
          agentSum: agentSum,
          isLoading: false
        })
      }).catch(error => {
        console.log('error=', error)
    })

  }

  exportSellByAgent() {
    if(this.state.date_start > this.state.date_end){
      AlertError('วันที่เริ่มต้น มากกว่า วันที่สิ้นสุด กรุณาตรวจสอบ')
      return
    }

    let start_date = moment(this.state.date_start).format('YYYY-MM-DD')
    let end_date = moment(this.state.date_end).format('YYYY-MM-DD')

    this.setState({
      isLoading: true
    }, () => {

      axios.get(APIURL + '/agent2/agentexport/'+start_date+'/'+end_date)
        .then(async res => {
          const excelBuffer = res.data.excelBuffer
          const currentDatetime = res.data.currentDatetime
          const fileName = 'รายการรถที่ขายผ่านนายหน้าตามวันที่_export_'+currentDatetime+'.xlsx'
          this.setState({
            isLoading: false
          })
          await save(excelBuffer, fileName)
        })

    })

  }

  render () {
    const {
      agentCars
    } = this.state
    let width = this.state.width
    const emptyData = <tr><td colSpan={5} className="text-center text-danger"> ไม่พบข้อมูล </td></tr>

    const agentCarsTr = this.state.agentCars.map((agentCar, index) => (
      <tr key={index}>
        <td className="text-center">{ index+1 }</td>
        <td>
          วันที่ขาย: { moment(agentCar.car_sell_date).locale('th').format('LL')}
          <br/>
          โดย: {agentCar.fullname}
        </td>
        <td>
          {agentCar.car_name} <br/>
          ทะเบียนรถ: {agentCar.car_license_plate_new} <br/>
          สี: {agentCar.car_color_name}
          <hr/>
          นายหน้า: {agentCar.agent_fullname} <br/>
          เบอร์โทร: {agentCar.agent_mobile}
        </td>
        <td>

          <table className="table2">
            <tbody>
            <tr>
              <td className="text-right">ราคาขาย : </td>
              <td className="text-right">
                <NumberFormat value={agentCar.real_sell_price + agentCar.car_external_commission}
                              displayType={'text'}
                              thousandSeparator={true}
                              prefix={'฿'}/>
              </td>
            </tr>
            <tr>
              <td className="text-right">ยอดจัด : </td>
              <td className="text-right">
                <NumberFormat
                  value={agentCar.rate_finance} displayType={'text'} thousandSeparator={true}
                  prefix={'฿'}/>
              </td>
            </tr>
            </tbody>
          </table>
        </td>
        <td>
          <NumberFormat
            value={agentCar.car_external_commission} displayType={'text'} thousandSeparator={true}
            prefix={'฿'}/>
        </td>
      </tr>
    ))

    const agentCarList = agentCars.length > 0 ? agentCarsTr : emptyData

    return (
      <div className="card">

        <Loading isLoading={this.state.isLoading} />

        <div className="card-block">
          <div className="row">
            <div className="col-sm-12 col-md-12">
              <div className="card card-accent-warning">
                <div className="card-header card-sparepart">
                  <strong className="text-title"> รายการรถที่ขายผ่านนายหน้าตามวันที่</strong>

                  {/*<button type="button"
                          onClick={() => {

                          }}
                          className="btn btn-info float-right">
                    <i className="icon-plus"/>&nbsp; เพิ่มข้อมูลค่าใช้จ่าย
                  </button>*/}
                </div>

                <div className="row mt-3">
                  <div className="col-md-1 text-right mt-1">
                    จาก
                  </div>
                  <div className="row col-8">
                    <div className="col-4">
                      <DatePicker
                        selected={this.state.date_start}
                        onChange={this.handleDateStart}
                        dateFormat="yyyy-MM-dd"
                        type="text"
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                    <div className="col-1 mr-3 mt-1">
                      ถึง
                    </div>

                    <div className="col-4">
                      <DatePicker
                        selected={this.state.date_end}
                        onChange={this.handleDateEnd}
                        dateFormat="yyyy-MM-dd"
                        type="text"
                        className="form-control"
                        placeholder=""
                      />
                    </div>

                    <div className="d-flex mr-3">
                      <button type="button" className="btn btn-sm btn-primary mr-1" onClick={() => this.loadSellByAgent()} > ตกลง </button>

                      <button type="button" className="btn btn-sm btn-success" onClick={() => this.exportSellByAgent()} > Excel </button>
                    </div>

                  </div>

                  <div className="col-3 text-right pt-2">
                    <h5>
                      รวม&nbsp;
                      <NumberFormat
                        displayType={'text'}
                        thousandSeparator={true}
                        value={this.state.agentSum}/>
                      &nbsp;บาท
                    </h5>
                  </div>
                </div>

                {/*<ExpenseListRow
                  expense_list={this.state.lists}
                  onDeleteHandle={this.onDeleteHandle}
                  onSaveFileModal={this.onSaveFileModal}
                  onEditExpenseModal={this.onEditExpenseModal}
                />*/}

                <div className="card-block">
                  <table className="table table-striped">
                    <thead>
                    <tr>
                      <th className="text-center">#</th>
                      <th >วันที่ขาย/โดย</th>
                      <th >ข้อมูลรถ</th>
                      <th className="text-center">ข้อมูลการขาย</th>
                      <th className="text-center">ค่านายหน้า</th>
                    </tr>
                    </thead>
                    <tbody>
                    {agentCarList}
                    </tbody>
                  </table>
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }

}

export default AgentByDate