import React, { Component } from 'react'
import DatePicker from 'react-datepicker'
import { APIURL } from '../../config/config'
import axios from 'axios'
import moment from 'moment'
import 'moment/locale/th';
import "react-datepicker/dist/react-datepicker.css";
// moment(claimData.claim_date).locale('th').format('LL');

class SparePartStockIn extends Component {
  constructor (props){
    super(props)
    this.state = {
      actionType: 'บันทึก',
      in_date: new Date(),
      sparePartSearchResult: [],
      isDeleteKey: false,
      stockInItem: [],
    }
    this.handleInDate = this.handleInDate.bind(this);
    this.onChangeSearchSparePart = this.onChangeSearchSparePart.bind(this);
    this.onKeyDownSearchSparePart = this.onKeyDownSearchSparePart.bind(this);
    this.addStockInItem = this.addStockInItem.bind(this);
    this.updateStockItem = this.updateStockItem.bind(this);
    this.removeStockItem = this.removeStockItem.bind(this);
    this.onSubmitStockIn = this.onSubmitStockIn.bind(this);
  }

  handleInDate(date) {
    this.setState({
      in_date: date
    })
  }

  onChangeSearchSparePart(e) {
    var search_text = this.refs.search_spare_part.value
    // console.log(search_text)

    if (this.state.isDeleteKey) {
      // console.log('delete onChangeSearchSparePart');
      return;
    }

    if(this.state.sparePartSearchResult.length === 1){
      // console.log('found 1 item in '+search_text)
      return;
    }

    axios.get(APIURL+'/spare_part/search/'+search_text).then(res => {
      // console.log(res.data)
      if(res.status === 200) {
        this.setState({
          sparePartSearchResult: res.data
        });
      }
    })
  }

  onKeyDownSearchSparePart(e) {
    if (e.keyCode === 8) {
      if(this.refs.search_spare_part.value.length <=3){
        this.setState({
          isDeleteKey: true,
          sparePartSearchResult: []
        });
      }else {
        this.setState({
          isDeleteKey: true
        });
      }
    }else{
      this.setState({
        isDeleteKey: false
      });
    }
  }

  addStockInItem(sparePartItem) {
   let stockInItem = this.state.stockInItem;
   let newItem = {
      id: sparePartItem.id,
      name: sparePartItem.name,
      price: sparePartItem.price,
      quantity: 1,
      unit: sparePartItem.unit,
   }

   const existItem = this.state.stockInItem.filter(item => item.id === sparePartItem.id)

    if(existItem.length===0){
      stockInItem.push(newItem)
      this.setState({
        stockInItem: stockInItem,
        sparePartSearchResult: [],
      },()=>{
        this.refs.search_spare_part.value=''
      });
    }else {
      let stockInItem = this.state.stockInItem.map(item => item.id === sparePartItem.id ? {...item, quantity: item.quantity + 1} : item)
      this.setState({
        stockInItem: stockInItem,
        sparePartSearchResult: [],
      },()=>{
        this.refs.search_spare_part.value=''
      });
    }
  }

  updateStockItem(event,itemStock){
    let quantity = isNaN(parseInt(event.currentTarget.value, 10)) ? '' : parseInt(event.currentTarget.value, 10)
    let stockInItem = this.state.stockInItem.map(item => item.id === itemStock.id ? {...item, quantity: quantity} : item)
    this.setState({
      stockInItem: stockInItem,
    });
  }

  removeStockItem(itemStock) {
    // console.log('===removeStockItem');
    let stockInItem = this.state.stockInItem;
    let indexRemove = stockInItem.indexOf(itemStock);
    if(indexRemove !== -1) {
      stockInItem.splice(indexRemove, 1);
      this.setState({
        stockInItem: stockInItem,
      });
    }
  }

  onSubmitStockIn(e) {
    e.preventDefault();
    let in_date = this.state.in_date
    let user = this.props.user.user
    let stockInItem = this.state.stockInItem

    if(stockInItem.length === 0){
      alert('กรุณาเพิ่มรายการอะไหล่อย่างน้อย 1 รายการ');
      return
    }

    var data = {
      in_date: in_date,
      user: user,
      stockInItem: stockInItem
    }

    axios.post(APIURL+'/stock_in',data)
      .then(res => {
        // console.log(res);
        if(res.status===200){
          alert('เพิ่มสต็อกเรียบร้อยแล้ว');
          this.setState({
            stockInItem: []
          },()=>this.props.onToggleTab('1'));

        }
    })

  }

  render() {
    let sparePartSearchResult = this.state.sparePartSearchResult.map((sparePartSearch,index) =>{
      return (
        <div
          className="col-12"
          style={{cursor: 'pointer', height: '30px'}}
          key={index}
          onClick={()=>this.addStockInItem(sparePartSearch)}
        >{sparePartSearch.name} (สต๊อก : {sparePartSearch.quantity_in})</div>
      )
    });

    let stockInItemList = this.state.stockInItem.map((item,index) =>{
      return (
        <tr key={index}>
          <td>{index+1}</td>
          <td>{item.name}</td>
          <td className="text-center">{item.price}</td>
          <td className="text-center" style={{width:'20%'}}>
            <input type="text"
                   className="text-center form-control"
                   value={item.quantity}
                   onChange={(event)=>this.updateStockItem(event,item)}
            />
          </td>
          <td className="text-center" >{item.unit}</td>
          <td className="text-center" >
            <button className="btn btn-sm btn-danger btn-md-width"
                    onClick={()=>this.removeStockItem(item)}
            >
              <i className="fa fa-remove"/> ลบ
            </button>
          </td>
        </tr>
      )
    });

    return (
      <form onSubmit={this.onSubmitStockIn}>
      <div className="row">
        <div className="col-lg-12">
          <div className="card card-accent-warning">
            <div className="card-header card-sparepart">
              <strong className="text-title">เพิ่มอะไหล่เข้าสต๊อก</strong>
            </div>
            <div className="card-block">
              <div className="row">
                <div className="form-group col-sm-2 text-right">
                  <label className="pt-2">วันที่ซื้อเข้า </label>
                </div>
                <div className="form-group col-sm-3">
                  <DatePicker
                    selected={this.state.in_date}
                    onChange={this.handleInDate}
                    dateFormat="yyyy-MM-dd"
                    type="text"
                    className="form-control"
                    placeholder=""
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group col-sm-2 text-right">
                  <label className="pt-2">ค้นหา </label>
                </div>
                <div className="form-group col-sm-8">
                  <input
                    ref="search_spare_part"
                    onChange={this.onChangeSearchSparePart}
                    onKeyDown={this.onKeyDownSearchSparePart}
                    placeholder="ชื่ออะไหล่"
                    className="form-control"
                    size="16"
                    type="text"/>
                  <div className="row mt-2">
                    {sparePartSearchResult}
                  </div>
                </div>
                <div className="form-group col-sm-2">

                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <table className="table table-striped">
                    <thead>
                     <tr>
                       <th>#</th>
                       <th>ชื่ออะไหล่รถ</th>
                       <th className="text-center">ราคาต่อหน่วย</th>
                       <th className="text-center">จำนวน</th>
                       <th className="text-center">หน่วย</th>
                       <th className="text-center">จัดการ</th>
                     </tr>
                    </thead>
                    <tbody>
                    <tr className={this.state.stockInItem.length===0? '': 'hidden'} >
                      <td colSpan={6} className="text-center text-danger">
                         ไม่พบรายการอะไหล่
                      </td>
                    </tr>
                    {stockInItemList}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  ผู้ทำรายการ : {this.props.user.user}
                </div>
                <div className="col-4">
                  &nbsp;
                </div>
                <div className="col-4 text-right">
                  วันที่ทำรายการ : {moment().locale('th').format('LL')}
                </div>
              </div>
            </div>
            <div className="card-footer text-right">
              <button type="reset"
                      className="btn btn-sm btn-danger mr-2"
              >
                <i className="fa fa-refresh"/> ยกเลิก
              </button>
              <button type="submit"
                      className={this.state.actionType==="แก้ไข"? "btn btn-sm btn-warning" :"btn btn-sm btn-primary"}
              >
                <i className={this.state.actionType === "แก้ไข" ? "fa fa-edit" : "fa fa-save"}/>
                &nbsp;{this.state.actionType}
              </button>
            </div>
          </div>
        </div>
      </div>
        </form>
    );
  }
}

export default SparePartStockIn;