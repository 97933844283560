/**
 * Created by Tukta on 15/7/2560.
 */
import React, { Component } from 'react'
import axios from 'axios'
import { APIURL } from '../../../config/config'
import {connect} from 'react-redux';
import DatePicker from 'react-datepicker'
import moment from 'moment'
import 'react-datepicker/dist/react-datepicker.css';
import NumberFormat from 'react-number-format';


class DebtorsForm extends Component{

    constructor(props){
        super(props);
        this.state = {
            lists: [],
            load_data: true,
            danger: false,
            debtorsadd: false,
            branch:[],
            customer: {},
            debtors_list: [],
            customer_list:[],
            customer_select:false,
            customer_id:'',
            debtors_id:0,
            debtors:'',
            car_id:'',
            car: '',
            date_pay : new Date(),
            duration_period:[],
            count_period:[],
            amount_of_debt:0,
            amount_pay_period:0,
        }
        this.handleDatePayStart = this.handleDatePayStart.bind(this);
        this.handleOnSubmitAddDebtors = this.handleOnSubmitAddDebtors.bind(this);
    }

    handleDatePayStart(date) {
        this.setState({
            date_pay: date
        });
    }

    searchCustomer(){
        var search_text = this.refs.search_customer_name.value ;
        //alert(search_text);
        this.getDataCustomerSearch(search_text);
    }
    getDataCustomerSearch (search_text) {
        axios.get(APIURL + '/customer/search/'+search_text)
            .then(res => this.setState({customer_list: res.data})).catch(error => {
            this.setState({customer_list: []})
        })
    }

    onCustomerSelect(customer) {
        /// alert(customer.customer_id)
        this.setState({
            customer_select: true,
            customer:customer,
            customer_id:customer.customer_id},()=>{
        });
    }

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    }

    loadBranchData () {
        axios.get(APIURL + '/branch/')
            .then(res => this.setState({branch: res.data})).catch(error => {
            this.setState({branch: []})
        })
    }

    loadCountPeriodData () {
        axios.get(APIURL + '/count_period/')
        .then(res => this.setState({count_period: res.data}))
        .catch(error => {
            this.setState({count_period: []})
        })
    }

    loadDurationPeriodData () {
        axios.get(APIURL + '/duration_period/')
          .then(res => this.setState({duration_period: res.data}))
          .catch(error => {
            this.setState({duration_period: []})
        })
    }

    componentDidMount() {
        this.loadBranchData()
        this.loadCountPeriodData()
        this.loadDurationPeriodData()


    }

    handleOnSubmitAddDebtors(event) {
        event.preventDefault()

        let customer_id = this.state.customer_id
        let branch_id = this.refs.branch_id.value
        let debtors_name = this.refs.debtors_name.value
        let amount_of_debt = this.refs.amount_of_debt.value
        let duration_period_id = this.refs.duration_period_id.value
        let amount_pay_period = this.refs.amount_pay_period.value
        let count_period_id = this.refs.count_period_id.value
        let date_pay_start = moment(this.state.date_pay).format('YYYY-MM-DD')
        let debtors_other = this.refs.debtors_other.value

        // alert(date_pay_start); return;

        if(customer_id === ''){
            alert('กรุณาเลือกชื่อลูกค้าที่ต้องตั้งหนี้')
            return;
        }
        if(branch_id === ''){
            alert('กรุณาเลือกสาขา')
            return;
        }
        if(duration_period_id === ''){
            alert('กรุณาเลือกจำนวนงวดที่ต้องชำระ')
            return;
        }
        if(count_period_id === ''){
            alert('กรุณาเลือกระยะเวลาต่องวด')
            return;
        }
        if(amount_of_debt === ''){
            alert("กรุณากรอกข้อมูลการชำระเงิน")
            return;
        }

        axios.post(APIURL + '/debtors/', {
            customer_id : customer_id,
            branch_id : branch_id,
            debtors_name : debtors_name,
            amount_of_debt : amount_of_debt,
            duration_period_id : duration_period_id,
            amount_pay_period : amount_pay_period,
            count_period_id : count_period_id,
            date_pay_start : date_pay_start,
            debtors_other : debtors_other,
            status_field: 'show',
            username: 'admin',
            debtors_type: 3
        })
            .then(res => {
                if(res.status === 200)
                  this.props.onToggle()
            })
            .catch(error => {
                alert("error: "+error)
            })
    }

    render(){
        var car_branch_list = this.state.branch.map((branch,index)=>{
            return(
                <option key={index} value={branch.branch_id}>{branch.branch_name}</option>
            )
        })

        var count_period_list = this.state.count_period.map((count_period,index)=>{
            return(
                <option key={index} value={count_period.count_period_id}>{count_period.count_period_name}</option>
            )
        })

        var duration_period_list = this.state.duration_period.map((duration_period,index)=>{
            return(
                <option key={index} value={duration_period.duration_period_id}>{duration_period.duration_period_name}</option>
            )
        })

        var search_customer_list = this.state.customer_list.map((customer_list,index)=>{
            return(
                <tr key={index}>
                    <td className="text-center"><strong>ชื่อ</strong>
                        <br/>
                        {customer_list.customer_name}&nbsp;&nbsp;&nbsp;{customer_list.customer_lastname}
                     </td>
                    <td className="text-center"><strong>เลขประจำตัวประชาชน</strong>
                        <br/>
                        {customer_list.customer_card_id}
                    </td>
                    <td className="text-center"><strong>เบอร์โทรศัพท์</strong>
                        <br/>
                        {customer_list.customer_mobile}</td>
                    <td><button  onClick={()=>this.onCustomerSelect(customer_list)} type="button" className="btn btn-primary">
                        <i className="fa fa-hand-pointer-o"/>&nbsp;&nbsp; เลือก</button></td>
                </tr>
            )
        })

        return(
            <div className="card card-accent-primary">
                <form action="" method="post"  onSubmit={this.handleOnSubmitAddDebtors.bind(this)}>
                    <div className="card-header">
                        <strong className="text-title">เพิ่มข้อมูลลูกหนี้</strong>
                    </div>
                    <div className="card-block">
                        <div className="row">
                            <div className="form-group col-sm-6">
                                <label htmlFor="debtors_name">หัวข้อ</label>
                                <input type="text"
                                       className="form-control"
                                       id="debtors_name"
                                       ref="debtors_name"
                                       placeholder=""
                                />
                            </div>
                            <div className="form-group col-sm-6">
                                <label htmlFor="branch">สาขา</label>
                                <select className="form-control"
                                        id="branch_id"
                                        ref="branch_id"
                                >
                                    {car_branch_list}
                                </select>
                            </div>


                            <div className="card-block">
                                <div className="row">
                                    <div className="form-group col-sm-12">
                                        <div className={!this.state.customer_select ? 'row ' : ' hidden'}>
                                            <div className="controls col-md-12">
                                                <div className="input-group ">
                                                    <input onChange={this.searchCustomer.bind(this)}
                                                           ref="search_customer_name"
                                                           placeholder="ค้นหาชื่อลูกค้า"
                                                           className="form-control"
                                                           size="16"
                                                           type="text"/>

                                                    <span className="input-group-btn ">
                                                        <button className="btn btn-sm btn-success"
                                                                type="button"
                                                                onClick={this.searchCustomer.bind(this)} >ค้นหา
                                                        </button>
                                                    </span>
                                                </div>
                                                <div id="show_customer" className="row">
                                                    <div className="controls col-md-12">
                                                        <table className="table table-striped">
                                                            <tbody>
                                                            {search_customer_list}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className= {this.state.customer_select ? 'row' : ' hidden'}>
                                            <div className="card-header controls col-md-12 hidden">
                                                <strong>ข้อมูลลูกค้า</strong>
                                            </div>
                                                <div className="col-lg-12">
                                                    <div className=" form-group row" style={styles.bottomLine}>
                                                        <label className="col-md-3"><strong> ชื่อ - นามสกุลลูกค้า </strong></label>
                                                        <div className="col-md-3">
                                                            <p className="text-justify text-success">
                                                                <strong>{this.state.customer.customer_name} &nbsp;&nbsp; {this.state.customer.customer_lastname}</strong></p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-12">
                                                    <div className=" form-group row" style={styles.bottomLine}>
                                                        <label className="col-md-3"><strong> เลขบัตรประชาชน </strong></label>
                                                        <div className="col-md-3">
                                                            <p className="text-justify text-success">
                                                                <strong>{this.state.customer.customer_card_id}</strong></p>
                                                        </div>

                                                        <label className="col-md-3"><strong> เบอร์โทรศัพท์ </strong></label>
                                                        <div className="col-md-3">
                                                            <p className="text-justify text-success">
                                                                <strong> {this.state.customer.customer_mobile} </strong></p>
                                                        </div>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>

                                    <div className="form-group col-sm-12">
                                        <label htmlFor="amount_of_debt">จำนวนหนี้</label>
                                        <input type="text"
                                               className="form-control hidden"
                                               id="amount_of_debt"
                                               ref="amount_of_debt"
                                               placeholder=""
                                               value={this.state.amount_of_debt}
                                               onChange={()=>{}}
                                        />
                                        <NumberFormat
                                            className="form-control"
                                            thousandSeparator={true}
                                            prefix={'฿'}
                                            //placeholder="กรอกค่าโอน"
                                            min={0}
                                            max={1000000000}
                                            step={1000}
                                            size={10}
                                            allowNegative={false}
                                            onValueChange = {(values) => {
                                                const {value} = values;
                                                this.setState({amount_of_debt: value},()=>{
                                                })

                                            }}
                                        />
                                    </div>

                                    <div className="form-group col-sm-6">
                                        <label htmlFor="duration_period_id">จำนวนงวดที่ต้องชำระ</label>
                                        <select
                                            className="form-control"
                                            id="duration_period_id"
                                            ref="duration_period_id"
                                            name="duration_period_id"
                                        >
                                            {duration_period_list}
                                        </select>
                                    </div>

                                    <div className="form-group col-sm-6">
                                        <label htmlFor="amount_pay_period">จำนวนเงินต่องวด</label>
                                        <input type="text"
                                               className="form-control hidden"
                                               id="amount_pay_period"
                                               ref="amount_pay_period"
                                               placeholder=""
                                               value={this.state.amount_pay_period}
                                               onChange={()=>{}}
                                        />
                                        <NumberFormat
                                            className="form-control"
                                            thousandSeparator={true}
                                            prefix={'฿'}
                                            //placeholder="กรอกค่าโอน"
                                            min={0}
                                            max={1000000000}
                                            step={1000}
                                            size={10}
                                            allowNegative={false}
                                            onValueChange = {(values) => {
                                                const {value} = values;
                                                this.setState({amount_pay_period: value},()=>{
                                                })

                                            }}
                                        />
                                    </div>

                                    <div className="form-group col-sm-6">
                                        <label htmlFor="count_period_id">ระยะเวลาต่องวด</label>
                                        <select
                                            className="form-control"
                                            id="count_period_id"
                                            ref="count_period_id"
                                            name="count_period_id"
                                        >
                                            {count_period_list}
                                        </select>
                                    </div>

                                    <div className="form-group col-sm-6">
                                        <label htmlFor="date_pay_start">วันเริ่มต้นชำระเงิน</label>
                                        <br/>
                                        <DatePicker
                                            selected={this.state.date_pay}
                                            onChange={this.handleDatePayStart}
                                            dateFormat="YYYY-MM-dd"
                                            type="text"
                                            className="form-control"
                                            id="date_pay_start"
                                            ref="date_pay_start"
                                            name="date_pay_start"
                                            placeholder=""
                                        />
                                    </div>

                                    <div className="form-group col-sm-12">
                                        <label htmlFor="debtors_other">หมายเหตุ</label>
                                        <input type="text"
                                               className="form-control"
                                               id="debtors_other"
                                               ref="debtors_other"
                                               placeholder=""
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 text-center">
                            <div className="card-block">
                                <button type="reset"
                                        className="btn  btn-danger mr-2" style={styles.btnWidth}
                                        onClick={this.props.onToggle}
                                >
                                    <i className="fa fa-refresh"/> ยกเลิก</button>

                                <button type="submit" className="btn btn-success"  style={styles.btnWidth} >
                                    <i className="icon-check"/>  บันทึกข้อมูลลูกหนี้</button>
                            </div>
                        </div>

                    </div>
                </form>
            </div>
        );
    }
}

const styles = {
    bottomLine: {
        borderBottomColor: '#ccc',
        borderBottomStyle: 'solid',
        borderWidth: 0.5,
    },
    btnWidth: {
        width: 170,
   }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    token: state.auth.token,
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(DebtorsForm);