import React, { Component } from 'react'
import DebtorsListRow from '../DebtorsListRow/DebtorsListRow'
import DebtorsForm from '../DebtorsForm/DebtorsForm'
import axios from 'axios'
import { APIURL } from '../../../config/config'
import { Modal, ModalBody } from 'reactstrap'
import { connect } from 'react-redux'
import pdfMake from "pdfmake/build/pdfmake"
import pdfFonts from "pdfmake/build/vfs_fonts"
import moment from 'moment/moment'
import { Link } from 'react-router-dom'

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
  THSarabunNew: {
    normal: 'THSarabunNew-Regular.ttf',
    bold: 'THSarabunNew-Bold.ttf',
    italics: 'THSarabunNew-Italic.ttf',
    bolditalics: 'THSarabunNew-BoldItalic.ttf'
  },
  Roboto: {
    normal: 'Roboto-Regular.ttf',
    bold: 'Roboto-Medium.ttf',
    italics: 'Roboto-Italic.ttf',
    bolditalics: 'Roboto-MediumItalic.ttf'
  }
}


class DebtorsList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      user: {},
      user_type: '',
      lists: [],
      load_data: true,
      danger: false,
      toggle_debtors_add: false,
      paymentdetail: false,
      selectCustomer: '',
      customer_id: 0,
      debtors_id: 0,
      action: 'บันทึก',
      customer: '',
      debtors: '',
      car_id: '',
      car: '',
      width: 0,
      height: 0
    }
    this.toggleDebtors = this.toggleDebtors.bind(this);
    this.togglePaymentDetail = this.togglePaymentDetail.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  componentWillMount () {
    //const queryString = require('query-string');
    var user = JSON.parse(this.props.user);
    this.setState({
      user: user ,
      user_type : user.type
    })
  }

  componentDidMount () {
    this.loadDebtorsData()
  }

  loadDebtorsData () {
    axios.get(APIURL + '/debtors_detail/sum/')
      .then(res => {
        if(res.status === 200) {
          var data = res.data.sort((a, b) => (a.debtors_id < b.debtors_id) ? 1 : -1)
          this.setState({lists: data})
        }
      }).catch(error => {
        this.setState({lists: []})
    })

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  toggleDebtors () {
    this.setState({
      toggle_debtors_add: !this.state.toggle_debtors_add
    })
    this.loadDebtorsData()
  }

  togglePaymentDetail () {
    this.setState({
      paymentdetail: !this.state.paymentdetail
    })
    this.loadDebtorsData()
  }

  addPaymentDetail (debtors) {
    this.setState({debtors_id: debtors.debtors_id})
    this.togglePaymentDetail()
  }

  onSearch(){
    let search_text = this.refs.input_search.value

    if(search_text==='')
      return;

    axios.get(APIURL + '/debtors_detail/search/'+search_text)
      .then(res => {
        if(res.status === 200) {
          let data = res.data.sort((a, b) => (a.debtors_id < b.debtors_id) ? 1 : -1)
          this.setState({lists: data})
        }
      }).catch(error => {
        console.log(error)
        this.setState({lists: []})
    })

  }

  deleteDebtors(debtor_del) {

    if(window.confirm('ท่านต้องการลบ '+debtor_del.debtors_name +'\nยอดหนี้ '+debtor_del.amount_of_debt.toLocaleString()+' ใช่หรือไม่? (ลบแล้วไม่สามารถกู้คืนได้)')) {
      var debtorList = this.state.lists.filter(debtors => debtors.debtors_id !== debtor_del.debtors_id)

      axios.delete(APIURL + '/debtors/'+debtor_del.debtors_id)
        .then(res => {

          if(res.status === 200) {
            this.setState({lists: debtorList})
          }
        }).catch(error => {
        // console.log(error)
        // this.setState({lists: []})
        alert('ผิดพลาด: '+error);
      })

    }
  }

  printDebtors(debtor) {
    //console.log('==printDebtors')
    //console.log(debtor)
    var debtors_id = debtor.debtors_id
    var car_sell_id = debtor.car_sell_id

    axios.get(APIURL + '/debtors_detail/debtors_id/' + debtors_id)
      .then(res => {
        //console.log("========================loadDebtorsDetailById")
        //console.log(res)
        if(res.status === 200) {
          var debtorsDetail = res.data


          axios.get(APIURL + '/car_sell/' + car_sell_id)
            .then(res2 => {
              var car_sell = res2.data
              this.printDebtorsPdf(debtor, debtorsDetail, car_sell)
            })

        }

      }).catch(error => {
      alert('error' + error)
    })

  }

  printDebtorsPdf(debtor, debtorsDetail, car_sell){

    // console.log(car_sell); return;

    var debtors_name = debtor.debtors_name
    var date_pay_start = "จ่ายทุกวันที่ " + moment( debtor.date_pay_start).format('DD')
    var customer_mobile = debtor.customer_mobile
    var customer_name = debtor.customer_name+' '+debtor.customer_lastname + ' ('+customer_mobile+')'
    // var debtors_other = debtor.debtors_other

    var car_sell_user = car_sell.length > 0 ? car_sell[0].car_sell_user : '-'
    var promotion_name = car_sell.length > 0 ? car_sell[0].promotion_name : '-'

    // return;

    var row1 = {
      columns: [
        { width: '10%',
          text: 'รายการ', style: 'textBoldRight'
        },
        { width: '90%', style: 'textNormal',
          text: debtors_name,
        },
      ],
      columnGap: 20,
      lineHeight:1
    }

    var row2 = {
      columns: [
        { width: '10%',
          text: 'ชื่อผู้ซื้อ', style: 'textBoldRight'
        },
        { width: '50%', style: 'textNormal',
          text: customer_name,
        },
        { width: '20%',
          text: 'โปรโมชั่น', style: 'textBoldRight'
        },
        { width: '30%', style: 'textNormal',
          text: promotion_name,
        },
      ],
      columnGap: 20,
      lineHeight:1
    }
    var row3 = {
      columns: [
        { width: '10%',
          text: 'เซลล์', style: 'textBoldRight'
        },
        {
          width: '50%', style: 'textNormal',
          text: car_sell_user,
        },
        { width: '20%',
          text: 'วันที่จ่ายค่างวด', style: 'textBoldRight'
        },
        {
          width: '30%', style: 'textNormal',
          text: date_pay_start,
        }
      ],
      columnGap: 20,
      lineHeight:1
    }


    var  deptAllRows = []
    var tableHeader = [
      { style: 'textBold', text: 'งวดที่',},
      { style: 'textBold', text: 'กำหนดชำระ',},
      { style: 'textBold', text: 'จำนวนเงิน',},
      { style: 'textBold', text: 'วันที่ชำระ',},
      { style: 'textBold', text: 'ผู้รับเงิน',},
    ];
    deptAllRows.push(tableHeader);

    debtorsDetail.forEach((debtor, index) => {
      var order = debtor.debtors_detail_order
      var date_dept = moment(debtor.date_dept).format('DD-MM-YYYY')
      var amount_dept = debtor.amount_dept.toLocaleString()
      var amount_pay = debtor.amount_pay === 0 ? ' ' : debtor.amount_pay.toLocaleString()
      var date_pay =  debtor.date_pay === '0000-00-00' ? ' ' : moment(debtor.date_pay).format('DD-MM-YYYY')

      var row = []

      var itemOrder = {
        text: order,
        style: 'textCenter'
      };
      var itemDateDept = {
        text: date_dept,
        style: 'textCenter'
      };
      var itemAmountDept = {
        text: amount_dept,
        style: 'textCenter'
      };

      var itemAmountPay = {
        text: amount_pay,
        style: 'textCenter'
      };
      var itemDatePay = {
        text: date_pay,
        style: 'textCenter'
      };


      row.push(itemOrder)
      row.push(itemDateDept)
      row.push(itemAmountDept)
      row.push(itemAmountPay)
      row.push(itemDatePay)

      deptAllRows.push(row);
    })

    var docDefinition = {
      pageOrientation: 'portrait',
      pageSize: 'A4',
      pageMargins: [40, 30, 40, 0],
      // background: {image: 'book', width: 600},
      content: [
        {text: 'ใบผ่อนชำระ', style: 'bodyhead', lineHeight: 1.3},
        row1,
        row2,
        row3,
        {text: ' ', style: 'bodyhead', lineHeight: 0.5},
        {
          table: {
            headerRows: 1,
            widths: ['10%','20%', '20%', '*', '*'],
            body: deptAllRows
          }
        },
      ],
      styles: {
        bodyhead: {
          fontSize: 18,
          bold: true,
          alignment: 'center'
        },
        textNormal: {
          fontSize: 16,
        },
        textBold: {
          fontSize: 16,
          bold: true,
          alignment: 'center'
        },
        textBoldRight: {
          fontSize: 16,
          bold: true,
          alignment: 'right'
        },
        textCenter: {
          fontSize: 16,
          alignment: 'center'
        }
      },
      defaultStyle: {
        font: 'THSarabunNew'
      }
    }
    pdfMake.createPdf(docDefinition).open();
  }

  onRefresh(){
    this.refs.input_search.value = ''
    this.loadDebtorsData()
  }

  render () {

    let width = this.state.width

    return (
      <div className="card">
        <div className="card-block">
          <div className="row">
            <div className="col-sm-12 col-md-12">
              <div className="card card-accent-info">
                <div className="card-header card-customer">
                  <strong className="text-title"> ข้อมูลบัญชีลูกหนี้ </strong>

                  <input
                    ref="input_search"
                    style={styles.inputSearch}
                    className="form-control mr-2"
                    placeholder="ค้นหาตามชื่อ หรือ เบอร์โทร"
                    type="text"
                  />

                  &nbsp;
                  <button
                    className="btn btn-sm btn-head-bar mr-1"
                    onClick={this.onSearch.bind(this)}
                    style={styles.buttonHeight}
                  >
                    <i className="icon-search"/>ค้นหา
                  </button>

                  <button className="btn btn-head-bar"
                          style={styles.buttonHeight}
                          onClick={this.onRefresh.bind(this)}

                  >
                    <i className="icon-refresh"/>
                  </button>

                  {
                    this.state.user.type === 'admin' || this.state.user.type === 'manager' || this.state.user.type === 'account' ? (
                      <Link to="/report/debtors">
                        <button className="btn btn-head-bar float-right mt-1 ml-1" style={{backgroundColor: '#4dbd74'}}>
                          <i className="icon-doc"></i>&nbsp; รายงานลูกหนี้
                        </button>
                      </Link>
                    ) : null
                  }

                  <button type="button"
                          onClick={this.toggleDebtors}
                          className="btn btn-head-bar float-right mt-1">
                    <i className="icon-plus"/>&nbsp; เพิ่มข้อมูลลูกหนี้
                  </button>



                  <Modal isOpen={this.state.toggle_debtors_add} toggle={this.toggleDebtors}
                         className={'modal-lg'}
                         style={width<=980 ?{maxWidth:800}:{maxWidth:1200}}
                  >
                    <ModalBody>
                      <DebtorsForm
                        //onLoadDebtors ={this.loadCarData}
                        onToggle={this.toggleDebtors}
                      />
                    </ModalBody>
                  </Modal>
                </div>

                <DebtorsListRow
                  debtors_list={this.state.lists}
                  debtorsCallbacks={
                    {
                      add: this.addPaymentDetail.bind(this),
                      del: this.deleteDebtors.bind(this),
                      print: this.printDebtors.bind(this),
                    }
                  }
                  user_type={this.state.user_type}
                />

              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

}

const styles = {
  bottomLine: {
    borderBottomColor: '#ccc',
    borderBottomStyle: 'solid',
    borderWidth: 0.5,
  },
  inputSearch: {
    marginLeft: 20,
    width: 300,
    display: 'inline'
  },

  buttonHeight: {
    height: 36,
    paddingTop:0,
    paddingBottom:0,
    display: 'inline',
    marginTop: '-0.35rem'
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(DebtorsList)