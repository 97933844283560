import React, { Component } from 'react';
import { IMAGE_FULL_URL, IMAGE_NOPHOTO_URL } from '../../../config/config';
import * as moment from 'moment';
import 'moment/locale/th';
import PreviewImage from '../../../views/PreviewImage/';
import NumberFormat from 'react-number-format';
import {  Modal, ModalBody } from 'reactstrap';
import { checkGroupPermission } from '../../../services/grouppermission';
import ReactToPrint, {PrintContextConsumer} from "react-to-print";

class CarRepairListItem extends Component{
  constructor (props){
    super(props)

    this.state = {
      preview_image_url: '',
      preview_image_select: false,

      lightboxState: true,
      preview_ligthbox: false,
    }
    this.onCarRepairDelete  = this.onCarRepairDelete.bind(this)
    this.togglePreviewImage = this.togglePreviewImage.bind(this)

    this.onPreviewLightbox = this.onPreviewLightbox.bind(this)
    this.handlePrintImage = this.handlePrintImage.bind(this)
  }

  onCarRepairDelete(car_repair){
    // console.log(car_repair)
    // return
    this.props.onCarRepairDelete(car_repair)
  }

  onPreviewSelected(url){
    this.setState({
      preview_image_url: url,
      preview_image_select: true
    });
  }

  togglePreviewImage() {
    this.setState({
      preview_image_select: !this.state.preview_image_select
    });
  }

    onPreviewLightbox(url,e){
        console.log(url)
        let docId = e.target.name
        this.setState({
            preview_image_url: url,
            preview_ligthbox: true
        }, () => {
        });

    }

    handlePrintImage(url, handlePrint) {
        this.setState({
            preview_image_url: url
        },() => handlePrint());
    }

  render(){
    // let user_type = this.props.user_type
    // console.log('user_type=', user_type)
    let sum_epair_cost = this.props.lists.length === 0 ? 0 : this.props.lists.reduce((acc, repair) => acc + repair.car_repair_cost, 0)

    let car_repair_list = this.props.lists.map((car_repair,index)=>{

      let image_url = ''
      if( car_repair.car_repair_file === 'nophoto.png') {
        image_url = IMAGE_NOPHOTO_URL
      } else {
        // check https
        if (car_repair.car_repair_file !== null) {
          if (car_repair.car_repair_file.includes('https')) {
            image_url = car_repair.car_repair_file
          }else{
            image_url = IMAGE_FULL_URL + car_repair.car_repair_file
          }
        }else{
          image_url = IMAGE_NOPHOTO_URL
        }
      }

      return(
        <tr key={index} >
          <td className="text-center">{index+1}</td>
          <td className="text-center">
            {car_repair.car_repair_name}
            <br/>
            <span className="badge badge-danger">
              {car_repair.car_repair_type}
            </span>
          </td>
          <td className="text-center text-nowrap">
            &nbsp;&nbsp;ส่งซ่อม : {moment(car_repair.car_repair_send).locale('th').format('LL')} <br/>
            ซ่อมเสร็จ : {moment(car_repair.car_repair_complete).locale('th').format('LL')} <br/>
            &nbsp;&nbsp;&nbsp;อู่&nbsp;: {car_repair.center_name}
          </td>
          <td className="text-center">
            <img
              src={image_url}
              style={{'cursor' : 'pointer', 'width': '120px', 'height': 'auto'}}
              onClick={()=>this.onPreviewSelected(image_url)}
              alt="เอกสาร"
            />
          </td>
          <td className="text-center">
            <NumberFormat value={car_repair.car_repair_cost} displayType={'text'} thousandSeparator={true} prefix={'฿'} />
          </td>
          <td className="text-center text-nowrap">
            {car_repair.fullname}<br/>
            {moment(car_repair.updated_at).locale('th').format('DD-MM-YYYY HH:mm')}
            <br/><br/>
            {
              car_repair.center_name === 'เบิกอะไหล่' ? (<font color='red'> *** เบิกอะไหล่ลบได้ที่เมนูข้อมูลอะไหล่ </font>): null
            }
          </td>
          <td className="text-center">

            {
              checkGroupPermission(23, this.props.grouppermissions).created ? (
                <>
                  <button className="btn btn-sm btn-warning btn-md-width" style={{width: 100}}
                          onClick={()=>this.props.onCarRepairEditSelected(car_repair)}
                  >
                    <i className="fa fa-pencil"/> แก้ไข
                  </button>
                  <br/>
                </>
              ) : null
            }

            {
              checkGroupPermission(23, this.props.grouppermissions).modified ? (
                <>
                  <button className="btn btn-sm btn-success btn-md-width  mt-2" style={{width: 100}}
                          onClick={()=>this.props.onCarRepairUploadFile(car_repair)}
                  >
                    <i className="fa fa-file"/> แนบไฟล์
                  </button>
                  <br/>
                </>
              ) : null
            }

            {
              checkGroupPermission(23, this.props.grouppermissions).deleted ? (
                <button className="btn btn-sm btn-danger btn-md-width mt-2" style={{width: 100}}
                        onClick={()=>this.onCarRepairDelete(car_repair)}
                        disabled={car_repair.car_repair_type === 'เบิกอะไหล่'}
                >
                  <i className="fa fa-remove"/> ลบ
                </button>
              ): null
            }

            <br/>

              {
                  <ReactToPrint
                      content={() => this.componentRef}
                  >
                      <PrintContextConsumer>
                          {({ handlePrint }) => (
                              <button className="btn btn-sm btn-primary btn-md-width mt-2" style={{width: 100}}
                                      onClick={()=> this.handlePrintImage(image_url, handlePrint)}>
                                  <i className="fa fa-print"/> พิมพ์
                              </button>
                          )}
                      </PrintContextConsumer>
                  </ReactToPrint>
              }

          </td>
        </tr>
      )
    })


    return(
      <div>

          <div style={{ display: "none" }}>
              <PreviewImage
                  imageUrl={this.state.preview_image_url} ref={el => (this.componentRef = el)}
              />
          </div>

        {
          sum_epair_cost === 0 ? null : (
            <>
              <h4 className="text-right">
              รวมค่าซ่อม &nbsp;&nbsp;
                <NumberFormat
                  value={sum_epair_cost.toFixed(2)}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'฿'}
                />
                &nbsp;&nbsp; บาท
              </h4>
            </>
          )
        }

        <table className={(this.props.lists.length>0)?'table table-striped table-responsive ' : 'table table-striped hidden'}>
          <thead>
          <tr>
            <th className="text-center">#</th>
            <th className="text-center" style={{width: '35%'}}>ชื่อรายการ</th>
            <th className="text-center text-nowrap">วันที่ส่งซ่อม/ซ่อมเสร็จ</th>
            <th className="text-center">เอกสารแนบ</th>

            <th className="text-center">ราคา</th>
            <th className="text-center text-nowrap">ผู้ทำรายการ</th>
            <th className="text-center">จัดการ</th>
          </tr>
          </thead>
          <tbody>
          {car_repair_list}
          </tbody>
        </table>

        <div className={this.props.lists.length ===0 ?' text-center  ' : 'hidden'} >
          <strong className="text-danger">ไม่พบรายการซ่อม</strong>
        </div>

        <Modal isOpen={this.state.preview_image_select} toggle={this.togglePreviewImage} className={'modal-lg '}>
          <ModalBody>
            <PreviewImage
              imageUrl={this.state.preview_image_url}
            />
          </ModalBody>
        </Modal>

      </div>
    )
  }
}

export default CarRepairListItem
