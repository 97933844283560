import React, { Component } from 'react';
import BranchForm from '../../../components/Branch/BranchForm';
import BranchListRow from '../../../components/Branch/BranchListRow';
import axios from 'axios';
import { APIURL } from '../../../config/config';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';
import { AlertError, AlertWarning } from '../../Alert/Alert'

class BranchList extends Component{
    constructor (props) {
        super(props)
        this.state = {
            user: {},
            lists: [],
            load_data: true,
            danger: false,
            branch_id: 0,
            branch_name: '',
            action: "บันทึก",
            branch: '' ,
        }
        this.cancelBranch = this.cancelBranch.bind(this)
    }

    componentWillMount() {
        this.setState({
            user: JSON.parse(this.props.user),
        })
    }

    loadData () {
        axios.get(APIURL + '/branch/')
            .then(res => {
                this.setState({lists: res.data, load_data: false, branch_id: 0, danger: false})
            }).catch(error => {
            this.setState({lists: [], load_data: true, branch_id: 0, danger: false})
        })
    }

    componentDidMount () {
        this.loadData()
    }

    toggleDanger () {
        this.setState({
            danger: !this.state.danger
        })
    }

    toggleDangerDelete (id) {
        axios.delete(APIURL + '/branch/' + id).then(res => {
            let result = res.data.result
            if (res.status === 200) {
                if (result === 'inuse') {
                   AlertError('ข้อมูลสาขามีการใช้งานอยู่ ไม่สามารถลบได้');
                } else if(result === 'success'){
                    this.loadData()
                }
                this.setState({action: "บันทึก"});
            }
        })
    }

    deleteBranch (branch) {
        this.setState({
            branch_id: branch.branch_id,
            branch_name: branch.branch_name
        }, () => this.toggleDanger())
    }

    editBranch (branch) {
        this.setState({action: "แก้ไข", branch: branch, branch_id: branch.branch_id});
    }

    addBranch(event, branchForm){
        event.preventDefault()
        let that = this ;
        let branch_name = event.target.branch_name.value
        let branch_address = event.target.branch_address.value
        let branch_tel = event.target.branch_tel.value
        let tax_id = event.target.tax_id.value
        let signatory = event.target.signatory.value
        let user = this.state.user.user
        let branch_type = branchForm.branch_type

        if(branch_name===''){
            return ;
        }

        event.target.branch_name.value = ''
        event.target.branch_address.value = ''
        event.target.branch_tel.value = ''
        event.target.tax_id.value = ''
        event.target.signatory.value = ''

        axios.post(APIURL + '/branch/', {
            branch_name: branch_name,
            branch_address: branch_address,
            branch_tel : branch_tel,
            username: user,
            tax_id: tax_id,
            signatory: signatory,
            branch_type: branch_type
        })
            .then( res => {
                if(res.status===200) {
                  that.loadData()
                  that.setState({branch: {}, branch_id: 0, action: "บันทึก"})
                }
            })
            .catch( error => {
                console.log(error)
            })
    }

    updateBranch(event, branchForm){
        event.preventDefault()
        let that = this ;
        let branch_name = event.target.branch_name.value
        let branch_address = event.target.branch_address.value
        let branch_tel = event.target.branch_tel.value
        let tax_id = event.target.tax_id.value
        let signatory = event.target.signatory.value
        let branch_type = branchForm.branch_type
        let branch_id = this.state.branch_id
        let user = this.state.user.user

        if(branch_name===''){
            AlertWarning('กรุณากรอกชื่อสาขา')
            return ;
        }
        event.target.branch_name.value = ""
        event.target.branch_address.value = ""
        event.target.branch_tel.value = ""
        event.target.branch_type.value = ""

        axios.put(APIURL + '/branch/'+branch_id, {
            branch_id: branch_id,
            branch_name: branch_name,
            branch_address: branch_address,
            branch_tel : branch_tel,
            tax_id: tax_id,
            signatory: signatory,
            status_field: 'show',
            username: user,
            branch_type: branch_type
        })
            .then( res => {
                if(res.status===200) {
                  that.loadData()
                  that.setState({branch: {}, branch_id: 0, action: "บันทึก"})
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    cancelBranch(e) {
        e.preventDefault()
        this.setState({
            action: 'บันทึก',
            branch: ''
        })
    }

    render(){
        return (
            <div className="card">
                <div className="card-block">
                    <div className="row">

                        <div className="col-sm-8">
                            <BranchListRow
                                branch_list={this.state.lists}
                                branchsCallbacks={
                                    {
                                        delete: this.deleteBranch.bind(this),
                                        edit: this.editBranch.bind(this)
                                    }
                                }
                            />
                        </div>

                        <div className="col-sm-4">
                            <BranchForm
                                onBranchSubmit={
                                    {
                                        add: this.addBranch.bind(this),
                                        update: this.updateBranch.bind(this),
                                        cancel: this.cancelBranch.bind(this),
                                    }
                                }
                                actionType={this.state.action}
                                branch={this.state.branch}
                            />
                        </div>
                    </div>

                    <Modal isOpen={this.state.danger}
                           toggle={this.toggleDanger.bind(this)}
                           className={'modal-danger '}
                    >
                        <ModalHeader toggle={this.toggleDanger.bind(this)}>ลบข้อมูล</ModalHeader>

                        <ModalBody>
                            <strong> คุณต้องการลบข้อมูล id={this.state.branch_name} ใช่หรือไม่ ?? </strong>
                            <br />(ข้อมูลจะไม่สามารถกู้คืนได้)
                        </ModalBody>

                        <ModalFooter>
                            <Button color="primary"
                                    onClick={this.toggleDangerDelete.bind(this, this.state.branch_id)}>ตกลง</Button>{' '}
                            <Button color="secondary" onClick={this.toggleDanger.bind(this)}>ยกเลิก</Button>
                        </ModalFooter>

                    </Modal>

                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    token: state.auth.token,
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(BranchList)
