import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as moment from 'moment';
import 'moment/locale/th';
import NumberFormat from 'react-number-format';
import { IMAGE_FULL_URL, APIURL, IMAGE_NOPHOTO_URL } from '../../../config/config'
import PreviewImage from '../../../views/PreviewImage/';
import {  Modal, ModalBody } from 'reactstrap';
import axios from 'axios/index';
import { checkGroupPermission } from '../../../services/grouppermission'
import CarExpenseFormEdit from '../CarExpenseFormEdit'
import CarExpenseFile from '../CarExpenseFile'

class CarExpenseList extends Component {

    constructor (props) {
        super(props)

        this.state = {
            preview_image_url: '',
            preview_image_select: false,
            isOpenCarExpenseFormEdit: false,
          expense_selected: {},
          isOpenCarExpenseImage: false
        }
        this.togglePreviewImage = this.togglePreviewImage.bind(this)
        this.onDeleteHandle = this.onDeleteHandle.bind(this)
        this.onOpenCarExpenseFormEdit = this.onOpenCarExpenseFormEdit.bind(this)
        this.onToggleCarExpenseFormEdit = this.onToggleCarExpenseFormEdit.bind(this)
        this.onToggleCarExpenseImage = this.onToggleCarExpenseImage.bind(this)
    }

    onPreviewSelected(url){
        this.setState({
            preview_image_url: url,
            preview_image_select: true
        });
    }

    togglePreviewImage() {
        this.setState({
            preview_image_select: !this.state.preview_image_select
        });
    }

  onDeleteHandle(expense){
    if(window.confirm('ยืนยันการลบรายการ '+ expense.expense_name)) {
      var expense_id = expense.expense_id
      var car_id = this.props.car_id
      var expense_image_file = expense.expense_image_file
      var data = {
        expense_image_file: expense_image_file,
        expense_amount: expense.expense_amount
      }

      var url = APIURL + '/expense/' + expense_id+'/'+car_id

      //console.log(expense)
      //console.log(url);
      //console.log(data);
      //return;

      axios.put(url, data) //1 = ค่าใช้จ่ายของรถ
        .then(res => {
          if (res.status === 200) {
            this.props.onReload()
          }
        }).catch(error => {
        console.log(error)
      })
    }
  }

  onToggleCarExpenseFormEdit(){
    this.setState({
      isOpenCarExpenseFormEdit: !this.state.isOpenCarExpenseFormEdit
    })
  }

  onToggleCarExpenseImage() {
    this.setState({
      isOpenCarExpenseImage: !this.state.isOpenCarExpenseImage
    })
  }

  onOpenCarExpenseFormEdit(expense) {
      this.setState({
        isOpenCarExpenseFormEdit: true,
        expense_selected: expense
      })
  }

  onOpenCarExpenseImage(expense) {
    this.setState({
      isOpenCarExpenseImage: true,
      expense_selected: expense
    })
  }

    render(){

      let sum_expense_cost = this.props.car_expense_list.length === 0 ? 0 : this.props.car_expense_list.reduce((acc, expense) => acc + expense.expense_amount, 0)

        let car_expense_list = this.props.car_expense_list.map((expense,index)=>{

          let image_url = ''
          if(expense.expense_image_file === 'nophoto.png') {
            image_url = IMAGE_NOPHOTO_URL
          } else {
            if (expense.expense_image_file !== null && expense.expense_image_file!== undefined) {
              if (expense.expense_image_file.includes('https')) {
                image_url = expense.expense_image_file
              }else{
                image_url = IMAGE_FULL_URL + expense.expense_image_file
              }
            }else{
              image_url = IMAGE_NOPHOTO_URL
            }
          }

          // console.log('image_url=', image_url)

            return (
                <tr key={index}>
                    <td className="text-center">{index+1}</td>
                    <td className="text-center">{moment(expense.expense_date).locale('th').format('LL')}</td>
                    <td className="text-center">{expense.branch_name}</td>
                    <td className="text-center">{expense.expense_name}</td>
                    <td className="text-center">
                        <img
                            src={image_url}

                            style={{'cursor' : 'pointer', 'width': '120px', 'height': 'auto'}}
                            alt="เอกสาร"
                            onClick={()=>this.onPreviewSelected(image_url)}
                        />
                    </td>
                    <td className="text-center">
                        <NumberFormat value={expense.expense_amount}
                                      displayType={'text'}
                                      thousandSeparator={true}
                                      prefix={'฿'}/>
                    </td>
                    <td className="text-center">
                        {expense.fullname}
                        <br/>
                        {moment(expense.updated_at).locale('th').format('DD-MM-YYYY HH:mm')}
                    </td>
                    <td className="text-center">

                      {
                        checkGroupPermission(24, this.props.grouppermissions).modified ? (
                          <>
                            <button className="btn btn-sm btn-warning btn-md-width"
                                    style={{width: 100}}
                                    onClick={()=> this.onOpenCarExpenseFormEdit(expense)}
                            >
                              <i className="fa fa-pencil"/> แก้ไข
                            </button>
                            <br/>
                          </>
                        ) : null
                      }

                      {
                        checkGroupPermission(24, this.props.grouppermissions).modified ? (
                          <>
                            <button className="btn btn-sm btn-success btn-md-width  mt-2"
                                    style={{width: 100}}
                                    onClick={() => this.onOpenCarExpenseImage(expense)}
                            >
                              <i className="fa fa-file"/> แนบไฟล์
                            </button>
                            <br/>
                          </>
                        ) : null
                      }

                      {
                        checkGroupPermission(24, this.props.grouppermissions).deleted ? (
                          <button className="btn btn-sm btn-danger btn-md-width mt-2"
                                  style={{width: 100}}
                                  onClick={() => this.onDeleteHandle(expense)}
                          >
                            <i className="fa fa-remove"/> ลบ
                          </button>
                        ) : null
                      }

                    </td>
                </tr>
            )
        });

        if(this.props.car_expense_list.length === 0){
          car_expense_list = <tr>
            <td colSpan={8} className="text-center text-danger">
              ไม่พบรายการจ่ายอื่นๆ ของรถ
            </td>
          </tr>
        }

        return (
            <div className="card-block">

              {
                sum_expense_cost === 0 ? null : (
                  <>
                    <h4 className="text-right">
                      รวมค่าใช้จ่ายอื่นๆ ของรถ &nbsp;&nbsp;
                      <NumberFormat
                        value={sum_expense_cost.toFixed(2)}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'฿'}
                      />
                      &nbsp;&nbsp; บาท
                    </h4>
                  </>
                )
              }

              {
                this.state.isOpenCarExpenseFormEdit ?
                  <CarExpenseFormEdit
                    isOpen={this.state.isOpenCarExpenseFormEdit}
                    expense={this.state.expense_selected}
                    car_id={this.state.expense_selected.car_id}
                    onToggle={this.onToggleCarExpenseFormEdit}
                    onReload={this.props.onReload}
                  />
                  : null
              }

              {
                this.state.isOpenCarExpenseImage ?
                    <CarExpenseFile
                      isOpen={this.state.isOpenCarExpenseImage}
                      expense={this.state.expense_selected}
                      onToggle={this.onToggleCarExpenseImage}
                      onReload={this.props.onReload}
                    />
                    : null
              }
                <table className="table table-striped">
                    <thead>
                    <tr>
                        <th className="text-center">#</th>
                        <th className="text-center">วันที่ใช้จ่าย</th>
                        <th className="text-center">สาขา</th>
                        <th className="text-center">รายละเอียด</th>
                        <th className="text-center">ไฟล์เอกสาร</th>
                        <th className="text-center">ยอดค่าใช้จ่าย</th>
                        <th className="text-center">ผู้บันทึก</th>
                        <th className="text-center">จัดการ</th>
                    </tr>
                    </thead>
                    <tbody>
                    {car_expense_list}
                    </tbody>
                </table>

                <Modal isOpen={this.state.preview_image_select} toggle={this.togglePreviewImage} className={'modal-lg '}>
                    <ModalBody>
                        <PreviewImage
                            imageUrl={this.state.preview_image_url}
                        />
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    token: state.auth.token,
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps) (CarExpenseList)
