import React, { Component } from 'react';
import MaintenanceCenterForm from '../../../components/MaintenanceCenter/MaintenanceCenterForm';
import MaintenanceCenterListRow from '../../../components/MaintenanceCenter/MaintenanceCenterListRow';
import axios from 'axios';
import { APIURL } from '../../../config/config';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import CarRepairFile from "../../CarRepair/CarRepairFile";
import MaintenanceCenterFile from "../MaintenanceCenterFile";
import PreviewImage from "../../../views/PreviewImage";

export  default class MaintenanceCenterList extends Component{
    constructor (props) {
        super(props)
        this.state = {
            lists: [],
            load_data: true,
            danger: false,
            center_id:0,
            action: "บันทึก",
            center: {} ,
            toggle_center_file: false,
        }
        this.toggleCenterFile = this.toggleCenterFile.bind(this);
        this.onCenterUploadFile = this.onCenterUploadFile.bind(this);
    }

    componentDidMount () {
        //if(this.state.load_data)
        this.loadData()
        this.loadMaintenanceCenterId()
    }

  loadData () {
    axios.get(APIURL + '/center/')
      .then(res => {
        let data = res.data
        this.setState({lists: data, load_data: false, center_id: 0, danger: false, center:{}})
      }).catch(error => {
      this.setState({lists: [], load_data: true, center_id: 0, danger: false})
    })
  }

    loadMaintenanceCenterId(){
        //var that = this
        axios.get(APIURL + '/center/'+this.state.center_id)
            .then(res => {
                this.setState({maintenance_center: res.data[0] })
            }).catch(error => {
            // alert("error"+error)
            this.setState({maintenance_center: {}})
        })
    }

    onCenterUploadFile(center) {
        this.setState({center: center}, () => this.toggleCenterFile())
    }

    toggleCenterFile() {
        let toggle = !this.state.toggle_center_file
        this.setState({
            toggle_center_file: toggle
        }, () => {
            if(toggle === false) {
                this.setState({
                    center: {}
                })
            }
        })
    }

    toggleDanger () {
        this.setState({
            danger: !this.state.danger
        })
    }

    toggleDangerDelete (id) {

        axios.delete(APIURL + '/center/' + id).then(res => {
            if (res.status === 200) {
                this.loadData()
                this.setState({action: "บันทึก"});
            }
        })
    }
    deleteCenter (center) {
        this.setState({center_id: center.center_id, center:center})
        this.toggleDanger()
    }
    editCenter (center) {
        this.setState(
            {action: "แก้ไข", center: center, center_id: center.center_id});
    }

    addCenter(event){
        event.preventDefault()
        let that = this ;
        let center_name = event.target.center_name.value
        let center_address = event.target.center_address.value
        let center_tel = event.target.center_tel.value

        if(center_name===''){
            return ;
        }
        event.target.center_name.value = ""
        event.target.center_address.value = ""
        event.target.center_tel.value = ""

        axios.post(APIURL + '/center/', {
            center_name: center_name,
            center_address: center_address,
            center_tel : center_tel,
            username: 'admin'
        })
            .then( res => {
                if(res.status === 200) {
                  that.loadData()
                  that.setState({center: '', center_id: 0, action: "บันทึก"})
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    updateCenter(event){
        event.preventDefault()
        let that = this ;
        let center_name = event.target.center_name.value
        let center_address = event.target.center_address.value
        let center_tel = event.target.center_tel.value
        let center_id = this.state.center_id;

        if(center_name===''){
            return ;
        }
        event.target.center_name.value = ""
        event.target.center_address.value = ""
        event.target.center_tel.value = ""
        axios.put(APIURL + '/center/'+center_id, {
            center_id: center_id,
            center_name: center_name,
            center_address: center_address,
            center_tel : center_tel,
            status_field: 'show',
            username: 'admin'
        })
            .then(res => {
                if(res.status === 200) {
                  that.loadData()
                  that.setState({center: {}, center_id: 0, action: "บันทึก"})
                }
            })
            .catch(function (error) {
                console.log(error)
            })

    }

    render(){

        let width = this.state.width

        return (
          <div className="card">
              <div className="card-block">
                  <div className="row">
                      <div className="col-sm-8">
                        <div className="card card-accent-info">
                          <div className="card-header">
                              <strong className="text-title">ข้อมูลศูนย์ซ่อมบำรุง</strong>
                          </div>

                      <div className="card-block">
                          <MaintenanceCenterListRow
                              center_list={this.state.lists}
                              centersCallbacks={
                                  {
                                      delete: this.deleteCenter.bind(this),
                                      edit: this.editCenter.bind(this)
                                  }
                              }
                              onCenterUploadFile={this.onCenterUploadFile}
                          />
                      </div>
                        </div>
                      </div>

                      <div className="col-sm-4">
                          <MaintenanceCenterForm
                              onCenterSubmit={
                                  {
                                      add: this.addCenter.bind(this),
                                      update: this.updateCenter.bind(this)
                                  }
                              }
                              actionType={this.state.action}
                              center={this.state.center}
                          />
                      </div>
                  </div>

                  <Modal isOpen={this.state.danger} toggle={this.toggleDanger.bind(this)}
                         className={'modal-danger '}>
                      <ModalHeader toggle={this.toggleDanger.bind(this)}>ลบข้อมูล</ModalHeader>
                      <ModalBody>
                          <strong> คุณต้องการลบข้อมูล {this.state.center.center_name} ใช่หรือไม่ ?? </strong>
                          <br />(ข้อมูลจะไม่สามารถกู้คืนได้)
                      </ModalBody>
                      <ModalFooter>
                          <Button color="primary"
                                  onClick={this.toggleDangerDelete.bind(this, this.state.center_id)}>ตกลง</Button>{' '}
                          <Button color="secondary" onClick={this.toggleDanger.bind(this)}>ยกเลิก</Button>

                      </ModalFooter>
                  </Modal>

                  {
                      Object.keys(this.state.center).length === 0 ? null : (
                          <MaintenanceCenterFile
                              toggle = {this.state.toggle_center_file}
                              onToggle = {this.toggleCenterFile}
                              width={width}
                              center={this.state.center}
                              onReload={() => {
                                  this.loadMaintenanceCenterId();
                                  this.loadData();
                              }}
                          />
                      )
                  }

                  <Modal isOpen={this.state.preview_image_select} toggle={this.togglePreviewImage} className={'modal-lg '}>
                      <ModalBody>
                          <PreviewImage
                              imageUrl={this.state.preview_image_url}
                          />
                      </ModalBody>
                  </Modal>

              </div>
          </div>
        );
    }
}
