import React, { Component } from 'react';
import { APIURL } from '../../../config/config'
import axios from 'axios/index'


class CustomerDocAdd extends Component {

  constructor (props) {
    super(props)

    this.state = {
      car: {} ,//this.props.car,
      w_doc_detail: false,
      w_customer_doc_file: false,
      //for search
      customer_select: false,
      customer_list: [],
      customer: {},
      customer_id: '',
      //edit
      doc_detail: ''
  }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)

  }

  componentDidMount() {
    if(this.props.form_status === 'edit'){
      //load customer
      var customer_id = this.props.customer_doc_selected.customer_id
      this.getDataCustomerById(customer_id)
      //set exist data to form
      this.setState({
        doc_detail: this.props.customer_doc_selected.doc_detail
      })
    }else if(this.props.form_status === 'add'){
      this.setState({
        customer: {},
        customer_id: '',
        doc_detail: ''
      })
    }
  }

  getDataCustomerById (customer_id) {
    axios.get(APIURL + '/customer/' + customer_id)
      .then(res => {
        if(res.data.length > 0) {
          this.setState({customer: res.data[0], customer_select: true, customer_id: customer_id})
          //console.log('===getDataCustomerById')
          //console.log(res.data)
        }
        //alert (res.data)
      }).catch(error => {
      this.setState({customer: {}})
    })
  }

  handleSubmit (event) {
    event.preventDefault();

    var doc_detail = event.target.doc_detail.value

    if(this.state.customer_id===''){
      alert('กรุณาเลือกลูกค้า');
      return
    }

    if(doc_detail===''){
      this.setState({
        w_doc_detail: true
      });
      return;
    }

    try {
      if(event.target.doc_file.files[0].name === undefined){
        this.setState({
          w_customer_doc_file: false
        });
      }
    }catch (ex){
      this.setState({
        // w_customer_doc_file: true
      });
       // return;
       //save with no file
      this.props.onCustomerDocSubmit.addNoFile(event)
      return;
    }

    var filename = event.target.doc_file.files[0].name ;

    if((/\.(gif|jpg|jpeg|tiff|png)$/i).test(filename)){
      //alert(event.target.car_repair_file.files[0].type);
      this.setState({
        // w_customer_doc_file: true
      });
      //update with file
      this.props.onCustomerDocSubmit.add(event)
    }



  }

  handleChange(event) {
    var targetName = event.target.name;
    var value = event.target.value;

    if(targetName === 'doc_detail'){
      this.setState({ doc_detail: value });
    }
  }

  searchCustomer () {
    var search_text = this.refs.search_customer_name.value
    //alert(search_text);
    this.getDataCustomerSearch(search_text)
  }

  getDataCustomerSearch (search_text) {
    axios.get(APIURL + '/customer/search/' + search_text)
      .then(res => {
        this.setState({customer_list: res.data})
        //console.log(res.data)
        //alert (res.data)
      }).catch(error => {
      this.setState({customer_list: []})
    })
  }


  onCustomerSelect (customer, event) {
    this.setState({
      customer_select: true,
      customer: customer,
      customer_id: customer.customer_id
    });
  }

  render() {

    var search_customer_list = this.state.customer_list.map((customer_list, index) => {
      return (
        <tr key={index}>
          <td><strong>ชื่อ</strong> &nbsp;&nbsp; {customer_list.customer_name}</td>
          <td><strong>นามสกุล</strong> &nbsp;&nbsp; {customer_list.customer_lastname}</td>
          <td><strong>เลขประจำตัวประชาชน</strong> &nbsp;&nbsp; {customer_list.customer_card_id}</td>
          <td><strong>เบอร์โทรศัพท์</strong> &nbsp;&nbsp; {customer_list.customer_mobile}</td>
          <td>
            <button onClick={() => this.onCustomerSelect(customer_list, this)} type="button"
                    className="btn btn-primary">
              <i className="fa fa-hand-pointer-o"/>&nbsp;&nbsp; เลือก
            </button>
          </td>
        </tr>
      )
    })

    return (
      <div className="card card-accent-primary ">
        <div className="card-header">
          <strong className="text-title">{this.props.form_status==='add'? ' เพิ่ม' : ' แก้ไข'}เอกสารลูกค้า</strong>
        </div>
        <form
          action="" method="post"
          onSubmit={ this.handleSubmit }
          encType="multipart/form-data"
        >
          <div className="card-block">

            <div className={!this.state.customer_select ? 'row ' : ' hidden'}>
              <div className="controls col-md-12">
                <div className="input-group has-success ">
                  <input onChange={this.searchCustomer.bind(this)}
                         ref="search_customer_name"
                         placeholder="ค้นหาชื่อลูกค้า"
                         className="form-control"
                         size="16"
                         type="text"/>

                  <span className="input-group-btn ">
                                   <button className="btn btn-success"
                                           type="button"
                                           onClick={this.searchCustomer.bind(this)}>ค้นหา
                                   </button>
                              </span>
                </div>
                <div id="show_customer" className="row">
                  <div className="controls col-md-12">
                    <table className="table table-striped">
                      <tbody>
                      {search_customer_list}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div className={this.state.customer_select ? 'row ' : ' hidden'}>

              <div className="card-header controls col-md-12 hidden ">
                <strong className="text-title">ข้อมูลลูกค้า</strong>
              </div>

              <div className="card-block has-success">
                <div className="row">
                  <div className="col-lg-12">
                    <div className=" form-group row" style={styles.bottomLine}>
                      <label className="col-md-3"><strong> ชื่อลูกค้า </strong></label>
                      <div className="col-md-3">
                        <p className="text-justify text-success ">
                          <strong>{this.state.customer.customer_name}</strong></p>
                      </div>

                      <label className="col-md-3"><strong> นามสกุล </strong></label>
                      <div className="col-md-3">
                        <p className="text-justify text-success">
                          <strong> {this.state.customer.customer_lastname} </strong></p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className=" form-group row" style={styles.bottomLine}>
                      <label className="col-md-3"><strong>
                        เลขบัตรประชาชน </strong></label>
                      <div className="col-md-3">
                        <p className="text-justify text-success">
                          <strong>{this.state.customer.customer_card_id}</strong></p>
                      </div>

                      <label className="col-md-3"><strong>
                        เบอร์โทรศัพท์ </strong></label>
                      <div className="col-md-3">
                        <p className="text-justify text-success">
                          <strong> {this.state.customer.customer_mobile} </strong></p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className=" form-group row" style={styles.bottomLine}>
                      <label className="col-md-3"><strong>
                        ที่อยู่ปัจจุบัน </strong></label>
                      <div className="col-md-9">
                        <p className="text-justify text-success">
                          <strong>{this.state.customer.customer_address}</strong></p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>


            </div>


            <div className="row">
              <div className="form-group col-md-12">
                <label htmlFor="center_name">รายละเอียดเอกสาร</label>
                <textarea
                  name="doc_detail"
                  className="form-control"
                  value={this.state.doc_detail}
                  onChange={this.handleChange}
                  />
                {this.state.w_doc_detail ? <span  className="help-block text-danger">กรุณากรอกชื่อเอกสาร</span>: null }
              </div>
            </div>

            <div className="row">
              <div className="form-group col-md-12">
                <label htmlFor="car_repair_file">ไฟล์เอกสาร </label>
                <input type="file"
                       name="doc_file"
                       accept="image/*"
                       className="form-control"
                       onChange={(e)=>{
                         const { target } = e
                         if(target.value.length > 0){
                           this.setState({
                             w_customer_doc_file: false
                           });
                         } else {
                           //target.reset();
                           this.setState({
                             w_customer_doc_file: true
                           });
                         }

                       }}
                />
                { this.state.w_customer_doc_file ? <span  className="help-block text-danger">กรุณาเลือกไฟล์ ประเภท ( .jpg  .jpeg .png )</span>: null }

              </div>
            </div>



          </div>
          <div className="card-footer text-right">
            <button type="reset"
                    onClick={this.props.onCustomerDocSubmit.cancel}
                    className="btn btn-sm btn-danger mr-2">
              <i className="fa fa-refresh"/> ยกเลิก
            </button>
            <button type="submit"
                    className={this.props.form_status==='add'? "btn btn-sm btn-primary" :  "btn btn-sm btn-warning" }>
              <i className="fa fa-save"/>
              {this.props.form_status==='add'? ' บันทึก' : ' แก้ไข'}
            </button>

            <input type="hidden" id="customer_id" name="customer_id" value={this.state.customer_id}/>
            <input type="hidden" id="form_status" name="form_status" value={this.props.form_status}/>
            <input type="hidden" id="customer_doc_id" name="customer_doc_id" value={this.props.customer_doc_selected.customer_doc_id}/>

          </div>

        </form>
      </div>
    )
  }
}

const styles = {
  bottomLine: {
    borderBottomColor: '#ccc',
    borderBottomStyle: 'solid',
    borderWidth: 0.5,
  }
}

export default  CustomerDocAdd
