import React, { Component } from 'react';
import {connect} from "react-redux";
import CarDocList from "../CarDocList";

class CarDocAdd extends  Component{
  constructor (props){
    super(props)

    this.state = {
      car: this.props.car,
      w_car_doc_name: false,
      w_car_doc_file: false,
    }

    this.handleSubmit = this.handleSubmit.bind(this)
  }



  handleSubmit (event) {
    event.preventDefault();

    let car_doc_name = event.target.car_doc_name.value

    if(car_doc_name===''){
      this.setState({
        w_car_doc_name: true
      });
      return;
    }

    try {
      if(event.target.car_doc_file.files[0].name === undefined){
        this.setState({
          w_car_doc_file: false
        });
      }
    }catch (ex){
      this.setState({
        w_car_doc_file: true
      });
      return;
    }

    var filename = event.target.car_doc_file.files[0].name ;

    if(!(/\.(gif|jpg|jpeg|tiff|png|pdf)$/i).test(filename)){
      //alert(event.target.car_repair_file.files[0].type);
      this.setState({
        w_car_repair_file: true
      });
      return;
    }

    this.props.onCarDocSubmit.add(event)

  }

  render(){
    return(
      <div className="card card-accent-primary ">
        <div className="card-header">
          <strong className="text-title">
            {this.props.car_doc_type === 'normal' ? 'เพิ่มเอกสารรถ' : 'เพิ่มเอกสารลับ'}
          </strong>
        </div>
        <form
          action="" method="post"
          onSubmit={ this.handleSubmit }
          encType="multipart/form-data"
        >
          <div className="card-block">

            <div className="row">
              <div className="form-group col-md-12">
                <label htmlFor="center_name">ชื่อเอกสาร</label>
                <input type="text"
                       className="form-control"
                       name="car_doc_name"
                       id="car_doc_name"
                       placeholder="ชื่อเอกสาร"
                />
                {this.state.w_car_doc_name ? <span  className="help-block text-danger">กรุณากรอกชื่อเอกสาร</span>: null }
              </div>
            </div>

            <div className="row">
              <div className="form-group col-md-12">
                <label htmlFor="car_repair_file">ไฟล์เอกสาร (ภาพ, pdf)</label>
                <input type="file"
                       id="car_doc_file"
                       name="car_doc_file"
                       accept="image/*, application/pdf"
                       className="form-control"
                       onChange={(e)=>{
                         const { target } = e
                         if(target.value.length > 0){
                           this.setState({
                             w_car_doc_file: false
                           });
                         } else {
                           //target.reset();
                           this.setState({
                             w_car_doc_file: true
                           });
                         }

                       }}
                />
                { this.state.w_car_doc_file ? <span  className="help-block text-danger">กรุณาเลือกไฟล์ ประเภท ( .jpg  .jpeg .png )</span>: null }

              </div>
            </div>



          </div>
          <div className="card-footer text-right">
            <button type="reset"
                    onClick={this.props.onCarDocSubmit.cancel}
                    className="btn btn-sm btn-danger mr-2">
              <i className="fa fa-refresh"/> ยกเลิก
            </button>
            <button type="submit"
                    className="btn btn-sm btn-primary">
              <i className="fa fa-save"/> บันทึก
            </button>

            <input type="hidden" id="car_id" name="car_id" value={this.state.car.car_id}/>

          </div>

        </form>
      </div>
    )
  }
}



export default  CarDocAdd;
