import React, { Component } from 'react'
import axios from 'axios'
import moment from 'moment'
import { APIURL } from '../../../config/config'
import pdfMake from 'pdfmake/build/pdfmake'

class CarInAllSummary extends Component{

  constructor (props) {
    super(props)
    this.state = {
      car_list: [],
    }

    this.loadCarAll = this.loadCarAll.bind(this)
    this.printCarAll = this.printCarAll.bind(this)
  }

  componentDidMount() {
    this.loadCarAll();
  }

  loadCarAll() {
    var url = APIURL+'/car'
    axios.get(url).then(res => {
      // console.log(res.data.length)
      // console.log()
     let carData = res.data.sort((a, b) => {
        var dateA = new Date(a.car_date_in)
        var dateB = new Date(b.car_date_in)
        return (dateB.getTime() > dateA.getTime()) ? b : a
      })
     // console.log(carData)
     this.setState({car_list: carData});
    })
  }

  printCarAll() {

    var carAllRows = []
    var raito  = 100/6;
    var widthTable = [raito*0.5+'%', raito*0.9+'%', '*', raito*0.7+'%', raito*0.7+'%', raito*0.7+'%']
    var headerText = "ข้อมูลรถในเต็นท์ วันที่ "+moment(new Date()).local('TH').format('LLL')

    var tableHeader = [
      { style: 'bodyBold', text: 'ลำดับ',},
      { style: 'bodyBold', text: 'วันที่ซื้อเข้า',},
      { style: 'bodyBold', text: 'ยี่ห้อ/รุ่น',},
      { style: 'bodyBold', text: 'ทะเบียน',},
      { style: 'bodyBold', text: 'ราคาซื้อ',},
      { style: 'bodyBold', text: 'ราคาขาย',},
    ];

    carAllRows.push(tableHeader);

    this.state.car_list.forEach((car, index) => {
      var row = []
      var order = index + 1;
      var date_in = moment(car.car_date_in).format('DD-MM-YYYY')
      var car_detail = '#'+car.car_id+' '+car.car_name
      var license = car.car_license_plate_new
      var car_cost = car.car_cost.toLocaleString()
      var car_price = car.car_sale_price.toLocaleString()

      var itemOrder = {
        text: order,
        style: 'textCenter'
      };
      var itemDateIn = {
        text: date_in,
        style: 'textCenter'
      };
      var itemCarDetail = {
        text: car_detail,
        style: 'bodyText'
      };
      var itemLicense = {
        text: license,
        style: 'textCenter'
      }
      var itemCarCost = {
        text: car_cost,
        style: 'textCenter'
      }
      var itemCarPrice = {
        text: car_price,
        style: 'textCenter'
      }

      row.push(itemOrder)
      row.push(itemDateIn)
      row.push(itemCarDetail)
      row.push(itemLicense)
      row.push(itemCarCost)
      row.push(itemCarPrice)
      //
      carAllRows.push(row)
    });


    var docDefinition = {
      pageOrientation: 'portrait',
      pageSize: 'A4',
      pageMargins: [45, 30, 45, 30], // margin: [left, top, right, bottom]
      content: [
        {text: headerText, style: 'bodyHead', lineHeight: 1.3},
        {
          table: {
            headerRows: 1,
            widths: widthTable,
            body: carAllRows
          }
        },
      ],
      styles: {
        bodyHead: {
          fontSize: 16,
          bold: true,
          alignment: 'center'
        },
        bodyText: {
          fontSize: 14,
        },
        bodyBold: {
          fontSize: 14,
          bold: true,
          alignment: 'center'
        },
        textCenter: {
          fontSize: 14,
          alignment: 'center'
        },
      },
      defaultStyle: {
        font: 'THSarabunNew'
      }
    };

    pdfMake.createPdf(docDefinition).open()

  }

   render() {

     var sumCarCost = this.state.car_list.length > 0 ? this.state.car_list.map(car => car.car_cost).reduce((prev, car_cost) => prev + car_cost) : 0
     var sumSalePrice =  this.state.car_list.length > 0 ? this.state.car_list.map(car => car.car_sale_price).reduce((prev, car_sale_price) => prev + car_sale_price) : 0

     var tbodyList = this.state.car_list.map((car, index) => {
       return (
         <tr key={index}>
           <td className="text-center">{index+1}</td>
           <td className="text-center">
             {moment(car.car_date_in).format('DD-MM-YYYY')}
           </td>
           <td><div >#{car.car_id} | {car.car_name}</div></td>
           <td className="text-center">{car.car_license_plate_new}</td>
           <td className="text-right">{car.car_cost.toLocaleString()}</td>
           <td className="text-right">{car.car_sale_price.toLocaleString()}</td>
         </tr>
       )
     });

     var tbEmpty = (
       <tr><td colSpan={6} className="text-danger text-center">ไม่พบข้อมูลรถ</td></tr>
     )
     var tbBody = this.state.car_list.length===0 ? tbEmpty : tbodyList

     return (
       <div className="card">
         <div className="card-block">
           <div className="row">
             <div className="col-sm-12 col-md-12">
               <div className="card card-accent-info">
                 <div className="card-header">
                   <strong> ข้อมูลรถในเต็นท์ </strong>

                   <button className="btn btn-primary float-right" onClick={this.printCarAll}>
                     <i className="icon-printer"></i>&nbsp; พิมพ์
                   </button>
                 </div>
                 <div className="card-block">
                 <table className="table table-striped">
                   <thead>
                     <tr>
                       <th className="text-center">#</th>
                       <th className="text-center">วันที่ซื้อเข้า</th>
                       <th className="text-center">ยี่ห้อ/รุ่น</th>
                       <th className="text-center">ทะเบียน</th>
                       <th className="text-center">ราคาซื้อ</th>
                       <th className="text-center">ราคาขาย</th>
                     </tr>
                   </thead>
                   <tbody>
                   {tbBody}
                   <tr>
                     <td colSpan="4" className="text-right"><strong>รวม</strong></td>
                     <td className="text-right">
                       <strong>{sumCarCost.toLocaleString()}</strong>
                     </td>
                     <td className="text-right">
                       <strong>{sumSalePrice.toLocaleString()}</strong>
                     </td>
                   </tr>
                   </tbody>
                 </table>
                 </div>
               </div>
             </div>
           </div>
         </div>
       </div>
     )
   }
}

export default CarInAllSummary;