import React, { Component } from 'react'
import axios from 'axios'
import { APIURL } from '../../../config/config' // PORT
import DatePicker from 'react-datepicker'
// import moment from 'moment'
import NumberFormat from 'react-number-format'
import 'react-datepicker/dist/react-datepicker.css'
import { connect } from 'react-redux'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import { LineNotify } from '../../../config/lineNotify'
import { NumberUtil } from '../../../utils/number-util'
import { AlertSuccess, AlertWarning } from '../../Alert/Alert'
import Loading from '../../Loading'
import { Redirect } from 'react-router-dom'
import ReactQuill from 'react-quill'

pdfMake.vfs = pdfFonts.pdfMake.vfs
pdfMake.fonts = {
  THSarabunNew: {
    normal: 'THSarabunNew-Regular.ttf',
    bold: 'THSarabunNew-Bold.ttf',
    italics: 'THSarabunNew-Italic.ttf',
    bolditalics: 'THSarabunNew-BoldItalic.ttf'
  },
  Roboto: {
    normal: 'Roboto-Regular.ttf',
    bold: 'Roboto-Medium.ttf',
    italics: 'Roboto-Italic.ttf',
    bolditalics: 'Roboto-MediumItalic.ttf'
  }
}

class FinancePendingAdd extends Component {

  constructor (props) {
    super(props)

    this.state = {
      user: {},
      lists: [],
      load_data: true,
      car: '',
      bank: [],
      finance_statuses: [],
      branch: [],
      isLoading: false,

      insurance_company: [],
      insurance_type: [],
      customer_list: [],
      customer_select: false,
      sales_type: [],
      car_id: '',
      customer_id: '',
      customer: {},
      normal_sell_price: 0,
      rate_finance: 0,
      insurance_pa: 0,
      sum_down: 0,
      insurance_price: 0,
      cost_finance: 0,
      cost_transfer: 0,
      cost_period_month: 0,
      total_cost_period_call: 0,
      total_outof_car: 0,
      balance_debt: 0,
      // startDatePromise: new Date(),//moment(),
      car_sell_pay: [],
      startDatePaySell: new Date() ,//moment(),
      car_sell_pay_count: 0,
      count_period_id: 0,
      car_sell_pay_list: [],
      car_sell_type_id: '',
      car_pay_all: 0,
      insurance_buy: 'ประกันแถม',
      customer_cash: 0 , // เงินคืนลูกค้า

      vat_sell: 0, // vat ขาย รายรับ
      finance_commission: 0,  // ค่าคอมมิชชั่นไฟแนนท์ รายรับ
      // รายจ่ายเพิ่ม
      car_external_commission: 0, // ค่านายหน้า ภายนอก
      sales_commission: 0, // ค่าคอมมิชชั่นฝ่ายขาย
      additional_commission: 0, // ค่าคอมมิชชั่นเพิ่มเติม
      advertising_cost: 0, // ค่าโฆษณา
      delivery_fee: 0, // ค่าบริการส่งรถ
      promotion_fee: 0, // ค่าโปรโมชั่น
      finance_fee: 0, // ค่าธรรมเนียมไฟแนนท์
      transfer_fee: 0, // ค่าโอนรถ
      // pisan
      monthly_installment: 0,
      sale_by_id: '',
      employees: [],
      //
      finance_date: null,
      finance_comment: ''
    }

    // this.onInsuranceBuyChanged = this.onInsuranceBuyChanged.bind(this)
    // this.addCarSellPay      = this.addCarSellPay.bind(this)
    // this.toggleAgentModal = this.toggleAgentModal.bind(this)
    // this.handleCarIssueDate = this.handleCarIssueDate.bind(this)
    // this.handleFistInstallmentDate = this.handleFistInstallmentDate.bind(this)
    // this.handleOnSubmitSell = this.handleOnSubmitSell.bind(this)

    this.handleOnSubmitFinancePending = this.handleOnSubmitFinancePending.bind(this)
    this.loadEmployeeData = this.loadEmployeeData.bind(this)
    this.handleFinanceDate = this.handleFinanceDate.bind(this)
    this.loadFinanceStatus = this.loadFinanceStatus.bind(this)
    this.loadBranchData = this.loadBranchData.bind(this)

  }

  loadFinanceStatus() {
    axios.get(APIURL + '/finance_status/')
      .then(res => {
        this.setState({finance_statuses: res.data})
      }).catch(error => {
      this.setState({finance_statuses: []})
    })
  }

  loadBankData () {
    axios.get(APIURL + '/bank/')
      .then(res => {
        this.setState({bank: res.data})
      }).catch(error => {
      this.setState({bank: []})
    })
  }

  loadBranchData () {
    axios.get(APIURL + '/branch/')
      .then(res => {
        this.setState({branch: res.data})
      }).catch(error => {
      this.setState({branch: []})
    })
  }

  loadCarSellTypeData () {
    axios.get(APIURL + '/car_sell_type/')
      .then(res => {
        this.setState({car_sell_type: res.data})
        //console.log(res.data)
        //alert (res.data)
      }).catch(error => {
      this.setState({car_sell_type: []})
    })
  }

  loadInsuranceCompanyData () {
    axios.get(APIURL + '/insurance_company/')
      .then(res => {
        this.setState({insurance_company: res.data})
        //console.log(res.data)
        //alert (res.data)
      }).catch(error => {
      this.setState({insurance_company: []})
    })
  }

  loadInsuranceTypeData () {
    axios.get(APIURL + '/insurance_type/')
      .then(res => {
        this.setState({insurance_type: res.data})
        //console.log(res.data)
        //alert (res.data)
      }).catch(error => {
      this.setState({insurance_type: []})
    })
  }

  loadCountPeriodData () {
    axios.get(APIURL + '/count_period/')
      .then(res => {
        this.setState({count_period: res.data})
        //console.log(res.data)
        //alert (res.data)
      }).catch(error => {
      this.setState({count_period: []})
    })
  }

  loadDurationPeriodData () {
    axios.get(APIURL + '/duration_period/')
      .then(res => {
        this.setState({duration_period: res.data})
        //console.log(res.data)
        //alert (res.data)
      }).catch(error => {
      this.setState({duration_period: []})
    })
  }

  loadCarSellListPayData () {
    axios.get(APIURL + '/car_sell_list_pay/')
      .then(res => {
        this.setState({car_sell_list_pay: res.data})
        //console.log(res.data)
        //alert (res.data)
      }).catch(error => {
      this.setState({car_sell_list_pay: []})
    })
  }

  loadCarStatusData () {
    axios.get(APIURL + '/car_status/sell/1')
      .then(res => {
        this.setState({car_status: res.data})
        //console.log(res.data)
        //alert (res.data)
      }).catch(error => {
      console.log(error)
      this.setState({car_status: []})
    })
  }

  /*handleDatePromise (date) {
    this.setState({
      startDatePromise: date
    })
  }*/

  /*handleCarIssueDate (date) {
    this.setState({
      car_issue_date: date
    })
  }*/

  /*handleFistInstallmentDate (date) {
    this.setState({
      first_installment: date
    })
  }*/

  /*handleCarDateTransfer (date) {
    this.setState({
      car_transfer_date: date
    })
  }*/

  /*handleFinancePaymentDate (date) {
    this.setState({
      finance_payment_date: date
    })
  }*/

  handleFinanceDate (date) {
    this.setState({
      finance_date: date
    })
  }

  handleOnSubmitFinancePending(event) {
    event.preventDefault()

    // for test redirect
    /*this.setState({
      isLoading: false
    }, () => {
      //
      AlertSuccess('บันทึกข้อมูลการจัดเรียบร้อยแล้ว')
      setTimeout(() => {
        let target = '/finance-pending/list/?car_id=' + this.state.car_id
        // <Redirect push to={target}/>
        this.props.history.push(target)
      }, 2000)
    })
    return*/

    let car_id = this.state.car_id
    let customer_id = this.state.customer_id
    let branch_id = event.target.branch_id.value
    let sale_by_id = event.target.sale_by_id.value
    let finance_date = event.target.finance_date.value
    let bank_finance_id = event.target.bank_finance_id.value
    // let rate_finance = event.target.rate_finance.value
    let finance_status_id = event.target.finance_status_id.value
    let rate_finance = event.target.rate_finance.value
    let finance_contract = event.target.finance_contract.value
    let finance_detail = event.target.finance_detail.value
    let sum_down = event.target.sum_down.value === '' ? 0 : event.target.sum_down.value
    let monthly_installment = event.target.monthly_installment.value === '' ? 0 : event.target.monthly_installment.value
    let user = this.state.user.user
    let finance_comment = this.state.finance_comment

    if (this.state.customer_id === '') {
      AlertWarning('กรุณาเลือกลูกค้า')
      this.refs.search_customer_name.focus()
      return
    }

    // finance_date
    if (finance_date === '') {
      AlertWarning('กรุณาเลือกวันที่จัดรถ');
      return
    }

    if (branch_id === '') {
      AlertWarning('กรุณาเลือกสาขาที่จัด')
      this.refs.branch_id.focus()
      return
    }

    if (sale_by_id === '') {
      AlertWarning('กรุณาเลือกผู้จัด');
      return
    }

    // bank_finance_id
    if (bank_finance_id === '') {
      AlertWarning('กรุณาเลือกไฟแนนซ์');
      return
    }

    // rate_finance
    // console.log(rate_finance, typeof rate_finance)
    // if (rate_finance === '' || rate_finance === '0') {
    //   AlertWarning('กรุณากรอกยอดจัดไฟแนนซ์');
    //   return
    // }

    let data = {
      car_id,
      customer_id,
      branch_id,
      sale_by_id,
      finance_date,
      bank_finance_id,
      finance_contract,
      rate_finance,
      sum_down,
      monthly_installment,
      finance_status_id,
      finance_detail,
      user,
      finance_comment
    }
    // console.log(data)

    axios.post(APIURL + '/finance_pending', data)
      .then(res => {

        if (res.status === 200) {
          this.setState({
            isLoading: false
          }, () => {
            //
            AlertSuccess('บันทึกข้อมูลการจัดเรียบร้อยแล้ว')
            setTimeout(() => {
              let target = '/finance-pending/list/?car_id=' + this.state.car_id
              // <Redirect push to={target}/>
              this.props.history.push(target)
            }, 2000)

          })
        }
      }).catch(error => {
      console.log(error)
      this.setState({ isLoading: false })
    })

    // return false
  }

  loadCarById () {
    //var that = this
    axios.get(APIURL + '/car/' + this.state.car_id)
      .then(res => {
        let car = res.data[0]
        this.setState({
          car: car,
          normal_sell_price: car.car_sale_price,
        })
      }).catch(error => {
      alert('พบข้อผิดพลาด: ' + error)
      this.setState({car: {}})
    })
  }

  componentWillMount () {
    //const queryString = require('query-string');
    this.setState({
      user: JSON.parse(this.props.user),
    })
  }

  componentDidMount () {
    const queryString = require('query-string')
    let car = queryString.parse(this.props.location.search)
    //this.setState({car_id : car.car_id});
    //alert('car_id='+car.car_id)
    this.setState({car_id: car.car_id}, () => {
      this.loadCarById()
      //this.loadDataCarSellPay ()
    })

    this.loadBankData()
    this.loadBranchData()
    this.loadInsuranceCompanyData()
    this.loadInsuranceTypeData()
    this.loadEmployeeData()
    this.loadFinanceStatus()
    // this.loadCarSellTypeData()
    // this.loadCountPeriodData()
    // this.loadDurationPeriodData()
    // this.loadCarSellListPayData()
    // this.loadCarStatusData()
    // this.loadSalesTypeData()
  }

  searchCustomer () {
    var search_text = this.refs.search_customer_name.value
    this.getDataCustomerSearch(search_text)
  }

  getDataCustomerSearch (search_text) {
    axios.get(APIURL + '/customer/search/' + search_text)
      .then(res => {
        if(res.status === 200) {
          this.setState({customer_list: res.data})
        }
      }).catch(error => {
      this.setState({customer_list: []})
    })
  }

  onCustomerSelect (customer, event) {
    this.setState({
      customer_select: true,
      customer: customer,
      customer_id: customer.customer_id
    })
  }

  loadEmployeeData() {
    axios.get(APIURL + '/employee/')
      .then(res => {
        // console.log(res.data);
        if(res.status === 200) {
          let data = res.data
          if (data.length > 0) {
            let dataFilter = data.filter(d => d.employee_position.indexOf('ขาย') !== -1)
            this.setState({ employees: dataFilter })
          } else {
            this.setState({employees: [] })
          }
        }
      }).catch(error => {
      // console.log(error);
      this.setState({employees: [] })
    })
  }

  render () {
    let car = this.state.car

    // let fullname = this.state.user.fullname
    // console.log('fullname=', fullname)

    let search_customer_list = this.state.customer_list.map((customer_list, index) => {
      return (
        <tr key={index}>
          <td><strong>ชื่อ</strong> &nbsp;&nbsp; {customer_list.customer_name}</td>
          <td><strong>นามสกุล</strong> &nbsp;&nbsp; {customer_list.customer_lastname}</td>
          <td><strong>เลขประจำตัวประชาชน</strong> &nbsp;&nbsp; {customer_list.customer_card_id}</td>
          <td><strong>เบอร์โทรศัพท์</strong> &nbsp;&nbsp; {customer_list.customer_mobile}</td>
          <td>
            <button onClick={() => this.onCustomerSelect(customer_list, this)} type="button"
                    className="btn btn-primary">
              <i className="fa fa-hand-pointer-o"></i>&nbsp;&nbsp; เลือก
            </button>
          </td>
        </tr>
      )
    })

    let bank_finance_list = this.state.bank.map((bank, index) => {
      return (
        <option key={index} value={bank.bank_id}>{bank.bank_name}</option>
      )
    })

    let branch_list = this.state.branch.map((branch, index) => {
      return (
        <option key={index} value={branch.branch_id}>{branch.branch_name}</option>
      )
    })

    /*
    var bank_pay_list = this.state.bank.map((bank, index) => {
      return (
        <option key={index} value={bank.bank_id}>{bank.bank_name}</option>
      )
    })

    var car_sell_type_list = this.state.car_sell_type.map((car_sell_type, index) => {
      return (
        <option key={index}
                value={car_sell_type.car_sell_type_id}>{car_sell_type.car_sell_type_name}</option>
      )
    })

    var insurance_company_list = this.state.insurance_company.map((insurance_company, index) => {
      return (
        <option key={index}
                value={insurance_company.insurance_company_id}>{insurance_company.insurance_company_name}</option>
      )
    })

    var insurance_type_list = this.state.insurance_type.map((insurance_type, index) => {
      return (
        <option key={index}
                value={insurance_type.insurance_type_id}>{insurance_type.insurance_type_name}</option>
      )
    })


    var duration_period_list = this.state.duration_period.map((duration_period, index) => {
      return (
        <option key={index}
                value={duration_period.duration_period_id}>{duration_period.duration_period_name}</option>
      )
    })

    var car_sell_list_pay_list = this.state.car_sell_list_pay.map((car_sell_list_pay, index) => {
      return (
        <option key={index}
                value={car_sell_list_pay.car_sell_list_pay_id}>{car_sell_list_pay.car_sell_list_pay_name}</option>
      )
    })

    var car_sell_status_list = this.state.car_status.map((car_status, index) => {
      return (
        <option key={index} value={car_status.car_status_id}>{car_status.car_status_name}</option>
      )
    })
    */

    let finance_statuses_list = this.state.finance_statuses.map((finance_status, index) => {
      return (
        <option key={index} value={finance_status.finance_status_id}>{finance_status.finance_status_name}</option>
      )
    })


    // finance_statuses

    let employee_list = this.state.employees.map((employee, index) => {
      return (
        <option key={index} value={employee.employee_id}> {employee.employee_name}&nbsp;&nbsp;{employee.employee_lastname} </option>
      )
    })

    /*let obj_photo;

     */

    return (
      <div className="card">

        <Loading isLoading={this.state.isLoading} />

        <div className="card-block">
          <form action="" method="post" onSubmit={this.handleOnSubmitFinancePending}>
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header">
                    <strong className="text-title">เพิ่มรายละเอียดการจัดรถ</strong>
                  </div>

                  <div className="card-block">
                    <div className="row" style={styles.bottomLine}>
                      <div className="col-lg-12">

                        <div className=" form-group row" style={styles.bottomLine}>
                          <label className="col-md-3 ">
                            <strong> ชื่อรถ </strong></label>
                          <div className="col-md-3">
                            <p className="text-justify text-primary">
                              <strong> {car.car_name} </strong>
                            </p>
                          </div>

                          <label className="col-md-3"><strong> ยี่ห้อ </strong></label>
                          <div className="col-md-3">
                            <p className="text-justify text-primary">
                              <strong> {car.car_brand_name} </strong></p>
                          </div>
                        </div>

                        <div className=" form-group row" style={styles.bottomLine}>
                          <label className="col-md-3 "><strong> รุ่นรถ </strong></label>
                          <div className="col-md-3">
                            <p className="text-justify text-primary">
                              <strong> {car.car_model_name} </strong></p>
                          </div>

                          <label className="col-md-3"><strong> รุ่นย่อย </strong></label>
                          <div className="col-md-3">
                            <p className="text-justify text-primary">
                              <strong> {car.car_sub_model_name} </strong></p>
                          </div>
                        </div>

                        <div className=" form-group row" style={styles.bottomLine}>
                          <label className="col-md-3"><strong> ปีรถ </strong></label>
                          <div className="col-md-3">
                            <p className="text-justify text-primary">
                              <strong> {car.car_year_name} </strong></p>
                          </div>

                          <label className="col-md-3"><strong> ระบบเกียร์ </strong></label>
                          <div className="col-md-3">
                            <p className="text-justify text-primary">
                              <strong> {car.car_gear_name} </strong></p>
                          </div>
                        </div>

                        <div className=" form-group row" style={styles.bottomLine}>
                          <label className="col-md-3"><strong> สีรถ </strong></label>
                          <div className="col-md-3">
                            <p className="text-justify text-primary">
                              <strong> {car.car_color_name} </strong></p>
                          </div>

                          <label className="col-md-3"><strong> เลขไมล์ </strong></label>
                          <div className="col-md-3">
                            <p className="text-justify text-primary">
                              <strong> {car.car_miles} </strong></p>
                          </div>
                        </div>

                        <div className=" form-group row" style={styles.bottomLine}>
                          <label className="col-md-3"><strong> ป้ายทะเบียนรถ </strong></label>
                          <div className="col-md-3">
                            <p className="text-justify text-primary">
                              <strong> {car.car_license_plate_new} </strong></p>
                          </div>

                          <label className="col-md-3"><strong> จังหวัด </strong></label>
                          <div className="col-md-3">
                            <p className="text-justify text-primary">
                              <strong> {car.province_name_new} </strong></p>
                          </div>
                        </div>

                        <div className={!this.state.customer_select ? 'row ' : ' hidden'}>
                          <div className="controls col-md-12">
                            <div className="input-group has-success ">
                              <input onChange={this.searchCustomer.bind(this)}
                                     ref="search_customer_name"
                                     placeholder="ค้นหาชื่อลูกค้า"
                                     className="form-control"
                                     size="16"
                                     type="text"/>

                              <span className="input-group-btn ">
                                   <button className="btn btn-success"
                                           type="button"
                                           onClick={this.searchCustomer.bind(this)}>ค้นหา
                                   </button>
                              </span>
                            </div>
                            <div id="show_customer" className="row">
                              <div className="controls col-md-12">
                                <table className="table table-striped">
                                  <tbody>
                                  {search_customer_list}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className={this.state.customer_select ? 'row ' : ' hidden'}>

                          <div className="card-header controls col-md-12 hidden ">
                            <strong>ข้อมูลลูกค้า</strong>
                          </div>

                          <div className="card-block has-success">
                            <div className="row">
                              <div className="col-lg-12">
                                <div className=" form-group row" style={styles.bottomLine}>
                                  <label className="col-md-3"><strong> ชื่อลูกค้า </strong></label>
                                  <div className="col-md-3">
                                    <p className="text-justify text-success ">
                                      <strong>{this.state.customer.customer_name}</strong></p>
                                  </div>

                                  <label className="col-md-3"><strong> นามสกุล </strong></label>
                                  <div className="col-md-3">
                                    <p className="text-justify text-success">
                                      <strong> {this.state.customer.customer_lastname} </strong></p>
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-12">
                                <div className=" form-group row" style={styles.bottomLine}>
                                  <label className="col-md-3"><strong>
                                    เลขบัตรประชาชน </strong></label>
                                  <div className="col-md-3">
                                    <p className="text-justify text-success">
                                      <strong>{this.state.customer.customer_card_id}</strong></p>
                                  </div>

                                  <label className="col-md-3"><strong>
                                    เบอร์โทรศัพท์ </strong></label>
                                  <div className="col-md-3">
                                    <p className="text-justify text-success">
                                      <strong> {this.state.customer.customer_mobile} </strong></p>
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-12">
                                <div className=" form-group row" style={styles.bottomLine}>
                                  <label className="col-md-3"><strong>
                                    ที่อยู่ปัจจุบัน </strong></label>
                                  <div className="col-md-9">
                                    <p className="text-justify text-success">
                                      <strong>{this.state.customer.customer_address}</strong></p>
                                  </div>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>

                        <div className="row">

                          <div className="form-group col-sm-3">
                            <label htmlFor="finance_date">วันที่จัดรถ </label> <br/>
                            <DatePicker
                              selected={this.state.finance_date}
                              onChange={this.handleFinanceDate}
                              dateFormat="YYYY-MM-dd"
                              type="text"
                              className="form-control"
                              id="finance_date"
                              name="finance_date"
                              placeholder=""
                            />
                          </div>


                          <div className="form-group col-sm-2">
                            <label htmlFor="branch">สาขาที่จัด</label>
                            <select className="form-control"
                                    id="branch_id"
                                    ref="branch_id"
                            >
                              <option value=''> เลือกสาขาที่จัด </option>
                              {branch_list}
                            </select>
                          </div>

                          <div className="form-group col-sm-2">
                            <label htmlFor="car_sell_type">จัดโดย</label>
                            <select
                              className="form-control"
                              id="sale_by_id"
                              name="sale_by_id"
                              onChange={(e) => {
                                this.setState({
                                  sale_by_id: e.target.value
                                })
                              }}
                            >
                              <option value=''> เลือกพนักงานผู้จัด </option>
                              {employee_list}
                            </select>
                          </div>

                          <div className="form-group col-sm-2">
                            <label htmlFor="bank_finance_id">ไฟแนนซ์</label>
                            <select
                              className="form-control"
                              id="bank_finance_id"
                              name="bank_finance_id"
                              ref="bank_finance_id"
                            >
                              <option value=""> เลือกไฟแนนซ์</option>
                              {bank_finance_list}
                            </select>
                          </div>

                          <div className="form-group col-sm-3">
                            <label htmlFor="finance_contract">ผู้รับจด</label>
                            <input style={styles.toUpper}
                                   type="text"
                                   className="form-control"
                                   name="finance_contract"
                                   placeholder="ผู้ติดต่อของไฟแนนซ์"
                            />
                          </div>


                        </div>

                        <div className="row">

                          <div className="form-group col-sm-3">
                            <label htmlFor="normal_sell_price">ราคาขายปกติ</label>
                            <input type="text"
                                   className="form-control hidden"
                                   id="normal_sell_price"
                                   name="normal_sell_price"
                                   placeholder=""
                                   ref="normal_sell_price"
                                   value={this.state.normal_sell_price}
                                   onChange={()=>{}}
                            />
                            <NumberFormat
                              className="form-control text-primary"
                              thousandSeparator={true}
                              prefix={'฿'}
                              //placeholder="กรอกค่าโอน"
                              min={0}
                              max={1000000000}
                              step={1000}
                              size={10}
                              allowNegative={false}
                              value={this.state.normal_sell_price}
                              displayType="text"
                            />
                          </div>

                          <div className="form-group col-sm-2">
                            <label htmlFor="rate_finance">ยอดจัดไฟแนนซ์</label>
                            <input type="text"
                                   className="form-control hidden"
                                   id="rate_finance"
                                   name="rate_finance"
                                   placeholder=""
                                   ref="rate_finance"
                                   value={this.state.rate_finance}
                                   onChange={()=>{}}
                            />
                            <NumberFormat
                              className="form-control"
                              thousandSeparator={true}
                              prefix={'฿'}
                              min={0}
                              max={1000000000}
                              step={1000}
                              size={10}
                              allowNegative={false}
                              onValueChange={(values) => {
                                const {value} = values
                                this.setState({rate_finance: value})
                              }}
                            />
                          </div>

                          <div className="form-group col-sm-2">
                            <label htmlFor="sum_down">ยอดดาวน์</label>
                            <input type="text"
                                   className="form-control hidden"
                                   id="sum_down"
                                   name="sum_down"
                                   placeholder=""
                                   ref="sum_down"
                                   value={this.state.sum_down}
                                   onChange={()=>{}}
                            />
                            <NumberFormat
                              className="form-control text-primary"
                              thousandSeparator={true}
                              prefix={'฿'}
                              min={0}
                              max={1000000000}
                              step={1000}
                              size={10}
                              allowNegative={false}
                              onValueChange={(values) => {
                                const {value} = values
                                this.setState({sum_down: value})
                              }}
                            />
                          </div>

                          <div className="form-group col-sm-2">
                            <label htmlFor="monthly_installment">ค่างวด</label>
                            <input type="text"
                                   className="form-control hidden"
                                   id="monthly_installment"
                                   name="monthly_installment"
                                   placeholder=""
                                   ref="monthly_installment"
                                   value={this.state.monthly_installment}
                                   onChange={()=>{}}
                            />
                            <NumberFormat
                              className="form-control"
                              thousandSeparator={true}
                              prefix={'฿'}
                              //placeholder="กรอกค่าโอน"
                              min={0}
                              max={1000000000}
                              step={1000}
                              size={10}
                              allowNegative={false}
                              onValueChange={(values) => {
                                const {value} = values
                                this.setState({monthly_installment: value})
                              }}
                            />
                          </div>

                          <div className="form-group col-sm-2">
                            <label htmlFor="finance_status_id">สถานะจัด</label>
                            <select className="form-control"
                                    id="finance_status_id"
                                    name="finance_status_id"
                            >
                              {finance_statuses_list}
                            </select>
                          </div>
                        </div>

                        <div className="row">
                          <div className="form-group col-sm-12">
                            <label htmlFor="finance_detail">รายละเอียดการจัด</label>
                            <input style={styles.toUpper}
                                   type="text"
                                   className="form-control"
                                   name="finance_detail"
                                   placeholder="รายละเอียดการจัด"
                            />
                          </div>
                        </div>

                        <div className="row mt-2">
                          <div className="form-group col-sm-12">
                            <label htmlFor="finance_comment">หมายเหตุการจัด</label>
                            <ReactQuill theme="snow"
                                        value={this.state.finance_comment}
                                        onChange={(val) => {
                                          this.setState({
                                            finance_comment: val
                                          })
                                        }}
                            />
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

                <div className="card-footer text-right">
                  <button type="reset"
                          className="btn btn-sm btn-danger mr-2"
                  >
                    <i className="fa fa-refresh"/> ยกเลิก
                  </button>
                  <button type="submit"
                          className="btn btn-sm btn-primary">
                    <i className="fa fa-save"/> บันทึกจัดไฟแนนซ์
                  </button>
                </div>

              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }

}

const styles = {
  bottomLine: {
    borderBottomColor: '#ccc',
    borderBottomStyle: 'solid',
    borderWidth: 0.5,
  },
  bottomLineAndMargin: {
    borderBottomColor: '#ccc',
    borderBottomStyle: 'solid',
    borderWidth: 0.5,
    paddingTop: 8
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(FinancePendingAdd)
