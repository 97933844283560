import React, { Component } from 'react';
import axios from 'axios';
import { APIURL } from '../../../config/config';
import {connect} from 'react-redux';
import CarModelListRow from "../CarModelListRow";
import CarModelForm from "../CarModelForm";

// import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
// import BankForm from "../../Bank/BankForm";

class CarModelList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      lists: [],
      car_brands: [],
      car_models: [],
      car_sub_models: [],
      load_data: true,
      danger: false,
      action: 'บันทึก',
      account: {},
      user_delete: '',
      user: {},
      model_id: 0,

      tmp_car_brand: '',
      tmp_car_model: '',
      tmp_car_sub_model: '',

      car_model_name_select: '',
      car_model_select: false,

      car_model_update_name: '',
      car_model_update: false,

      car_sub_model_update_name: '',
      car_sub_model_update: false,

      actionType: 'บันทึก',
      actionTypeSub: 'บันทึก'
    }
    this.handleCarBrandChange =  this.handleCarBrandChange.bind(this)
    this.loadCarSubModel = this.loadCarSubModel.bind(this)
    this.onRefresh = this.onRefresh.bind(this);
    this.onReloadCarModel = this.onReloadCarModel.bind(this);
    this.onCarModelSelect = this.onCarModelSelect.bind(this);
    this.onCarModelUpdate = this.onCarModelUpdate.bind(this);
    this.onChangeSwitch = this.onChangeSwitch.bind(this);
    this.onCarSubModelUpdate = this.onCarSubModelUpdate.bind(this);
    this.onReloadCarSubModel = this.onReloadCarSubModel.bind(this);
    this.onChangeSwitchSubModel = this.onChangeSwitchSubModel.bind(this);
  }

  componentWillMount () {
    //const queryString = require('query-string');
    var user = JSON.parse(this.props.user);
    this.setState({
      user: user
    })

  }

  componentDidMount () {
    this.loadCarBrandData()
    // this.loadCarModelData()
  }

  loadCarBrandData () {
    axios.get(APIURL + '/car_brand/')
        .then(res => {
          this.setState({car_brands: res.data})
          //console.log(res.data)
          //alert (res.data)
        }).catch(error => {
      this.setState({car_brands: []})
    })
  }

  handleCarBrandChange (event) {
    let car_brand_id = event.target.value
    let car_brand_name = event.target.options[event.target.selectedIndex].text

    axios.get(APIURL + '/car_brand_model/all/' + car_brand_id)
        .then(res => {
          // console.log(res.data)
          this.setState({
            car_models: res.data,
            tmp_car_brand: car_brand_name,
            tmp_car_model: '',
            tmp_car_sub_model: '',
            car_brand_id: car_brand_id,
          })
          //that.makeCarName()
        }).catch(error => {
          this.setState({car_models: []})
    })
  }

  onReloadCarModel(car_brand_id){
    axios.get(APIURL + '/car_brand_model/all/' + car_brand_id)
        .then(res => {
          // console.log(res.data)
          this.setState({
            car_models: res.data,
            actionType: 'บันทึก',
            //actionTypeSub:'บันทึก'
          })
        }).catch(error => {
      this.setState({car_models: []})
    })
  }

  onReloadCarSubModel(car_model_id){
    axios.get(APIURL + '/car_sub_model/all/' + car_model_id)
        .then(res => {
          // console.log(res.data)
          this.setState({
            car_sub_models: res.data,
            actionTypeSub:'บันทึก'
          })
        }).catch(error => {
      this.setState({car_sub_models: []})
    })
  }

  onCarModelSelect(brand_model){
    this.setState({
      car_model_select: true,
      car_model_name_select: brand_model.car_model_name,
      car_model_id_select: brand_model.car_model_id
    }, ()=>{
      this.loadCarSubModel(brand_model.car_model_id)
    })
    // console.log('เลือก =',brand_model)
  }

  onChangeSwitch(checked,car_model){
    // console.log(checked,car_model)
   let car_model_update = car_model
   let car_models =  this.state.car_models
   let car_model_index = car_models.findIndex(model => model.car_model_id=== car_model.car_model_id)

    if(checked === false){
      car_model_update.status = 'hidden'
    }else {
      car_model_update.status = 'show'
    }

    // TODx: update car_model_status
    axios.post(APIURL + '/car_brand_model/status', car_model_update)
        .then(res => {
        }).catch(error => {
    })

    car_models[car_model_index] = car_model_update
    this.setState({
      car_models:car_models
    })
  }

  onChangeSwitchSubModel(checked,car_sub_model){
    // console.log(checked,car_model)

    let car_sub_model_update = car_sub_model
    let car_sub_models =  this.state.car_sub_models
    let car_sub_model_index = car_sub_models.findIndex(sub_model => sub_model.car_sub_model_id=== car_sub_model.car_sub_model_id)


    if(checked === false){
      car_sub_model_update.status = 'hidden'
    }else {
      car_sub_model_update.status = 'show'
    }

    // TODO: update car_sub_model_status
    axios.post(APIURL + '/car_sub_model/status', car_sub_model_update)
        .then(res => {
        }).catch(error => {
    })

    car_sub_models[car_sub_model_index] = car_sub_model_update
    this.setState({
      car_sub_models:car_sub_models
    })
  }

  onCarModelUpdate(brand_model){
    this.setState({
      car_model_update: true,
      car_model_update_name: brand_model.car_model_name,
      car_model_update_id: brand_model.car_model_id,
      actionType: 'แก้ไข'
      // customer: customer,
    })
    // console.log('แก้ไข =',brand_model)
  }

  onCarSubModelUpdate(brand_model){
    this.setState({
      car_sub_model_update: true,
      car_sub_model_update_name: brand_model.car_sub_model_name,
      car_sub_model_update_id: brand_model.car_sub_model_id,
      actionTypeSub: 'แก้ไข'
      // customer: customer,
    })
    // console.log('แก้ไข sub =',brand_model)
  }

  onRefresh(){
    this.refs.car_brand_id.value = ''
    this.setState({
      car_models : [],
      car_sub_model_name : [],
      car_model_update_name : '',
      car_sub_models : [],
      car_sub_model_update_name : '',
      car_model_name_select: '',
      actionType: 'บันทึก',
      actionTypeSub: 'บันทึก'
    })
    this.loadCarBrandData()
  }

  loadCarSubModel(car_model_id){

    axios.get(APIURL + '/car_sub_model/all/' + car_model_id)
        .then(res => {
          this.setState({car_sub_models: res.data})
        }).catch(error => {
      this.setState({car_sub_models: []})
    })
    //console.log('loadCarSubModel')
  }

  render () {

    let car_brand_list = this.state.car_brands.map((car_brand, index) => {
      return (
          <option key={index} value={car_brand.car_brand_id}>{car_brand.car_brand_name}</option>
      )
    })

    /*let car_brand_model = this.state.car_models.map((car_model, index) => {
      return (
          <option key={index} value={car_model.car_model_id}>{car_model.car_model_name}</option>
      )
    })*/

    let car_sub_model = this.state.car_sub_models.map((car_sub_model, index) => {
      return (
          <option key={index} value={car_sub_model.car_sub_model_id}>{car_sub_model.car_sub_model_name}</option>
      )
    })

    return (
        <div className="card">
          <div className="card-block">
            <div className="row">

              <div className="row col-sm-12">

                <div className="col-sm-2 mt-1">
                  <strong>ยี่ห้อรถ</strong>
                </div>

                <div className="">
                  <select className="form-control"
                          id="car_brand_id"
                          name="car_brand_id"
                          ref="car_brand_id"
                          style={styles.selectBrand}
                          onChange={this.handleCarBrandChange}
                  >
                    <option value="">เลือกยี่ห้อรถ</option>
                    {car_brand_list}
                  </select>
                </div>

                <div className="col-sm-2">
                  <button
                      className="btn btn-sm btn-head-bar"
                      onClick={this.onRefresh.bind(this)}
                      style={styles.buttonHeight}
                  >
                    <i className="icon-refresh"/>&nbsp; คืนค่าเริ่มต้น
                  </button>
                </div>
              </div>

              <div className="col-sm-6">
                <CarModelListRow
                    car_brand_model_list={this.state.car_models}
                    car_brand_id = {this.state.car_brand_id}
                    onReloadCarModel = {this.onReloadCarModel}
                    onCarModelSelect = {this.onCarModelSelect}
                    onCarModelUpdate = {this.onCarModelUpdate}
                    onChangeSwitch = {this.onChangeSwitch}
                    car_model_name = {this.state.car_model_update_name}
                    car_model_id = {this.state.car_model_update_id}
                    actionType = {this.state.actionType}
                />
              </div>

              <div className="col-sm-6">
                <CarModelForm
                    car_model_name = {this.state.car_model_name_select}
                    car_sub_model_list={this.state.car_sub_models}
                    car_model_id = {this.state.car_model_id_select}
                    car_brand_id = {this.state.car_brand_id}
                    onReloadCarSubModel = {this.onReloadCarSubModel}
                    onCarSubModelUpdate = {this.onCarSubModelUpdate}
                    car_sub_model_name = {this.state.car_sub_model_update_name}
                    car_sub_model_id = {this.state.car_sub_model_update_id}
                    actionTypeSub = {this.state.actionTypeSub}
                    onChangeSwitchSubModel = {this.onChangeSwitchSubModel}
                />
              </div>
            </div>

          </div>
        </div>
    )
  }
}

const styles = {
  selectBrand: {
    marginLeft: -55,
    width: 300,
    borderRadius: 5,
    display: 'inline',
    marginBottom: 20
  },

  buttonHeight: {
    height: 36,
    //width: 36,
    paddingTop:0,
    paddingBottom:0,
    borderRadius:5,
    display: 'inline',
    marginLeft: 0
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(CarModelList);