import React, {Component} from 'react'
import { Modal, ModalBody } from 'reactstrap'
import axios from 'axios'
import MaskedInput from 'react-maskedinput'
import 'react-datepicker/dist/react-datepicker.css'
import { APIURL } from '../../../config/config'
import moment from 'moment'
import 'moment/locale/th'
import { AlertError, AlertSuccess, AlertWarning } from '../../Alert/Alert'
import { connect } from 'react-redux'
moment.locale('th');

class AgentFormModal extends Component{
  constructor (props) {
    super(props)
    this.state = {
      user: JSON.parse(this.props.user),
      startDateAgentNew1: moment().format('YYYY-MM-DD'),
      w_agent_file: false,
      agent_fullname: '',
      agent_address: '',
      agent_card_id: '',
      agent_mobile: '',
      agent_birth_text: '',
      agent_id: null,
      agent_list: [],
      width: 0,
      height: 0
    }
    this.handleOnSubmitAgent = this.handleOnSubmitAgent.bind(this);
    this.onMaskedDateAgent = this.onMaskedDateAgent.bind(this);
    this.convertBBtoCC = this.convertBBtoCC.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  componentDidMount () {
    // console.log('agentSelected:', this.props.agentSelected)
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    // console.log('componentDidUpdate', prevProps.agentSelected, this.props.agentSelected)
    if (this.props.agentSelected !== null) {
      if(prevProps.agentSelected !==  this.props.agentSelected) {
        const agent = this.props.agentSelected
        // customer_birth_mask: this.convertCCtoBB(moment(this.props.customer.customer_birth).format("YYYY-MM-DD")),
        this.setState({
          agent_fullname: agent.agent_fullname,
          agent_address: agent.agent_address,
          agent_card_id: agent.agent_card_id,
          agent_mobile: agent.agent_mobile,
          agent_birth_text: this.convertCCtoBB(moment(agent.agent_birth).format("YYYY-MM-DD")),
          agent_id: agent.agent_id
        })
      }
    } else {
      if(this.props.actionType === 'บันทึก' && prevProps.actionType !==  this.props.actionType) {
        this.setState({
          agent_fullname: '',
          agent_address: '',
          agent_card_id: '',
          agent_mobile: '',
          agent_birth_text: '',
          agent_id: null,
        })
      }
    }
  }

  convertCCtoBB(cc_date) { // "1972-10-31"
    let date_arr=cc_date.split('-');
    let dd = date_arr[2];
    let mm = date_arr[1];
    let yyyy = Number(date_arr[0])+543;

    if(moment(cc_date, 'YYYY-MM-DD', true).isValid()){
      //alert(cc_date);
      return dd+'-'+mm+'-'+yyyy ;
    }
    return moment() ;
  }

  handleOnChange(e) {
    e.preventDefault()
    const name = e.target.name
    const value = e.target.value
    // console.log(name, value)
    this.setState({
      [name]: value
    })
  }

  handleOnSubmitAgent(e) {
    e.preventDefault()
    const actionType = this.props.actionType
    const agent_fullname = e.target.agent_fullname.value
    // const agent_birth_text = e.target.agent_birth_text.value
    const agent_birth = this.state.startDateAgentNew1
    const agent_address = e.target.agent_address.value
    const agent_birth_text = e.target.agent_birth_text.value
    const agent_card_id = e.target.agent_card_id.value
    const agent_mobile = e.target.agent_mobile.value

    /*console.log('actionType:', actionType)
    console.log('agent_fullname:', agent_fullname)
    console.log('agent_birth:', agent_birth)
    console.log('agent_address:', agent_address)
    console.log('agent_card_id:', agent_card_id)
    console.log('agent_mobile:', agent_mobile)*/

    if(agent_fullname === '') {
      AlertWarning('กรุณากรอกชื่อ-นามสกุล');
      return
    }

    if(agent_birth_text === '') {
      AlertWarning('กรุณากรอกวันเกิด');
      return
    }

    if(agent_mobile === '') {
      AlertWarning('กรุณากรอกเบอร์โทรศัพท์');
      return
    }

    let fileStatus = '';
    if(actionType==='บันทึก') {
      try {
        // if(e.target.agent_file.value.length > 0){
        // console.log('file', e.target.agent_file.files[0].name)
        if (e.target.agent_file.files[0].name === undefined) {
          this.setState({
            w_agent_file: false
          });
          fileStatus = 'nofile'
        } else {
          var filename = e.target.agent_file.files[0].name;
          if (!(/\.(gif|jpg|jpeg|tiff|png)$/i).test(filename)) {
            AlertError('กรุณาเลือกไฟล์ภาพ jpg, jpeg, png')
            this.setState({
              w_agent_file: true
            });
            return
          }
          fileStatus = 'withfile'
        }
      } catch (ex) {
        // AlertError('กรุณาเลือกไฟล์ภาพนายหน้า')
        // this.setState({
        //   w_agent_file: true
        // });
        fileStatus = 'nofile'
        /// return
      }
    }

    // console.log('fileStatus=', fileStatus)
    // return
    // console.log('add or edit data')
    // console.log('user_created:', this.state.user.user)

    let user_created = this.state.user.user;

    const data = new FormData();
    data.append('agent_fullname', agent_fullname);
    data.append('agent_birth', agent_birth);
    data.append('agent_address', agent_address);
    data.append('agent_card_id', agent_card_id);
    data.append('agent_mobile', agent_mobile);
    data.append('user_created', user_created);

    if(actionType==='บันทึก') {
      if (fileStatus === 'withfile') {
        data.append('file', e.target.agent_file.files[0], e.target.agent_file.files[0].name);
        axios.post(APIURL+'/agent2/file', data)
          .then(res => {
            if(res.status === 200) {
              AlertSuccess('บันทึกข้อมูลเรียบร้อยแล้ว')
              this.props.toggleAgentModal('success')
            }
          })
      } else if (fileStatus === 'nofile') {
        let dataNoFile = {
          agent_fullname,
          agent_birth,
          agent_address,
          agent_card_id,
          agent_mobile,
          user_created
        }
        axios.post(APIURL+'/agent2/nofile', dataNoFile)
          .then(res => {
            if(res.status === 200) {
              AlertSuccess('บันทึกข้อมูลเรียบร้อยแล้ว')
              this.props.toggleAgentModal('success')
            }
          })
      }

    } else if (actionType==='แก้ไข') {
      // console.log('this.state.agent_id)', this.state.agent_id)
      // console.log('this.state.agent_id)', e.target.agent_file.files.length)

      const agent_id = this.state.agent_id
      // data.append('agent_id', this.state.agent_id);
      // data.append('user_update', this.state.user.user);

      if(e.target.agent_file.files.length === 0) {
        // edit nofile pass
        const dataNoFile = {
          agent_id: agent_id,
          agent_fullname: agent_fullname,
          agent_birth: agent_birth,
          agent_address: agent_address,
          agent_card_id: agent_card_id,
          agent_mobile: agent_mobile,
          user_update: this.state.user.user
        }
        axios.put(APIURL+'/agent2/edit/nofile/'+agent_id, dataNoFile)
          .then(res => {
            if(res.status === 200) {
              AlertSuccess('แก้ไขข้อมูลเรียบร้อยแล้ว')
              this.props.toggleAgentModal('success')
            }
          })
      }else{
        // change file agent_key
        const agentSelected = this.props.agentSelected
        const agent_key = agentSelected.agent_key
        data.append('agent_key', agent_key);
        data.append('file', e.target.agent_file.files[0], e.target.agent_file.files[0].name);
        axios.post(APIURL+'/agent2/edit/file/'+agent_id, data)
          .then(res => {
            if(res.status === 200) {
              AlertSuccess('แก้ไขข้อมูลเรียบร้อยแล้ว')
              this.props.toggleAgentModal('success')
            }
          })
      }

    }

  }

  handleDateAgent(date) {
    this.setState({
      startDateAgentNew1: moment(date).format('YYYY-MM-DD')
    });
  }

  onMaskedDateAgent (e){
    let buddhist_date = e.target.value ;
    //alert('date='+date);
    if(buddhist_date.length===10){
      let cc_date = this.convertBBtoCC(buddhist_date)
      this.handleDateAgent(cc_date)
    }
  }

  convertBBtoCC(buddhist_date){ //dd-mm-yyyy to yyyy-mm-dd
    let date_arr=buddhist_date.split('-');
    let dd = date_arr[0];
    let mm = date_arr[1];
    let yyyy = date_arr[2]-543;
    let cc_date= yyyy+'-'+mm+'-'+dd ;
    if(moment(cc_date, 'YYYY-MM-DD', true).isValid()){
      //alert(cc_date);
      return cc_date ;
    }
    return moment() ;
  }

  render () {
    let width = this.state.width

    return (
      <>
        <Modal isOpen={this.props.isAgentModal}
               toggle={this.props.toggleAgentModal}
               className={'modal-lg ' + this.props.className}
               style={width<=980 ?{maxWidth:800}:{maxWidth:1200}}
        >
          <ModalBody>
            <form action="" method="post" onSubmit={this.handleOnSubmitAgent}>
            <div className={this.props.actionType === 'แก้ไข' ? "card card-accent-warning" : "card card-accent-primary" }>

              <div className="card-header">
                <strong className="text-title">{this.props.actionType}ข้อมูลนายหน้า</strong>
              </div>

              <div className="card-block">
                <div className="row">

                  <div className="form-group col-sm-6">
                    <label htmlFor="agent_name">ชื่อ-นามสกุล*</label>
                    <input type="text"
                           className="form-control"
                           id="agent_fullname"
                           name="agent_fullname"
                           placeholder=""
                           onChange={this.handleOnChange}
                           maxLength={200}
                           value={this.state.agent_fullname}
                    />
                  </div>

                  <div className="form-group col-sm-6">
                    <label htmlFor="agent_birth_text">วันเดือนปีเกิด (พ.ศ.)*</label>
                    <MaskedInput
                      mask="11-11-1111"
                      name="agent_birth_text"
                      placeholder="dd-mm-yyyy"
                      onChange={this.onMaskedDateAgent}
                      className="form-control"
                      value={this.state.agent_birth_text}
                    />
                  </div>

                  <div className="form-group col-sm-12">
                    <label htmlFor="agent_address">ที่อยู่ปัจจุบัน</label>
                    <input type="text"
                           className="form-control"
                           id="agent_address"
                           name="agent_address"
                           placeholder=""
                           maxLength={250}
                           value={this.state.agent_address}
                           onChange={this.handleOnChange}
                    />
                  </div>

                  <div className="form-group col-sm-6">
                    <label htmlFor="agent_card_id">เลขบัตรประชาชน</label>
                    <input type="text"
                           className="form-control hidden"
                           id="agent_card_id_old-bk"
                           name="agent_card_id_old-bk"
                           // maxLength="13"
                           placeholder=""
                           // value={this.state.agent_card_id}
                           // onChange={this.handleOnChange}
                    />

                    <MaskedInput
                        mask="1-1111-11111-11-1"
                        className="form-control"
                        id="agent_card_id"
                        name="agent_card_id"
                        maxLength="20"
                        placeholder=""
                        value={this.state.agent_card_id}
                        onChange={this.handleOnChange}
                    />
                  </div>

                  <div className="form-group col-sm-6">
                    <label htmlFor="agent_mobile">เบอร์โทรศัพท์*</label>
                    <input type="text"
                           className="form-control hidden"
                           id="agent_mobile_old-bk"
                           name="agent_mobile_old-bk"
                           placeholder=""
                           // maxLength={20}
                           // value={this.state.agent_mobile}
                           // onChange={this.handleOnChange}
                    />

                    <MaskedInput
                        mask="111-111-1111"
                        className="form-control"
                        id="agent_mobile"
                        name="agent_mobile"
                        placeholder=""
                        maxLength={20}
                        value={this.state.agent_mobile}
                        onChange={this.handleOnChange}
                    />
                  </div>

                  <div className="form-group col-md-12">
                    <label htmlFor="agent_file">ภาพนายหน้า </label>
                    <input type="file"
                           id="agent_file"
                           name="agent_file"
                           accept="image/*"
                           className="form-control"
                           onChange={(e)=>{
                             const { target } = e
                             if(target.value.length > 0){
                               this.setState({
                                 w_agent_file: false
                               });
                             } else {
                               //target.reset();
                               this.setState({
                                 w_agent_file: true
                               });
                             }

                           }}
                    />
                    { this.state.w_agent_file ? <span  className="help-block text-danger">กรุณาเลือกไฟล์ ประเภท ( .jpg  .jpeg .png )</span>: null }

                  </div>

                </div>
              </div>

              <div className="card-footer text-right">

                <button type="button" onClick={() => this.props.toggleAgentModal(null)} className="btn btn-sm btn-danger mr-2">
                  <i className="fa fa-refresh"/> ยกเลิก</button>

                <button type="submit" className={this.props.actionType==="แก้ไข"? "btn btn-sm btn-warning" : "btn btn-sm btn-primary"}>
                  <i className={this.props.actionType === "แก้ไข" ? "fa fa-edit" : "fa fa-save"}/> {this.props.actionType}</button>

              </div>
            </div>
            </form>
          </ModalBody>
        </Modal>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(AgentFormModal)
