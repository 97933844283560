import React, { Component } from 'react'
import axios from 'axios'
import {connect} from 'react-redux';
// import MaskedInput  from 'react-maskedinput'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css';
import 'moment/locale/th';
import { APIURL } from '../../../config/config'
import NumberFormat from 'react-number-format';
moment.locale('th');

class ReceiptsForm extends Component {
    constructor (props) {
        super(props)
        this.state = {
            lists: [],
            load_data: true,
            danger: false,
            receipts:'',
            branch:[],
            expense_type:[],
            income_expense_item:[],
            receipts_amount:0,
            startDateReceipts: new Date() ,//moment().format('YYYY-MM-DD'),
            receipts_name:'',
            receipts_image:'',
            user: {},
            w_receipts_image_file: false,
        }
        this.handleDateReceipts = this.handleDateReceipts.bind(this)
        this.convertBBtoCC = this.convertBBtoCC.bind(this)
        // this.onMaskedDateReceipts = this.onMaskedDateReceipts.bind(this)
        this.handleReceiptsNameChange = this.handleReceiptsNameChange.bind(this)
        this.handleOnSubmitReceipts = this.handleOnSubmitReceipts.bind(this)
        this.handleIncomeExpenseItemChange = this.handleIncomeExpenseItemChange.bind(this)
    }

  componentDidMount() {
    this.loadData()
    this.loadBranchData()
    this.loadExpenseTypeData()
    this.loadIncomeExpenseItemData()
  }

  componentWillMount () {
    this.setState({
      user: JSON.parse(this.props.user)
    });
  }

    handleOnSubmitReceipts(event) {
        event.preventDefault()
        let receipts_date = moment(this.state.startDateReceipts).format('YYYY-MM-DD')
        let branch_id = event.target.branch_id.value
        let income_expense_item_id = event.target.income_expense_item_id.value
        let receipts_name = event.target.receipts_name.value
        let receipts_amount = event.target.receipts_amount.value
        //let expense_image_file = event.target.expense_image_file.value
        var expense_type_id = 3 //3 = รายรับของเต็นท์รถ

       /* try {
            if(event.target.receipts_image_file.files[0].name === undefined){
                this.setState({
                    w_receipts_image_file: false
                });
            }
        }catch (ex){
            this.setState({
                w_receipts_image_file: true
            });
            return;
        } */

        if(receipts_amount===''){
            alert('กรุณากรอกจำนวนเงิน')
            this.refs.receipts_amount.focus()
            return ;
        }

        if(income_expense_item_id===''){
            alert('กรุณาเลือกหมวดหมู่ค่าใช้จ่าย')
            this.refs.income_expense_item_id.focus()
            return ;
        }

        if(branch_id===''){
            alert('กรุณาเลือกสาขา')
            this.refs.branch_id.focus()
            return ;
        }

      if(event.target.receipts_image_file.files.length === 0) { // nofile
        var data = {
          receipts_date: receipts_date,
          branch_id: branch_id,
          receipts_amount: receipts_amount,
          income_expense_item_id: income_expense_item_id,
          receipts_name: receipts_name,
          expense_type_id: expense_type_id,
          user_created: this.state.user.user,
          user_updated: this.state.user.user,
          receipts_image_file: 'nophoto.png'
        }
        //console.log('---รายได้ NoFile')
        //console.log(data)

        axios.post(APIURL + '/receipts/nofile/', data)
          .then(res =>{
             this.props.onToggle();
          })
          .catch(error => {
            console.log(error)
            //alert("error"+error)
          })

      } else {
        var filename = event.target.receipts_image_file.files[0].name;
        //alert (filename)
        //return;
        if (!(/\.(gif|jpg|jpeg|tiff|png)$/i).test(filename)) {
          //alert(event.target.car_repair_file.files[0].type);
          this.setState({
            w_receipts_image_file: true
          });
          return;
        }

        const data = new FormData();
        data.append('file', event.target.receipts_image_file.files[0], event.target.receipts_image_file.files[0].name);
        data.append('receipts_date', receipts_date);
        data.append('branch_id', branch_id);
        data.append('income_expense_item_id', income_expense_item_id);
        data.append('receipts_name', receipts_name);
        data.append('receipts_amount', receipts_amount);
        data.append('expense_type_id', expense_type_id);
        data.append('user_created', this.state.user.user);
        data.append('user_updated', this.state.user.user);

        // console.log(data)

        axios.post(APIURL + '/receipts/', data)
          .then(res => {
            // console.log(res)
            if (res.status === 200)
              this.props.onToggle();
          })
          .catch(error => {
            console.log(error)
          })
      }
    }

    handleDateReceipts(date) {
        this.setState({
            startDateReceipts: new Date(date)
            // startDateReceipts: moment(date).format('YYYY-MM-DD')
        });
    }

    /*onMaskedDateReceipts(e){
        let buddhist_date = e.target.value ;
        if(buddhist_date.length===10){
            let cc_date = this.convertBBtoCC(buddhist_date)
            this.handleDateReceipts(cc_date)
        }
    }*/

    convertBBtoCC(buddhist_date) { //dd-mm-yyyy to yyyy-mm-dd
        let date_arr=buddhist_date.split('-');
        let dd = date_arr[0];
        let mm = date_arr[1];
        let yyyy = date_arr[2]-543;
        let cc_date= yyyy+'-'+mm+'-'+dd ;
        if(moment(cc_date, 'YYYY-MM-DD', true).isValid()){
            //alert(cc_date);
            return cc_date ;
        }
        return moment() ;
    }

    handleIncomeExpenseItemChange (event) {
        var income_expense_item_name = event.target.options[event.target.selectedIndex].text
        this.setState({
            receipts_name: income_expense_item_name,
        })
    }

    handleReceiptsNameChange(){
        var receipts_name = this.refs.receipts_name.value
        this.setState({
            receipts_name : receipts_name
        });
    }

    loadData () {
        axios.get(APIURL + '/receipts/')
            .then(res => {
                //alert("res"+res);
                this.setState({lists: res.data, })
            }).catch(error => {
            //alert("error"+error.message);
            this.setState({lists: []})
        })
    }

    loadBranchData () {
        axios.get(APIURL + '/branch/')
            .then(res => {
                this.setState({branch: res.data})
                //console.log(res.data)
                //alert (res.data)
            }).catch(error => {
            this.setState({branch: []})
        })
    }

    loadExpenseTypeData () {
        axios.get(APIURL + '/expense_type/')
            .then(res => {
                this.setState({expense_type: res.data})
            }).catch(error => {
            this.setState({expense_type: []})
        })
    }

    loadIncomeExpenseItemData () {
        axios.get(APIURL + '/income_expense_item/get/income')
            .then(res => {
                if( res.status === 200)
                 this.setState({income_expense_item: res.data})
            }).catch(error => {
            this.setState({income_expense_item: []})
        })
    }

    render(){
        //const { history,user } = this.props
      let branch_list = this.state.branch.map((branch,index)=>{
            return(
                <option key={index} value={branch.branch_id}>{branch.branch_name}</option>
            )
        })

        let income_expense_item_list = this.state.income_expense_item.map((income_expense_item,index)=>{
            return(
                <option key={index} value={income_expense_item.income_expense_item_id}>{income_expense_item.income_expense_item_name}</option>
            )
        })

        return (
            <div className="card card-accent-primary">
                <form action="" method="post" onSubmit={this.handleOnSubmitReceipts.bind(this)}>
                    <div className="card-header">
                        <strong className="text-title">เพิ่มข้อมูลรายรับ</strong>
                    </div>

                    <div className="card-block">
                        <div className="row">

                            <div className="form-group col-sm-6">
                                <label htmlFor="receipts_date_text">วันที่รับเงิน</label>
                                {/*<MaskedInput
                                    mask="11-11-1111"
                                    name="receipts_date_text"
                                    placeholder="dd-mm-yyyy"
                                    onChange={this.onMaskedDateReceipts}
                                    className="form-control"
                                />*/}
                                <br/>
                                <DatePicker
                                    type="text"
                                    className="form-control"
                                    name="expense_date_text"
                                    placeholder=""
                                    selected={this.state.startDateReceipts}
                                    onChange={this.handleDateReceipts}
                                    dateFormat="yyyy-MM-dd"
                                />

                            </div>

                            <div className="form-group col-sm-6">
                                <label htmlFor="branch">สาขา</label>
                                <select className="form-control"
                                        id="branch_id"
                                        ref="branch_id"
                                >
                                    {branch_list}
                                </select>
                            </div>


                            <div className="form-group col-sm-6">
                                <label htmlFor="income_expense_item">หมวดรายรับ</label>
                                <select
                                    className="form-control"
                                    id="income_expense_item_id"
                                    ref="income_expense_item_id"
                                    onChange={this.handleIncomeExpenseItemChange}
                                >
                                    {income_expense_item_list}
                                </select>
                            </div>

                            <div className="form-group col-sm-6">
                                <label htmlFor="receipts_name">รายละเอียดรายรับ (พิมพ์เพิ่มเติมได้)</label>
                                <input type="text"
                                       className="form-control"
                                       id="receipts_name"
                                       ref="receipts_name"
                                       value={this.state.receipts_name}
                                       placeholder=""
                                       onChange={this.handleReceiptsNameChange}
                                />
                            </div>

                            <div className="form-group col-sm-12">
                                <label htmlFor="receipts_amount">ยอดรายรับ</label>
                                <input
                                    type="text"
                                    className="form-control hidden"
                                    name="receipts_amount"
                                    placeholder=""
                                    value={this.state.receipts_amount}
                                    onChange={() => {}}
                                />
                                <NumberFormat
                                    className="form-control"
                                    thousandSeparator={true}
                                    prefix={'฿'}
                                    placeholder="ค่าใช้จ่าย"
                                    min={0}
                                    max={1000000000}
                                    step={1000}
                                    size={10}
                                    allowNegative={false}
                                    onValueChange = {(values) => {
                                        const {value} = values;
                                        this.setState({receipts_amount: value})
                                    }}
                                />
                            </div>

                            <div className="form-group col-md-12">
                                <label htmlFor="receipts_image_file">เอกสารแนบ </label>
                                <input type="file"
                                       id="receipts_image_file"
                                       name="receipts_image_file"
                                       accept="image/*"
                                       className="form-control"
                                       onChange={(e)=>{
                                           const { target } = e
                                           if(target.value.length > 0){
                                               this.setState({
                                                   w_receipts_image_file: false
                                               });
                                           } else {
                                               //target.reset();
                                               this.setState({
                                                   w_receipts_image_file: true
                                               });
                                           }

                                       }}
                                />
                                { this.state.w_receipts_image_file ? <span  className="help-block text-danger">กรุณาเลือกเอกสารแนบ</span>: null }

                            </div>

                        </div>
                    </div>

                    <div className="card-footer text-right">
                        <button type="reset"
                                onClick={this.props.onToggle}
                                className="btn btn-sm btn-danger mr-2">
                            <i className="fa fa-refresh"/> ยกเลิก
                        </button>
                        <button type="submit"
                                className="btn btn-sm btn-primary">
                            <i className="fa fa-save"/> บันทึก
                        </button>
                        {/*<input type="hidden" id="car_id" name="car_id" value={this.state.car.car_id}/>*/}
                    </div>

                </form>
            </div>
        )

    }
}

/*const styles = {
    bottomLine: {
        borderBottomColor: '#ccc',
        borderBottomStyle: 'solid',
        borderWidth: 0.5,
    }
}*/

const mapStateToProps = (state) => ({
    user: state.auth.user,
    token: state.auth.token,
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(ReceiptsForm);
