/**
 * Created by devmaster on 7/14/2017 AD.
 */
import { createReducer } from '../utils';
import createConstants from '../constants';

const initialState = {
  isAuthenticated: false,
  user: null,
  type: null,
  token: null,
  logo_image: null,
  statusText:''
};

export default createReducer (initialState, {
  [createConstants.LOGIN_USER_SUCCESS]: (state, payload) => {

    return Object.assign({}, state, {
      isAuthenticated: true,
      user:  payload.user,
      type:  payload.type,
      token: payload.token,
      logo_image: payload.logo_image,
      statusText:'successfully logged in'
    });
  },
  [createConstants.LOGIN_USER_FAILURE]: (state, payload) => {
    return Object.assign({}, state, {
      isAuthenticated: false,
      user: null,
      type: null,
      token: null,
      logo_image: null,
      statusText: 'Authentication Error'
    });
  },
  [createConstants.LOGOUT_USER]: (state, payload) => {
    return Object.assign({}, state, {
      isAuthenticated: false,
      user: null,
      type: null,
      token: null,
      logo_image: null,
      statusText: 'successfully logged out.'
    });
  }
})
