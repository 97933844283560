import React, { Component } from 'react'
import Loading from '../Loading'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import axios from 'axios'
import { APIURL, PORT } from '../../config/config'
import { connect } from 'react-redux'
import { save } from 'save-file';


class SellReport extends Component {

  constructor (props) {
    super(props);
    this.state = {
      isLoading: false,
      date_start: new Date(),
      date_end: new Date(),
      sum_price_normal: 0,
      sum_price: 0,
      sum_profit: 0,
      sum_car_cost: 0,
      sum_car_cost_commissions: 0,
      sum_car_cost_repair: 0,
      sum_car_cost_other: 0,
      sum_car_cost_tax: 0,
      sum_car_cost_act: 0,
      //
      sum_car_external_commission: 0,
      sum_sales_commission: 0,
      sum_additional_commission: 0,
      sum_advertising_cost: 0,
      sum_delivery_fee: 0,
      sum_promotion_fee: 0,
      //
      sum_finance_fee: 0,
      sum_transfer_fee: 0,
      //
      sum_vat_sell: 0,
      sum_finance_commission: 0,
      lists: [],
    }
    this.handleDateStart = this.handleDateStart.bind(this)
    this.handleDateEnd = this.handleDateEnd.bind(this)
    this.loadSellData = this.loadSellData.bind(this)
  }

  handleDateStart(date){
    this.setState({
      date_start: date
    })
  }

  handleDateEnd(date){
    this.setState({
      date_end: date
    })
  }

  loadSellData() {
    // const {date_start, date_end} = this.state

    if(this.state.date_start > this.state.date_end){
      alert('วันที่เริ่มต้น มากกว่า วันที่สิ้นสุด กรุณาตรวจสอบ')
      return
    }

    let start_date =  moment(this.state.date_start).format('YYYY-MM-DD')
    let end_date   =  moment(this.state.date_end).format('YYYY-MM-DD')

    // const data = {
    //   start_date,
    //   end_date
    // }
    this.setState({
      isLoading: true
    })

    axios.get(APIURL + '/report/sell/'+start_date+'/'+end_date)
      .then(res => {
        let sum_price_normal = 0
        let sum_price = 0
        let sum_car_cost = 0
        let sum_car_cost_repair = 0
        let sum_car_cost_other = 0
        let sum_car_cost_other2 = 0
        let sum_profit = 0
        let sum_car_cost_tax = 0
        let sum_car_cost_act = 0
        //
        let sum_vat_sell = 0
        let sum_finance_commission = 0
        let sum_car_external_commission = 0
        let sum_sales_commission = 0
        let sum_additional_commission = 0
        let sum_advertising_cost = 0
        let sum_delivery_fee = 0
        let sum_promotion_fee = 0
        let sum_finance_fee = 0
        let sum_transfer_fee = 0
        //
        let sum_car_cost_commissions = 0

        if(res.data.length > 0) {
          sum_price_normal =  res.data.reduce((acc, next) => acc + next.normal_sell_price, 0)
          sum_price =  res.data.reduce((acc, next) => acc + next.real_sell_price, 0)
          sum_car_cost =  res.data.reduce((acc, next) => acc + next.car_cost, 0)
          //
          sum_car_cost_commissions = res.data.reduce((acc, next) => acc + next.car_cost_commissions, 0)
          sum_car_cost_repair =  res.data.reduce((acc, next) => acc + next.car_cost_repair, 0)
          sum_car_cost_tax = res.data.reduce((acc, next) => acc + next.car_cost_tax, 0)
          sum_car_cost_act = res.data.reduce((acc, next) => acc + next.car_cost_act, 0)
          sum_car_cost_other =  res.data.reduce((acc, next) => acc + next.car_cost_expenses_other, 0)
          sum_car_cost_other2 =  res.data.reduce((acc, next) => acc + next.car_cost_other, 0)
          sum_car_cost_other = sum_car_cost_other+sum_car_cost_other2

          // รายรับการขาย
          sum_vat_sell = res.data.reduce((acc, next) => acc + next.vat_sell, 0)
          sum_finance_commission = res.data.reduce((acc, next) => acc + next.finance_commission, 0)
          // รายจ่ายการขาย
          sum_car_external_commission = res.data.reduce((acc, next) => acc + next.car_external_commission, 0)
          sum_sales_commission = res.data.reduce((acc, next) => acc + next.sales_commission, 0)
          sum_additional_commission = res.data.reduce((acc, next) => acc + next.additional_commission, 0)
          sum_advertising_cost = res.data.reduce((acc, next) => acc + next.advertising_cost, 0)
          sum_delivery_fee = res.data.reduce((acc, next) => acc + next.delivery_fee, 0)
          sum_promotion_fee = res.data.reduce((acc, next) => acc + next.promotion_fee, 0)
          // รายจ่ายการขาย 2
          sum_finance_fee = res.data.reduce((acc, next) => acc + next.finance_fee, 0) // ค่าธรรมเนียมไฟแนนท์
          sum_transfer_fee = res.data.reduce((acc, next) => acc + next.transfer_fee, 0) // ค่าโอนรถ

          sum_profit = sum_price - (sum_car_cost + sum_car_cost_commissions + sum_car_cost_repair + sum_car_cost_other+sum_car_cost_tax+sum_car_cost_act) + (sum_vat_sell + sum_finance_commission) - (sum_car_external_commission + sum_sales_commission + sum_additional_commission + sum_advertising_cost + sum_delivery_fee + sum_promotion_fee + sum_finance_fee + sum_transfer_fee)

        }

        this.setState({
          lists: res.data,
          sum_price_normal: sum_price_normal,
          sum_price: sum_price,
          sum_car_cost: sum_car_cost,
          sum_car_cost_commissions: sum_car_cost_commissions,
          sum_car_cost_repair: sum_car_cost_repair,
          sum_car_cost_other: sum_car_cost_other,
          sum_profit: sum_profit,
          sum_car_cost_tax: sum_car_cost_tax,
          sum_car_cost_act: sum_car_cost_act,
          //
          sum_car_external_commission: sum_car_external_commission,
          sum_sales_commission: sum_sales_commission,
          sum_additional_commission: sum_additional_commission,
          sum_advertising_cost: sum_advertising_cost,
          sum_delivery_fee: sum_delivery_fee,
          sum_promotion_fee: sum_promotion_fee,
          sum_finance_fee: sum_finance_fee,
          sum_transfer_fee: sum_transfer_fee,
          //
          sum_vat_sell: sum_vat_sell,
          sum_finance_commission: sum_finance_commission,
          isLoading: false
        })
      }).catch(error => {
        console.log('error:', error)
       // this.setState({lists: []})
    })

  }

  exportSellData() {

    if(this.state.date_start > this.state.date_end){
      alert('วันที่เริ่มต้น มากกว่า วันที่สิ้นสุด กรุณาตรวจสอบ')
      return
    }

    let start_date =  moment(this.state.date_start).format('YYYY-MM-DD')
    let end_date   =  moment(this.state.date_end).format('YYYY-MM-DD')

    this.setState({
      isLoading: false
    })

    axios.get(APIURL + '/report/sellexport/'+start_date+'/'+end_date)
      .then( async res => {
        const excelBuffer = res.data.excelBuffer
        const currentDatetime = res.data.currentDatetime
        const fileName = 'รายงานสรุปยอดขาย_export_'+currentDatetime+'.xlsx'
        this.setState({
          isLoading: false
        })
        await save(excelBuffer, fileName)

      }).catch(error => {
      console.log('error:', error)
      // this.setState({lists: []})
    })

  }

  render () {
    const {
      lists,
      sum_price_normal,
      sum_price,
      sum_car_cost,
      sum_car_cost_commissions,
      sum_car_cost_repair,
      sum_car_cost_other,
      sum_profit,
      sum_car_cost_tax,
      sum_car_cost_act,
      //
      sum_car_external_commission,
      sum_sales_commission,
      sum_additional_commission,
      sum_advertising_cost,
      sum_delivery_fee,
      sum_promotion_fee,
      sum_finance_fee,
      sum_transfer_fee,
      //
      sum_vat_sell,
      sum_finance_commission
    } = this.state

    const colIndex = PORT === 9005 ? 4 : 5;
    const colIndex2 = PORT === 9005 ? 7 : 9;
    const emptyBody = <tr><td colSpan={12} className="text-center text-danger" > ไม่พบข้อมูล </td></tr>

    const listRows = lists.map((car, index) => {
      const car_sell_date = moment(car.car_sell_date).locale('th').format('LL')
      const car_cost = car.car_cost
      const car_cost_commissions = car.car_cost_commissions // คอมฯซื้อเข้า
      const car_cost_repair = car.car_cost_repair
      // ค่าใช้จ่าย พ.ร.บ./ค่าภาษีรถยนต์
      const car_cost_tax = car.car_cost_tax
      // ค่าภาษีรถยนต์
      const car_cost_act = car.car_cost_act
      const car_cost_other = car.car_cost_other
      const car_cost_expenses_other = car.car_cost_expenses_other+car_cost_other
      const profit_before_sell = car.real_sell_price - (car_cost + car_cost_commissions + car_cost_repair+car_cost_expenses_other+car_cost_tax+car_cost_act)
      const profit_after_sell = (car.vat_sell + car.finance_commission) - (car.car_external_commission + car.sales_commission + car.additional_commission + car.advertising_cost + car.delivery_fee + car.promotion_fee + car.transfer_fee)
      const gross_profit = profit_before_sell + profit_after_sell

      return (
        <tr key={index} style={index%2===0? { backgroundColor: ''} : { backgroundColor: '#e4e5e6'}}>
          <td className="text-center">{index+1}</td>
          <td style={{width: 200}}>{car.car_name} ({car.car_license_plate_new})</td>
          <td className="text-center">{car.car_brand_name}</td>
          <td style={{width: 110}}>{car_sell_date}</td>
          <td style={{width: 60}} className="text-center">{car.employee_name}</td>

          <td className={PORT === 9005 ? 'hidden' : 'text-right'} >{car.normal_sell_price === null ? '-' : car.normal_sell_price.toLocaleString()}</td>
          <td className="text-right">{car.real_sell_price === null ? '-' : car.real_sell_price.toLocaleString()}</td>

          <td className="text-right">{car.vat_sell === null ? '-' : car.vat_sell.toLocaleString()}</td>
          <td className="text-right">{car.finance_commission === null ? '-' : car.finance_commission.toLocaleString()}</td>

          <td className="text-right">{car.car_cost === null ? '-' : car.car_cost.toLocaleString()}</td>
          <td className={PORT === 9005 ? 'hidden' : 'text-right'} >{car.car_cost_commissions === null ? '-' : car.car_cost_commissions.toLocaleString()}</td>
          <td className="text-right">{car.car_cost_repair === null ? '-' : car.car_cost_repair.toLocaleString()}</td>
          <td className="text-right">{car.car_cost_tax === null ? '-' : car.car_cost_tax.toLocaleString()}</td>
          <td className="text-right">{car.car_cost_act === null ? '-' : car.car_cost_act.toLocaleString()}</td>
          <td className={PORT === 9005 ? 'hidden' : 'text-right'}>{car.car_cost_expenses_other === null ? '-' : car.car_cost_expenses_other.toLocaleString()}</td>

          <td className="text-right">{car.car_external_commission === null ? '-' : car.car_external_commission.toLocaleString()}</td>
          <td className="text-right">{car.sales_commission === null ? '-' : car.sales_commission.toLocaleString()}</td>
          <td className="text-right">{car.additional_commission === null ? '-' : car.additional_commission.toLocaleString()}</td>
          <td className="text-right">{car.advertising_cost === null ? '-' : car.advertising_cost.toLocaleString()}</td>
          <td className="text-right">{car.delivery_fee === null ? '-' : car.delivery_fee.toLocaleString()}</td>
          <td className="text-right">{car.promotion_fee === null ? '-' : car.promotion_fee.toLocaleString()}</td>
          <td className="text-right">{car.finance_fee === null ? '-' : car.finance_fee.toLocaleString()}</td>
          <td className="text-right">{car.transfer_fee === null ? '-' : car.transfer_fee.toLocaleString()}</td>

          <td className="text-right">{gross_profit.toLocaleString()}</td>
        </tr>
      )
    })

    const tableRows = lists.length === 0 ? emptyBody : listRows

    const tableSum = <tr>
                  <td className="text-right font-weight-bold" colSpan={5}>รวม</td>
                  <td className={PORT === 9005 ? 'hidden' : 'text-right font-weight-bold'} >{sum_price_normal.toLocaleString()}</td>
                  <td className="text-right font-weight-bold">{sum_price.toLocaleString()}</td>
                  <td className="text-right font-weight-bold">{sum_vat_sell.toLocaleString()}</td>
                  <td className="text-right font-weight-bold">{sum_finance_commission.toLocaleString()}</td>

                  <td className="text-right font-weight-bold">{sum_car_cost.toLocaleString()}</td>

                  <td  className={PORT === 9005 ? 'hidden' : 'text-right font-weight-bold'} >{sum_car_cost_commissions.toLocaleString()}</td>
                  <td className="text-right font-weight-bold">{sum_car_cost_repair.toLocaleString()}</td>
                  <td className="text-right font-weight-bold">{sum_car_cost_tax.toLocaleString()}</td>
                  <td className="text-right font-weight-bold">{sum_car_cost_act.toLocaleString()}</td>
                  <td  className={PORT === 9005 ? 'hidden' : 'text-right font-weight-bold'} >{sum_car_cost_other.toLocaleString()}</td>

                  <td className="text-right font-weight-bold">{sum_car_external_commission.toLocaleString()}</td>
                  <td className="text-right font-weight-bold">{sum_sales_commission.toLocaleString()}</td>
                  <td className="text-right font-weight-bold">{sum_additional_commission.toLocaleString()}</td>
                  <td className="text-right font-weight-bold">{sum_advertising_cost.toLocaleString()}</td>
                  <td className="text-right font-weight-bold">{sum_delivery_fee.toLocaleString()}</td>
                  <td className="text-right font-weight-bold">{sum_promotion_fee.toLocaleString()}</td>
                  <td className="text-right font-weight-bold">{sum_finance_fee.toLocaleString()}</td>
                  <td className="text-right font-weight-bold">{sum_transfer_fee.toLocaleString()}</td>

                  <td className="text-right font-weight-bold">{sum_profit.toLocaleString()}</td>
                </tr>

    return (
      <div>
        <Loading isLoading={this.state.isLoading} />

        <div className="card-block" style={styles.overflowY}>
          <div className="row">

              <div className="card card-accent-primary">
                <div className="card-header">
                  <strong className="text-title">รายงานการขาย</strong>
                </div>
                <div className="card-block">

                  <div className="row mt-1">
                    <div className="col-1 mt-1 text-right">
                      จาก
                    </div>

                    <div className="col-2">
                      <DatePicker
                        selected={this.state.date_start}
                        onChange={this.handleDateStart}
                        dateFormat="yyyy-MM-dd"
                        type="text"
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                    <div className="mt-1 text-right">
                      ถึง
                    </div>
                    <div className="col-2 ml-3">
                      <DatePicker
                        selected={this.state.date_end}
                        onChange={this.handleDateEnd}
                        dateFormat="yyyy-MM-dd"
                        type="text"
                        className="form-control d-block"
                        placeholder=""
                      />
                    </div>

                    <div className="d-flex mr-3">
                      <button type="button" className="btn btn-sm btn-primary" onClick={() => this.loadSellData()} > ตกลง </button>
                    </div>

                    <div className="d-flex">
                      <button type="button" className="btn btn-sm btn-success" onClick={() => this.exportSellData()} > Excel </button>
                    </div>

                    <div className="col-5 text-right pt-2">
                      {/*<h5>
                        รวมยอดขาย&nbsp;
                        <NumberFormat
                          displayType={'text'}
                          thousandSeparator={true}
                          value={this.state.sum_expense}/>
                        &nbsp;บาท
                      </h5>*/}
                    </div>
                  </div>

                  <div className="row mt-5">
                    <div className="col-12">
                      <table className="" border={1} style={{border: 1}} >
                        <thead>
                        <tr>
                          <th rowSpan={2} style={styles.headerTable} >ลำดับ</th>
                          <th rowSpan={2} style={styles.headerTable} >ชื่อรถ</th>
                          <th rowSpan={2} style={styles.headerTable} >ยี่ห้อ</th>
                          <th rowSpan={2} style={styles.headerTable} >วันที่ขาย</th>
                          <th rowSpan={2} style={styles.headerTable} >ขายโดย</th>
                          <th rowSpan={2} className={PORT === 9005 ? 'hidden' : ''} style={styles.headerTable} >ราคาขาย(0)</th>

                          <th colSpan={3} className="text-center" style={styles.rowReceive}  >รายรับ</th>
                          <th colSpan={PORT === 9005 ? 12 : 14} className="text-center" style={styles.rowExpense} >รายจ่าย</th>

                          <th rowSpan={2} style={styles.rowProfit} >กำไรเบื้องต้น({colIndex2 + 9})</th>

                        </tr>
                        <tr>

                          <th style={styles.rowReceive}  >ราคาขายจริง(1)</th>
                          <th style={styles.rowReceive}  >vat ยอดจัดได้คืน(2)</th>
                          <th style={styles.rowReceive}  >ค่าคอมมิชชั่นไฟแนนท์(3)</th>

                          <th style={styles.rowExpense} >ซื้อรถเข้า(4)</th>
                          <th className={PORT === 9005 ? 'hidden' : ''} style={styles.rowExpense} >ค่าคอมฯซื้อเข้า({colIndex})</th>
                          <th style={styles.rowExpense} >ค่าซ่อม({colIndex + 1})</th>
                          <th style={styles.rowExpense} >ค่าใช้จ่าย พ.ร.บ.({colIndex + 2})</th>
                          <th style={styles.rowExpense} >ค่าภาษีรถยนต์({colIndex + 3})</th>
                          <th className={PORT === 9005 ? 'hidden' : ''} style={styles.rowExpense} >ค่าใช้จ่ายรถอื่นๆ({colIndex2})</th>
                          <th style={styles.rowExpense} >ค่านายหน้า({colIndex2 + 1})</th>
                          <th style={styles.rowExpense} >ค่าคอมมิชชั่นฝ่ายขาย({colIndex2 + 2})</th>
                          <th style={styles.rowExpense} >ค่าคอมมิชชั่นเพิ่มเติม({colIndex2 + 3})</th>
                          <th style={styles.rowExpense} >ค่าโฆษณา({colIndex2 + 4})</th>
                          <th style={styles.rowExpense} >ค่าบริการส่งรถ({colIndex2 + 5})</th>
                          <th style={styles.rowExpense}>ค่าโปรโมชั่น({colIndex2 + 6})</th>
                          <th style={styles.rowExpense}>ค่าธรรมเนียมไฟแนนท์({colIndex2 + 7})</th>
                          <th style={styles.rowExpense}>ค่าโอนรถ({colIndex2 + 8})</th>

                        </tr>
                        </thead>
                        <tbody>
                         {tableRows}
                         {tableSum}
                        </tbody>
                      </table>
                    </div>
                  </div>

                </div>
              </div>

          </div>
        </div>

      </div>
    )
  }
}

// eeede8
const styles = {
  headerTable: {
    backgroundColor: '#eeede8',
    verticalAlign: 'middle',
    textAlign: 'center',
    fontSize: '12px',
    fontWeight:'semi-bold'
  },
  rowReceive: {
    backgroundColor: '#bfcad0',
    textAlign: 'center',
    fontSize: '12px',
    fontWeight:'semi-bold'
  },
  rowExpense: {
    backgroundColor: '#ede4e3',
    textAlign: 'center',
    fontSize: '12px',
    fontWeight:'semi-bold'
  },
  rowProfit: {
    backgroundColor: '#95E06C',
    textAlign: 'center',
    fontSize: '12px',
    fontWeight:'semi-bold'
  },
  overflowY: {
    overflow: 'scroll',
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

// export default SellReport;
export default connect(mapStateToProps)(SellReport);
