/**
 * Created by Tukta on 15/7/2560.
 */
import React, { Component } from 'react'
import axios from 'axios'
import { APIURL } from '../../../config/config'
import { connect } from 'react-redux'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import 'react-datepicker/dist/react-datepicker.css'
import NumberFormat from 'react-number-format'
import { LineNotify } from '../../../config/lineNotify'
import { NumberUtil } from '../../../utils/number-util'

class DebtorsPay extends Component {

  constructor (props) {
    super(props)
    this.state = {
      lists: [],
      load_data: true,
      danger: false,
      branch: [],
      customer: {},
      debtors_list: [],
      customer_list: [],
      customer_select: false,
      customer_id: '',
      debtors_id: 0,
      debtors: '',
      car_id: '',
      car: '',
      date_pay: new Date(),
      duration_period: [],
      count_period: [],
      amount_pay: 0,
      amount_pay_period: 0,
      amount_pay_before: 0,
      note: '',
      user: {}
    }
    this.handleDatePay = this.handleDatePay.bind(this)
  }

  componentWillMount () {
    this.setState({
      user: JSON.parse(this.props.user)
    })
  }

  handleDatePay (date) {
    this.setState({
      date_pay: date
    })
  }

  formatDate (date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [year, month, day].join('-')
  }

  componentDidMount () {
    // const  debtors_detail_id = this.props.debtors_detail.debtors_detail_id
    const  amount_pay_before = this.props.debtors_detail.amount_pay

    this.setState({
      amount_pay_before: amount_pay_before
    })
  }

  handleOnSubmitDebtorsPay (event) {
    event.preventDefault()

    //let that = this
    const debtors_detail = this.props.debtors_detail

    const debtors_name = debtors_detail.debtors_name
    const debtors_detail_id = debtors_detail.debtors_detail_id
    const debtors_id = debtors_detail.debtors_id
    const debtors_detail_order = debtors_detail.debtors_detail_order
    const date_dept = debtors_detail.date_dept

    const amount_pay = this.state.amount_pay // ยอดชำระ
    const note = this.state.note
    const date_pay = moment(this.state.date_pay).format('YYYY-MM-DD')
    // this.props.debtors_detail.amount_dept - this.state.amount_pay_before
    const amount_dept = this.props.debtors_detail.amount_dept - this.state.amount_pay_before
    const amount_pay_before = this.state.amount_pay_before

    const fullname = debtors_detail.customer_name + ' ' + debtors_detail.customer_lastname
    const amount_of_debt = debtors_detail.amount_of_debt // หนี้ทั้งหมด
    const date_pay_message = moment(this.state.date_pay).format('DD/MM/YYYY')
    // ยอดชำระ ： 8,000 บาท
    const remain_message = Number(amount_of_debt) - Number(amount_pay)
    const fullname_user = debtors_detail.fullname // ผุ้ทำรายการ

    if (amount_pay === 0) {
      alert('กรุณากรอกข้อมูลการชำระเงิน')
      return;
    }

    let message = 'ชำระค่างวด : '+debtors_name + '\n' +
      'ลูกค้า : ' + fullname +'\n' +
      'ยอดหนี้ ： '+NumberUtil.addCommas(amount_of_debt)+' บาท\n' +
      'ยอดชำระ ： '+NumberUtil.addCommas(amount_pay)+' บาท\n' +
      'วันที่ชำระ ： '+date_pay_message+'\n' +
      'ค้างชำระ ： '+NumberUtil.addCommas(remain_message)+' บาท\n' +
      'ผู้บันทึกรายการ ： '+fullname_user ;

    // LineNotify.sendNotify(message)
    // return

    //  debtors_id , debtors_detail_order, amount_dept = 0, date_dept , date_pay, amount_pay
    const data = {
      debtors_id: debtors_id,
      debtors_detail_order: debtors_detail_order,
      date_dept: date_dept,
      amount_dept: amount_dept,
      amount_pay: amount_pay,
      date_pay: date_pay,
      note: note,
      amount_pay_before: amount_pay_before,
      username: this.state.user.user,
    }

    axios.put(APIURL + '/debtors_detail/pay/'+debtors_detail_id, data)
      .then(res => {
        if(res.status === 200) {
          LineNotify.sendNotify(message)
          this.props.onLoadDebtorsDetailById()
          this.props.onToggle()
        }
      })
      .catch(function (error) {
        console.log(error)
        alert('error' + error)
      })
  }

  render () {
    // const debtors_detail = this.props.debtors_detail

    return (
      <div className="card card-accent-primary">
        <form action="" method="post" onSubmit={this.handleOnSubmitDebtorsPay.bind(this)}>
          <div className="card-header">
            <strong className="text-title">ข้อมูลการชำระเงิน</strong>
          </div>

          <div className="card-block">
            <div className="row">
              <div className="card-block">

                <div className="row">
                  <div className="form-group col-md-12">
                    <span className="h6">
                      {this.props.debtors_detail.debtors_name} <br/>
                      วันที่ต้องชำระ: {this.props.debtors_detail.date_dept}
                      &nbsp;&nbsp;&nbsp;จำนวนเงินที่ต้องชำระ:
                      &nbsp;&nbsp; {this.props.debtors_detail.amount_dept - this.state.amount_pay_before}
                    </span>
                  </div>

                </div>

                <div className="row">

                  <div className="form-group col-md-6">
                    <label htmlFor="amount_of_debt">จำนวนเงินที่ชำระ</label>
                    <input type="text"
                           className="form-control hidden"
                           id="amount_pay"
                           ref="amount_pay"
                           placeholder=""
                           value={this.state.amount_pay}
                           onChange={()=>{}}
                    />
                    <NumberFormat
                      className="form-control"
                      thousandSeparator={true}
                      prefix={'฿'}
                      //placeholder="กรอกค่าโอน"
                      min={0}
                      max={1000000000}
                      step={1000}
                      size={10}
                      allowNegative={false}
                      onValueChange={(values) => {
                        const {value} = values
                        this.setState({amount_pay: value})
                      }}
                    />
                  </div>

                  <div className="form-group col-md-6">
                    <label htmlFor="date_pay_start">วันชำระ</label>
                    <br/>
                    <DatePicker
                      selected={this.state.date_pay}
                      onChange={this.handleDatePay}
                      dateFormat="YYYY-MM-dd"
                      type="text"
                      className="form-control"
                      id="date_pay"
                      name="date_pay"
                    />
                  </div>

                </div>

                <div className="row">
                  <div className="form-group col-sm-12">
                    <label htmlFor="profile_name">หมายเหตุ</label>
                    <textarea
                      name="profile_name"
                      className="form-control"
                      value={this.state.note}
                      onChange={(e) => {
                        this.setState({
                          note: e.target.value
                        })
                      }}
                      />
                  </div>
                </div>

              </div>
            </div>

          </div>
          <div className="card-footer text-right">
            <button type="reset"
                    onClick={this.props.onToggle}
                    className="btn btn-sm btn-danger mr-2">
              <i className="fa fa-refresh"/> ยกเลิก
            </button>
            <button type="submit"
                    className="btn btn-sm btn-primary"
            >
              <i className="fa fa-save"/> บันทึก
            </button>

            <input type="hidden" id="ปปป" name="ปปป"/>

          </div>

        </form>
      </div>
    )
  }
}

/*const styles = {
  bottomLine: {
    borderBottomColor: '#ccc',
    borderBottomStyle: 'solid',
    borderWidth: 0.5,
  }
}*/

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(DebtorsPay)