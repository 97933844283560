import React, { Component } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { connect } from 'react-redux';
import classnames from 'classnames';
import {sparePartTab} from '../../actions/index';
import SparePartList from './SparePartList';
import SparePartStockIn from './SparePartStockIn';
import SparePartStockOut from './SparePartStockOut';
import SummaryStockOut from './SummaryStockOut';
import HistoryStockOut from './HistoryStockOut';
import RetailerList from './RetailerList';


class SparePart extends Component{
  constructor(props) {
    super(props)
    this.state = {
      user: {},
      activeTab: '',
      reloadSparePart: false,
      reloadSummaryStockOut: false
    }

    this.toggleTab = this.toggleTab.bind(this);
    this.toggleTabReload = this.toggleTabReload.bind(this);
    this.reloadSparePartFinish = this.reloadSparePartFinish.bind(this);
    this.reloadSummary = this.reloadSummary.bind(this);
    this.reloadSummaryStockOutFinish = this.reloadSummaryStockOutFinish.bind(this);
    this.onReloadSparePart = this.onReloadSparePart.bind(this);
  }

  componentWillMount () {
    this.setState({
      user: JSON.parse(this.props.user)
    })
  }

  componentDidMount() {
    // console.log('activeTab='+this.props.activeTab)
    this.setState({
      activeTab: this.props.activeTab
    })
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      },()=>{
        this.props.onSparePartTab(tab)
      });
    }
  }

  toggleTabReload(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
        reloadSparePart: true
      },()=>{
        this.props.onSparePartTab(tab)
      });
    }
  }

  reloadSummary() {
    this.setState({
      reloadSummaryStockOut: true
    })
  }

  reloadSparePartFinish() {
    this.setState({
      reloadSparePart: false
    })
  }

  onReloadSparePart() {
    this.setState({
      reloadSparePart: true
    })
  }

  //
  reloadSummaryStockOutFinish() {
    this.setState({
      reloadSummaryStockOut: false
    })
  }

  render() {
    return (
      <div className="col-md-12 mb-4">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '1' })}
              onClick={() => { this.toggleTab('1'); }}
            >
              <i className="icon-list"/> รายการอะไหล่
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '2' })}
              onClick={() => { this.toggleTab('2'); }}
            >
              <i className="icon-plus"/> เพิ่มอะไหล่เข้าสต๊อก
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '3' })}
              onClick={() => { this.toggleTab('3'); }}
            >
              <i className="icon-minus"/> เบิกอะไหล่
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '6' })}
              onClick={() => { this.toggleTab('6'); }}
            >
              <i className="icon-book-open"/> ประวัติการเบิกอะไหล่(ลบ)
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '4' })}
              onClick={() => { this.toggleTab('4'); }}
            >
              <i className="icon-book-open"/> สรุปการเบิกอะไหล่
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '5' })}
              onClick={() => { this.toggleTab('5'); }}
            >
              <i className="icon-home"/> ข้อมูลร้านค้า
            </NavLink>
          </NavItem>

        </Nav>

        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <SparePartList
              user={this.state.user}
              reloadSparePart={this.state.reloadSparePart}
              onReloadSparePartFinish={this.reloadSparePartFinish}
            />

            <HistoryStockOut
              user={this.state.user}
              viewType="range"
              reloadSummaryStockOut={this.state.reloadSummaryStockOut}
              onReloadSummaryStockOutFinish={this.reloadSummaryStockOutFinish}
            />

          </TabPane>
          <TabPane tabId="2">
            <SparePartStockIn  user={this.state.user} onToggleTab={this.toggleTabReload} />
          </TabPane>

          <TabPane tabId="3">
            <SparePartStockOut
              user={this.state.user}
              onToggleTab={this.toggleTabReload}
              onReloadSummary={this.reloadSummary}
            />
          </TabPane>

          <TabPane tabId="6">
            <HistoryStockOut
              user={this.state.user}
              viewType="range"
              reloadSummaryStockOut={this.state.reloadSummaryStockOut}
              onReloadSummaryStockOutFinish={this.reloadSummaryStockOutFinish}
            />
          </TabPane>

          <TabPane tabId="4">
            <SummaryStockOut
              user={this.state.user} viewType="range"
              reloadSummaryStockOut={this.state.reloadSummaryStockOut}
              onReloadSummaryStockOutFinish={this.reloadSummaryStockOutFinish}
            />
          </TabPane>

          <TabPane tabId="5">

            <RetailerList
              onReloadSparePart={this.onReloadSparePart}
            />

          </TabPane>

        </TabContent>

      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated,
  activeTab: state.sparepart.activeTab
})

const mapDispatchToProps = (dispatch) => ({
  onSparePartTab(activeTab) {
    dispatch(sparePartTab(activeTab))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(SparePart);