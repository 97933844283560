/**
 * Created by devmaster on 7/15/2017 AD.
 */
import React, { Component } from 'react'


 class BankForm extends Component{

  constructor (props){
     super(props)
     this.state = {
        bank_name: '',
        bank_name_full: '',
        bank_address_line1: '',
        bank_address_line2: '',
        bank_tax_id: '',
        bank: this.props.bank
     }

     this.onChangeBank = this.onChangeBank.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if(JSON.stringify(nextProps.bank) !== JSON.stringify(this.props.bank)){
      this.setState({ bank: nextProps.bank })
    }
  }

   /*onChangeBank(event){
     this.setState({ bank :{
         bank_name : event.target.value
       }})
   }*/

     onChangeBank(event) {
         let xname = event.target.name
         if(xname === "bank_name") {
             this.setState ({bank:{
                     ...this.state.bank,
                     bank_name: event.target.value
                 }})
         }else if(xname === "bank_name_full") {
             this.setState ({bank:{
                     ...this.state.bank,
                     bank_name_full: event.target.value
                 }})
         }else if(xname === "bank_address_line1") {
             this.setState ({bank:{
                     ...this.state.bank,
                     bank_address_line1 : event.target.value
                 }})
         }else if(xname === "bank_address_line2") {
             this.setState ({bank:{
                     ...this.state.bank,
                     bank_address_line2 : event.target.value
                 }})
         }else if(xname === "bank_tax_id") {
             this.setState ({bank:{
                     ...this.state.bank,
                     bank_tax_id : event.target.value
                 }})
         }
     }

  render(){

     return (
       <div className="card card-accent-success">
         <div className="card-header card-customer">
           <strong className="text-title">เพิ่มธนาคาร</strong>
         </div>
           <form action="" method="post" onSubmit={ (this.props.actionType==="แก้ไข")? this.props.onBankSubmit.update :this.props.onBankSubmit.add  }>
         <div className="card-block">

             <div className="form-group">
               <label htmlFor="bank_name">ชื่อธนาคาร</label>
               <input
                      value={this.state.bank.bank_name || ''}
                      type="text"
                      className="form-control"
                      name="bank_name"
                      placeholder="กรอกชื่อธนาคาร"
                      style={styles.inputSearch}
                      onChange={this.onChangeBank}
               />

             </div>

             <div className="form-group">
                 <label htmlFor="bank_name_full">ชื่อเต็มธนาคาร (สำหรับออกใบกำกับภาษีให้ธนาคาร)</label>
                 <input
                     value={this.state.bank.bank_name_full || ''}
                     type="text"
                     className="form-control"
                     name="bank_name_full"
                     placeholder="กรอกชื่อเต็มธนาคาร"
                     style={styles.inputSearch}
                     onChange={this.onChangeBank}
                 />

             </div>

             <div className="form-group">
                 <label htmlFor="bank_address_line1">ที่อยู่ธนาคาร บรรทัด 1</label>
                 <input
                     value={this.state.bank.bank_address_line1 || ''}
                     type="text"
                     className="form-control"
                     name="bank_address_line1"
                     placeholder="กรอกที่อยู่ธนาคาร"
                     style={styles.inputSearch}
                     onChange={this.onChangeBank}
                 />
             </div>

             <div className="form-group">
                 <label htmlFor="bank_address_line2">ที่อยู่ธนาคาร บรรทัด 2</label>
                 <input
                     value={this.state.bank.bank_address_line2 || ''}
                     type="text"
                     className="form-control"
                     name="bank_address_line2"
                     placeholder="กรอกที่อยู่ธนาคาร"
                     style={styles.inputSearch}
                     onChange={this.onChangeBank}
                 />

             </div>

             <div className="form-group">
                 <label htmlFor="bank_tax_id">เลขผู้เสียภาษี</label>
                 <input
                     value={this.state.bank.bank_tax_id || ''}
                     type="text"
                     className="form-control"
                     name="bank_tax_id"
                     placeholder="กรอกเลขผู้เสียภาษี"
                     style={styles.inputSearch}
                     onChange={this.onChangeBank}
                 />

             </div>


         </div>
         <div className="card-footer text-right">
               <button type="reset"
                       className="btn btn-sm btn-danger mr-2"
                       style={styles.button}
                        onClick={()=>{
                            this.setState({
                                bank:{}
                            })
                        }}
               >
                   <i className="fa fa-refresh"/> ยกเลิก</button>
               <button type="submit"
                       className={this.props.actionType==="แก้ไข"?  "btn btn-sm btn-warning" : "btn btn-sm btn-primary"}
                       style={styles.button}>
                 <i className={this.props.actionType === "แก้ไข" ? "fa fa-edit" : "fa fa-save"}/> {this.props.actionType}</button>
           </div>
           </form>
       </div>
     );
  }
}

const styles = {
    button: {
        borderRadius:5,
        height: 36,
    },
    inputSearch: {
        borderRadius:5,
    },
}
/*
BankForm.propTypes = {
    onUserSubmit: React.PropTypes.func.isRequired,

};*/
export  default BankForm;