
export const CarUtil = {
  convertDepositRegistration(deposit_registration_id){
    switch (deposit_registration_id) {
      case 1:
        return 'มัดจำเล่ม';
      case 2:
        return 'ไม่มัดจำ';
      default:
        return '-'
    }
  },
  convertLicenseIdToName(liciense_status_id) {
    switch (liciense_status_id) {
      case 0:
        return '--ไม่ระบุ--';
      case 1:
        return 'มีเล่มทะเบียน';
      case 2:
        return 'ยังไม่ปิดบัญชี';
      case 3:
        return 'รอเล่มปิดบัญชี';
      case 4:
        return 'มีเล่มอยู่เต็นท์';
      case 5:
        return 'ไม่มีเล่ม';
      default:
        return '-'
    }
  },
  convertVatPersonToName(person_vat_id) {
    switch (person_vat_id) {
      case 0:
        return '-';
      case 1:
        return 'บุคคล';
      case 2:
        return 'vat';
    }
  }
}
