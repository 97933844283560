import React, { Component } from 'react'
import NumberFormat from 'react-number-format'
import PreviewImage from '../../../views/PreviewImage/';
import {Modal, ModalBody} from "reactstrap";
const port = window.env.PORT;

export  default class CarCalculate extends Component {
  constructor (props) {
    super(props)
    //this.handleOnSubmitCustomer.bind(this);
    this.state = {
      lists: [],
      load_data: true,
      danger: false,
      car_id: 0,
      action: 'บันทึก',
      car: this.props.car,
      real_sell_price: 0,
      down_payment: 0,
      interest: 0,
      period: 0,
      car_net_profit: 0,
      car_sale_price: 0,
      percent_down: 0,
      yord_jud: 0,
      yord_jud_interest: 0,
      month_payment: 0,
      month_payment_vat: 0,
      installment_balance_zero:0,
      preview_image_url: '',
      preview_image_select: false,
      interest_topup : 0,
      interest_new : 0,

      total_out_car : 0,
      insurance : 0,
      insurance_floor: ''
    }

    this.togglePreviewImage = this.togglePreviewImage.bind(this)
    this.onPreviewSelected = this.onPreviewSelected.bind(this)
    this.setInsurancePrice = this.setInsurancePrice.bind(this)
  }

  setInsurancePrice(e){
    let value = e.target.value
    let insurance = 0
    // console.log(value, typeof value)
    if(value === '1'){
      insurance = 18000
    }else if(value === '2'){
      insurance = 9600
    }else if(value === '2+'){
      insurance = 8000
    }else {
      insurance = 0
    }

    this.setState({
      insurance_floor: value,
      insurance : insurance
    })
  }


  componentDidMount () {
    this.setState({car_sale_price: this.state.car.car_sale_price})
  }

  onPreviewSelected(url){
    this.setState({
      preview_image_url: url,
      preview_image_select: true
    });
  }

  togglePreviewImage() {
    this.setState({
      preview_image_select: !this.state.preview_image_select
    });
  }

  handleOnChangeCarCalculate () {

    //var real_sell_price = this.state.real_sell_price
    let down_payment = this.state.down_payment
    let interest = this.state.interest
    let period = this.state.period
    let car_sale_price = this.state.car_sale_price
    let installment_balance_zero = 0

    // รวมออกรถ
    let total_out_car = 0

    let interest_topup = 0

    if (down_payment === '')
      down_payment = 0

    if (interest === '') {
      interest = 0
    } else {
      // cal interest topup //9017 poomrodsuay
      if (Number(port) === 9017){
        if (car_sale_price !== ''){
          let car_price = Number(car_sale_price)
          if (car_price >= 400000) {
            interest_topup = 0.4
          }else if (car_price >= 300000){
            interest_topup = 0.8
          }else if (car_price >= 200000){
            interest_topup = 1
          }
        }
      }
    }

    if (Number(port) === 9024){
      // samkor
      let insurance = this.state.insurance
      let transfer_fee = this.state.transfer_fee
      total_out_car = Number(insurance)+Number(transfer_fee)+Number(down_payment)
     /* if (insurance !== '' && transfer_fee){

      }*/
    }


    // console.log(interest_topup)

    interest = Number(interest) + interest_topup
    interest = parseFloat(interest).toFixed(2)

    // console.log(interest)

    this.setState({
      interest_topup,
      interest_new : interest,
      total_out_car : total_out_car
    })

    if (period === '')
      period = 0

    if (car_sale_price !== '' && down_payment !== '') {
      var percent_down = (100.0 * down_payment) / car_sale_price
      this.setState({percent_down: percent_down})

      var yord_jud = car_sale_price - down_payment

      if (interest !== '') {

        this.setState({
          yord_jud: yord_jud,
        })

        if (period !== '') {
          var yord_jud_interest = yord_jud + ((yord_jud * interest / 100) * (period) / 12)
          var month_payment = yord_jud_interest / period

          // console.log('month_payment='+month_payment)
          installment_balance_zero = (yord_jud / period) * 1.07

          if(month_payment!==Infinity){
            this.setState({
              month_payment: month_payment,
              month_payment_vat: (month_payment*1.07),
              yord_jud_interest: yord_jud_interest,
              installment_balance_zero: installment_balance_zero,
            })
          }
        }

      }

    }

  }

  render () {

    return (

      <div className="card card-accent-info">
        <form action="" method="post">
          <div className="card-header">
            <strong className="text-title">คำนวณค่างวดสินเชื่อรถยนต์</strong>
          </div>

          {Number(port) === 9024 ?
              <div className="card-block">
                <div className="row pl-3">
                  <div className="row p-1 col-sm-12">
                    <h5>{this.state.car.car_name}</h5>
                  </div>
                </div>
                <div className="row">
                  <div className="row p-3">

                    <div className="form-group col-sm-6">
                      <strong>ราคารถยนต์ :</strong>
                    </div>
                    <div className="form-group col-sm-3">
                      <input type="text"
                             ref="real_sell_price"
                             value={this.state.car_sale_price}
                             className="hidden"
                             onChange={()=>{}}
                      />
                      <NumberFormat
                          className="form-control"
                          thousandSeparator={true}
                          prefix={'฿'}
                          //placeholder="กรอกค่าโอน"
                          min={0}
                          max={1000000000}
                          step={1000}
                          size={10}
                          allowNegative={false}
                          value={this.state.car_sale_price}
                          onValueChange={(values) => {
                            const {value} = values
                            this.setState({car_sale_price: value}, () => {
                              this.handleOnChangeCarCalculate()
                            })

                          }}
                      />
                    </div>

                    <div className="form-group col-sm-6">
                      <strong>เงินดาวน์ :</strong>
                    </div>
                    <div className="form-group col-sm-3">
                      <input type="text"
                             ref="down_payment"
                             value={this.state.down_payment}
                             className="hidden"
                             onChange={()=>{}}
                      />
                      <NumberFormat
                          className="form-control"
                          thousandSeparator={true}
                          prefix={'฿'}
                          //placeholder="กรอกค่าโอน"
                          min={0}
                          max={1000000000}
                          step={1000}
                          size={10}
                          allowNegative={false}
                          onValueChange={(values) => {
                            const {value} = values
                            this.setState({down_payment: value}, () => {
                              this.handleOnChangeCarCalculate()
                            })

                          }}
                      />
                    </div>

                    <div className="form-group col-sm-6">
                      <strong>จัดไฟแนนซ์ :</strong>
                    </div>
                    <div className="form-group col-sm-3">
                      <NumberFormat
                          className="form-control"
                          thousandSeparator={true}
                          prefix={'฿'}
                          decimalScale={2}
                          min={0}
                          max={1000000000}
                          step={1000}
                          size={10}
                          allowNegative={false}
                          value={this.state.yord_jud}
                      />
                    </div>

                    <div className="form-group col-sm-4">
                      <strong>ประกันรถยนต์ :</strong>
                    </div>
                    <div className="form-group col-sm-1">
                      <strong>ชั้น :</strong>
                    </div>
                    <div className="form-group col-sm-1">
                      <input type="text"
                             value={this.state.insurance_floor}
                             className="form-control"
                             onChange={this.setInsurancePrice}
                      />
                    </div>
                    <div className="form-group col-sm-3">
                      {/*<input type="text"
                             ref="insurance"
                             // value={this.state.insurance}
                             className="hidden"
                             onChange={()=>{}}
                      />*/}
                      <NumberFormat
                          className="form-control"
                          thousandSeparator={true}
                          prefix={'฿'}
                          //placeholder="กรอกค่าโอน"
                          min={0}
                          max={1000000000}
                          step={1000}
                          size={10}
                          allowNegative={false}
                          value={this.state.insurance}
                          onValueChange={(values) => {
                            const {value} = values
                            console.log(value)
                            this.setState({insurance: value}, () => {
                              this.handleOnChangeCarCalculate()
                            })

                          }}
                      />
                    </div>

                    <div className="form-group col-sm-6">
                      <strong>ค่าจัดโอน / ย้าย :</strong>
                    </div>
                    <div className="form-group col-sm-3">
                      {/*<input type="text"
                             ref="transfer_fee"
                             // value={this.state.transfer_fee}
                             className="hidden"
                             onChange={()=>{}}
                      />*/}
                      <NumberFormat
                          className="form-control"
                          thousandSeparator={true}
                          prefix={'฿'}
                          //placeholder="กรอกค่าโอน"
                          min={0}
                          max={1000000000}
                          step={1000}
                          size={10}
                          allowNegative={false}
                          value={this.state.transfer_fee}
                          onValueChange={(values) => {
                            const {value} = values
                            this.setState({transfer_fee: value}, () => {
                              this.handleOnChangeCarCalculate()
                            })

                          }}
                      />
                    </div>

                    <div className="form-group col-sm-6 text-success mt-0">
                      <strong>รวมออกรถ :</strong>
                    </div>
                    {/*ดาวน์+ประกัน+ค่าจัด*/}
                    <div className="form-group col-sm-3">
                      <NumberFormat
                          className="form-control text-success font-weight-bold"
                          thousandSeparator={true}
                          displayType={'input'}
                          prefix={'฿'}
                          decimalScale={2}
                          min={0}
                          max={1000000000}
                          step={1000}
                          size={10}
                          allowNegative={false}
                          value={this.state.total_out_car}
                      />
                    </div>


                    <div className="form-group col-sm-6">
                      <strong>ดอกเบี้ย :</strong>
                    </div>

                    <div className="form-group col-sm-3">
                      <input type="text"
                             ref="interest"
                             value={this.state.interest}
                             className="hidden"
                             onChange={()=>{}}
                      />
                      <NumberFormat
                          className="form-control"
                          thousandSeparator={true}
                          suffix={' %'}
                          min={0}
                          max={1000000000}
                          step={1000}
                          size={10}
                          allowNegative={false}
                          onValueChange={(values) => {
                            const {value} = values
                            this.setState({interest: value}, () => {
                              this.handleOnChangeCarCalculate()
                            })
                          }}
                      />
                    </div>

                    <div className="form-group col-sm-3 justify-content-center mt-1">
                      {
                        port === '9017' ?
                            <div className={(this.state.interest === '' || this.state.interest === 0) ? "hidden" : "" }>
                              {'+ '} {this.state.interest_topup} {' % '}  =  {this.state.interest_new} {' %'}
                            </div> : null
                      }
                    </div>

                    <div className="form-group col-sm-6">
                      <strong>ระยะเวลาผ่อนชำระ :</strong>
                    </div>
                    <div className="form-group col-sm-3">
                      <input type="text"
                             ref="period"
                             value={this.state.period}
                             className="hidden"
                             onChange={()=>{}}
                      />
                      <NumberFormat
                          className="form-control"
                          thousandSeparator={true}
                          suffix={' งวด'}
                          //placeholder="กรอกค่าโอน"
                          min={0}
                          max={200}
                          step={1}
                          size={2}
                          allowNegative={false}
                          onValueChange={(values) => {
                            const {value} = values
                            this.setState({period: value}, () => {
                              this.handleOnChangeCarCalculate()
                            })
                          }}
                      />
                    </div>

                    <div className="col-md-12 text-center">
                      <hr />
                    </div>

                    <div className="form-group col-sm-6 text-primary mt-0">
                      <strong>ค่างวด :</strong>
                    </div>
                    <div className="form-group col-sm-3">
                      <NumberFormat
                          className="form-control text-primary font-weight-bold"
                          thousandSeparator={true}
                          prefix={'฿'}
                          decimalScale={2}
                          min={0}
                          max={1000000000}
                          step={1000}
                          size={10}
                          allowNegative={false}
                          value={this.state.month_payment_vat}
                      />
                    </div>

                    <div className="form-group col-sm-6">
                      <strong>* ค่างวดรวมภาษีมูลค่าเพิ่ม</strong>
                    </div>

                    <div className="col-md-12 text-center">
                      <hr />
                    </div>


                      {/*<div className="form-group col-sm-6">
                        <strong>ยอดผ่อน 0% (รวม VAT 7%) :</strong>
                      </div>

                      <div className="form-group col-sm-3 ml-3">
                        <NumberFormat
                            className="form-control"
                            thousandSeparator={true}
                            prefix={'฿'}
                            decimalScale={2}
                            min={0}
                            max={1000000000}
                            step={1000}
                            size={10}
                            allowNegative={false}
                            value={this.state.installment_balance_zero}
                        />
                      </div>*/}
                  </div>
                </div>

                <div className={port === '9017' ? "row mt-0" : "hidden" }>
                  <div className="form-group col-12">
                    <button type="button" className="col-3 btn btn-warning mr-2"
                            onClick={()=>this.onPreviewSelected('https://bosscar.s3.ap-southeast-1.amazonaws.com/poomrodsuay/interest_zero.jpg')}>
                      ดอกเบี้ย 0%
                    </button>

                    <button type="button" className="col-3 btn btn-success mr-2"
                            onClick={()=>this.onPreviewSelected('https://bosscar.s3.ap-southeast-1.amazonaws.com/poomrodsuay/interest_normal.jpg')}>
                      ดอกเบี้ยปกติ
                    </button>

                    <button type="button" className="col-4 btn btn-primary"
                            onClick={()=>this.onPreviewSelected('https://bosscar.s3.ap-southeast-1.amazonaws.com/poomrodsuay/interest_customer_good.jpg')}>
                      ดอกเบี้ยลูกค้าประวัติดี
                    </button>

                  </div>
                </div>

                <Modal isOpen={this.state.preview_image_select} toggle={this.togglePreviewImage} className={'modal-lg '}>
                  <ModalBody>
                    <PreviewImage
                        imageUrl={this.state.preview_image_url}
                    />
                  </ModalBody>
                </Modal>

              </div>

              :

              <div className="card-block">
                <div className="row pl-3">
                  <div className="row p-1 col-sm-12">
                    <h5>{this.state.car.car_name}</h5>
                  </div>
                </div>
                <div className="row">
                  <div className="row p-3">
                    <div className="form-group col-sm-6">
                      <strong>ราคารถยนต์ :</strong>
                    </div>
                    <div className="form-group col-sm-3">
                      <input type="text"
                             ref="real_sell_price"
                             value={this.state.car_sale_price}
                             className="hidden"
                             onChange={()=>{}}
                      />
                      <NumberFormat
                          className="form-control"
                          thousandSeparator={true}
                          prefix={'฿'}
                          //placeholder="กรอกค่าโอน"
                          min={0}
                          max={1000000000}
                          step={1000}
                          size={10}
                          allowNegative={false}
                          value={this.state.car_sale_price}
                          onValueChange={(values) => {
                            const {value} = values
                            this.setState({car_sale_price: value}, () => {
                              this.handleOnChangeCarCalculate()
                            })

                          }}
                      />
                    </div>

                    <div className="form-group col-sm-6">
                      <strong>เงินดาวน์ :</strong>
                    </div>
                    <div className="form-group col-sm-3">
                      <input type="text"
                             ref="down_payment"
                             value={this.state.down_payment}
                             className="hidden"
                             onChange={()=>{}}
                      />
                      <NumberFormat
                          className="form-control"
                          thousandSeparator={true}
                          prefix={'฿'}
                          //placeholder="กรอกค่าโอน"
                          min={0}
                          max={1000000000}
                          step={1000}
                          size={10}
                          allowNegative={false}
                          onValueChange={(values) => {
                            const {value} = values
                            this.setState({down_payment: value}, () => {
                              this.handleOnChangeCarCalculate()
                            })

                          }}
                      />

                    </div>

                    <div className="form-group col-sm-6">
                      <strong>ดอกเบี้ย :</strong>
                    </div>

                    <div className="form-group col-sm-3">
                      <input type="text"
                             ref="interest"
                             value={this.state.interest}
                             className="hidden"
                             onChange={()=>{}}
                      />
                      <NumberFormat
                          className="form-control"
                          thousandSeparator={true}
                          suffix={' %'}
                          min={0}
                          max={1000000000}
                          step={1000}
                          size={10}
                          allowNegative={false}
                          onValueChange={(values) => {
                            const {value} = values
                            this.setState({interest: value}, () => {
                              this.handleOnChangeCarCalculate()
                            })
                          }}
                      />


                    </div>

                    <div className="form-group col-sm-3 justify-content-center mt-1">
                      {
                        port === '9017' ?
                            <div className={(this.state.interest === '' || this.state.interest === 0) ? "hidden" : "" }>
                              {'+ '} {this.state.interest_topup} {' % '}  =  {this.state.interest_new} {' %'}
                            </div> : null
                      }
                    </div>

                    <div className="form-group col-sm-6">
                      <strong>ระยะเวลาผ่อนชำระ :</strong>
                    </div>
                    <div className="form-group col-sm-3">
                      <input type="text"
                             ref="period"
                             value={this.state.period}
                             className="hidden"
                             onChange={()=>{}}
                      />
                      <NumberFormat
                          className="form-control"
                          thousandSeparator={true}
                          suffix={' งวด'}
                          //placeholder="กรอกค่าโอน"
                          min={0}
                          max={200}
                          step={1}
                          size={2}
                          allowNegative={false}
                          onValueChange={(values) => {
                            const {value} = values
                            this.setState({period: value}, () => {
                              this.handleOnChangeCarCalculate()
                            })
                          }}
                      />
                    </div>

                    <div className="col-md-12 text-center">
                      {/*<div className="card-block">
                   <button type="button" className="btn btn-success btn-sm">
                   <i className="icon-check"></i>  คำนวณ</button>
                   </div>*/}
                      <hr />
                    </div>

                    <div className="form-group col-sm-6">
                      <strong>% เงินดาวน์ :</strong>
                    </div>
                    <div className="form-group col-sm-3">
                      <NumberFormat
                          className="form-control"
                          thousandSeparator={true}
                          suffix={' %'}
                          decimalScale={2}
                          min={0}
                          max={1000000000}
                          step={1000}
                          size={10}
                          allowNegative={false}
                          value={this.state.percent_down}
                      />
                    </div>

                    <div className="form-group col-sm-6">
                      <strong>ยอดจัดสินเชื่อ :</strong>
                    </div>
                    <div className="form-group col-sm-3">
                      <NumberFormat
                          className="form-control"
                          thousandSeparator={true}
                          prefix={'฿'}
                          decimalScale={2}
                          min={0}
                          max={1000000000}
                          step={1000}
                          size={10}
                          allowNegative={false}
                          value={this.state.yord_jud}
                      />
                    </div>

                    <div className="form-group col-sm-6">
                      <strong>ยอดจัดสินเชื่อรวมดอกเบี้ย :</strong>
                    </div>
                    <div className="form-group col-sm-3">
                      <NumberFormat
                          className="form-control"
                          thousandSeparator={true}
                          prefix={'฿'}
                          decimalScale={2}
                          min={0}
                          max={1000000000}
                          step={1000}
                          size={10}
                          allowNegative={false}
                          value={this.state.yord_jud_interest}
                      />
                    </div>

                      <div className="form-group col-sm-6">
                        <strong>ค่างวดไม่รวมภาษี : </strong>
                      </div>
                      <div className="form-group col-sm-3">
                        <NumberFormat
                            className="form-control"
                            thousandSeparator={true}
                            prefix={'฿'}
                            decimalScale={2}
                            min={0}
                            max={1000000000}
                            step={1000}
                            size={10}
                            allowNegative={false}
                            value={this.state.month_payment}
                        />
                      </div>


                    <div className="form-group col-sm-6 text-primary mt-0">
                      <strong>ค่างวด + Vat 7% * :</strong>
                    </div>
                    <div className="form-group col-sm-3">
                      <NumberFormat
                          className="form-control"
                          thousandSeparator={true}
                          prefix={'฿'}
                          decimalScale={2}
                          min={0}
                          max={1000000000}
                          step={1000}
                          size={10}
                          allowNegative={false}
                          value={this.state.month_payment_vat}
                      />
                    </div>

                    <div className="form-group col-sm-6">
                      <strong>* ค่างวดรวมภาษีมูลค่าเพิ่ม</strong>
                    </div>

                    <div className="col-md-12 text-center">
                      <hr />
                    </div>

                      <div className="form-group col-sm-6">
                        <strong>ยอดผ่อน 0% (รวม VAT 7%) :</strong>
                      </div>

                      <div className="form-group col-sm-3">
                        <NumberFormat
                            className="form-control"
                            thousandSeparator={true}
                            prefix={'฿'}
                            decimalScale={2}
                            min={0}
                            max={1000000000}
                            step={1000}
                            size={10}
                            allowNegative={false}
                            value={this.state.installment_balance_zero}
                        />
                      </div>
                  </div>
                </div>

                <div className={port === '9017' ? "row mt-0" : "hidden" }>
                  <div className="form-group col-12">
                    <button type="button" className="col-3 btn btn-warning mr-2"
                            onClick={()=>this.onPreviewSelected('https://bosscar.s3.ap-southeast-1.amazonaws.com/poomrodsuay/interest_zero.jpg')}>
                      ดอกเบี้ย 0%
                    </button>

                    <button type="button" className="col-3 btn btn-success mr-2"
                            onClick={()=>this.onPreviewSelected('https://bosscar.s3.ap-southeast-1.amazonaws.com/poomrodsuay/interest_normal.jpg')}>
                      ดอกเบี้ยปกติ
                    </button>

                    <button type="button" className="col-4 btn btn-primary"
                            onClick={()=>this.onPreviewSelected('https://bosscar.s3.ap-southeast-1.amazonaws.com/poomrodsuay/interest_customer_good.jpg')}>
                      ดอกเบี้ยลูกค้าประวัติดี
                    </button>

                  </div>
                </div>

                <Modal isOpen={this.state.preview_image_select} toggle={this.togglePreviewImage} className={'modal-lg '}>
                  <ModalBody>
                    <PreviewImage
                        imageUrl={this.state.preview_image_url}
                    />
                  </ModalBody>
                </Modal>

              </div>
          }

        </form>
      </div>
    )
  }
}