import React, { Component } from 'react'
import NumberFormat from 'react-number-format'
import Autosuggest from 'react-autosuggest';
import axios from 'axios'
import { APIURL } from '../../config/config'
import Swal from 'sweetalert2'
import { AlertSuccess } from '../Alert/Alert'
import { Modal, ModalBody } from 'reactstrap'
import PreviewImage from '../../views/PreviewImage'

const suggestionHighlightedDivStyle = { backgroundColor: '#5bc0de' };
const suggestionDivStyle = { padding: '10px'};

const unitList = [
  { id: 'ชุด',    name: 'ชุด' },
  { id: 'ชิ้น',    name: 'ชิ้น' },
  { id: 'กล่อง',  name: 'กล่อง' },
  { id: 'โหล',   name: 'โหล' },
  { id: 'เครื่อง', name: 'เครื่อง' },
  { id: 'ถัง',    name: 'ถัง' },
  { id: 'ขวด',   name: 'ขวด' },
  { id: 'ลูก',    name: 'ลูก' },
  { id: 'คู่',     name: 'คู่' },
]

const getSuggestions = value => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  return inputLength === 0 ? [] : unitList.filter(lang =>
    lang.name.toLowerCase().slice(0, inputLength) === inputValue
  );
};

// When suggestion is clicked, Autosuggest needs to populate the input
const getSuggestionValue = suggestion => suggestion.name;

// Use your imagination to render suggestions.
const renderSuggestion = suggestion => (
  <span>
    {suggestion.name}
  </span>
);

class SparePartList extends Component {

  constructor (props){
    super(props)
    this.state = {
      actionType: 'บันทึก',
      suggestions: [],
      id: '',
      name: '',
      price: 0,
      quantity: 0,
      unit: '',
      sparePartList: [],
      note: '',
      s3key: '',
      preview_image_url: '',
      preview_image_select: false,
      retailers: [],
      retailer_id: 0,
    }
    this.onSparePartSubmit = this.onSparePartSubmit.bind(this)
    this.loadSparePart = this.loadSparePart.bind(this)
    this.onDeleteSparePart= this.onDeleteSparePart.bind(this)
    this.onPreUpdateSparePart= this.onPreUpdateSparePart.bind(this)
    this.resetFormState= this.resetFormState.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.resetState = this.resetState.bind(this)
    this.handleUploadFile = this.handleUploadFile.bind(this)
    this.onPreviewSelected = this.onPreviewSelected.bind(this)
    this.togglePreviewImage = this.togglePreviewImage.bind(this)
    this.loadRetailerData = this.loadRetailerData.bind(this)
  }

  resetState() {
    this.setState({
      actionType: 'บันทึก',
      id: '',
      name: '',
      price: 0,
      quantity: 0,
      unit: '',
      note: ''
    })
  }

  componentDidMount() {
    this.mounted = true
    this.loadSparePart();
    this.loadRetailerData();
  }

  componentWillUnmount() {
    this.mounted = false
  }

  loadRetailerData() {
    axios.get(APIURL + '/retailer/')
      .then(res => {
        this.setState({
          retailers: res.data
        })
      })
  }

  componentWillReceiveProps(nextProps){
      if(nextProps.reloadSparePart !== this.props.reloadSparePart){
        if(nextProps.reloadSparePart){
          this.loadSparePart();
          this.loadRetailerData();
        }
      }
  }

  handleChange(event) {
    let name  = event.target.name;
    let value = event.target.value;
    if(name === 'name'){
      this.setState({name: value})
    }else if(name === 'price'){
      this.setState({price: value})
    }else if(name === 'note'){
      this.setState({note: value})
    }
  }

  loadSparePart() {
    axios.get(APIURL+'/spare_part').then(res => {
      if(res.status === 200) {
        if(this.mounted) {
          this.setState({
            sparePartList: res.data
          }, () => {
            if (this.props.reloadSparePart) {
              this.props.onReloadSparePartFinish();
            }
          });
        }
      }
    })
  }

  // Autosuggest will call this function every time you need to update suggestions.
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: getSuggestions(value)
    });
  };
  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  onChange = (event, { newValue }) => {
    this.setState({
      unit: newValue
    });
  };

  onSparePartSubmit(e){
    e.preventDefault()
    let name = e.target.name.value
    let retailer_id = e.target.retailer_id.value
    let price = e.target.price.value
    let quantity = e.target.quantity.value
    let unit = e.target.unit.value
    let note = e.target.note.value
    let user = this.props.user.user


    if(name===''){
      alert('กรุณากรอกชื่ออะไหล่/วัสดุอุปกรณ์');
      return;
    }

    let data = {
      name: name,
      price: price,
      quantity: quantity,
      unit: unit,
      type: 'warehourse',
      user: user,
      note: note,
      retailer_id: retailer_id
    }

    // e.target.retailer_id.value = '0'
    // return

    e.target.name.value = ''
    e.target.price.value = ''
    e.target.quantity.value = ''
    e.target.unit.value = ''
    e.target.note.value = ''
    e.target.retailer_id.value = '0'

    this.setState({
      price: 0,
      quantity: 0,
      unit: '',
      note: '',
      s3key: '',
      retailer_id: 0
    })
    // add
    if(this.state.actionType === 'บันทึก'){
      axios.post(APIURL+'/spare_part', data)
        .then(res => {
          if(res.status === 200){
            AlertSuccess('บันทึกข้อมูลเรียบร้อยแล้ว');
            this.loadSparePart();
          }
        })
        .catch(err => {
          console.log('err: ', err)
        })
    }else if(this.state.actionType === 'แก้ไข'){
      //edit
        let id = e.target.id.value
        axios.put(APIURL+'/spare_part/'+id, data)
        .then(res => {
          if(res.status === 200){
            AlertSuccess('แก้ไขข้อมูลเรียบร้อยแล้ว');
            this.loadSparePart();
          }
        })
        .catch(err => {
          console.log('err: ', err)
        })
    }
  }

  onDeleteSparePart(sparePart){
    let id = sparePart.id
    axios.delete(APIURL+'/spare_part/'+id)
      .then(res => {
        if(res.data.delete){
          AlertSuccess('ลบเรียบร้อยแล้ว');
          this.resetFormState();
          this.loadSparePart();
        }else{
          alert(res.data.message);
        }
      })
  }

  onPreUpdateSparePart(sparePart) {
    // let id = sparePart.id
    this.setState({
      actionType: 'แก้ไข',
      id: sparePart.id,
      name: sparePart.name,
      price: sparePart.price,
      unit: sparePart.unit,
      note: sparePart.note,
      s3key: sparePart.s3key,
      retailer_id: sparePart.retailer_id === null ? 0 : sparePart.retailer_id
    }, () => {
      this.refs.spare_part_image.value = '';
    });
  }

  resetFormState(){
    this.setState({
      actionType: 'บันทึก',
      id: '',
      name: '',
      price: 0,
      quantity: 0,
      unit: '',
      s3key: ''
    });
  }

  onPreviewSelected(url){
    this.setState({
      preview_image_url: url,
      preview_image_select: true
    });
  }

  togglePreviewImage() {
    this.setState({
      preview_image_select: !this.state.preview_image_select
    });
  }

  handleUploadFile (event) {
    event.preventDefault();
    // let input_file_name = event.target.name
    let id = this.state.id
    let s3key = this.state.s3key
    let name = this.state.name

    if ( id === '') {
      return
    }

    let that = this

    const data = new FormData();
    data.append('uploads', event.target.files[0], event.target.files[0].name)
    data.append('id', id)
    data.append('s3key', s3key)

    Swal.fire({
      title: 'ต้องการเพิ่มภาพ/เปลี่ยนภาพ อะไหล่ '+name + ' ใช่หรือไม่? ',
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {

        axios.post(APIURL+'/spare_part/image', data)
          .then(function(response){
            if(response.status === 200) {
              AlertSuccess('เพิ่มภาพ/เปลี่ยนภาพเรียบร้อยแล้ว');
              // that.resetFormState();
              that.loadSparePart();
            }
          })
          .catch(function(error){
            console.log('error:' + error.response);
            // alert("error:"+error.response);
          });
      }
    })


  }

  render() {
    const inputProps = {
      placeholder: 'ชื่อหน่วย',
      value: this.state.unit ,
      onChange: this.onChange ,
      type: 'search',
    };

    let sparePartList = this.state.sparePartList.map((sparePart, index) => {
      return (
        <tr key={index+1}>
          <td>{index+1}</td>
          <td>
            ชื่อ: {sparePart.name}
            <br/>
            {
              sparePart.retailer_name === null ? '' : (
                <>
                  ร้าน: {sparePart.retailer_name}
                  <br/>
                </>
              )
            }

            {
              sparePart.s3key === '' ? null : (<>
                  <br/>
                  <img
                    src={sparePart.s3url}
                    style={{'cursor' : 'pointer', 'width': '48px', 'height': 'auto'}}
                    onClick={()=>this.onPreviewSelected(sparePart.s3url)}
                    alt="เอกสาร"
                  />
                </>
              )
            }
          </td>
          <td>
            <NumberFormat
              displayType={'text'}
              thousandSeparator={true}
              value={sparePart.price}/>
          </td>
          <td className="text-center">{sparePart.quantity_in}</td>
          <td>{sparePart.unit}</td>
          <td>{sparePart.note}</td>
          <td>

            <button
              className="btn btn-sm btn-warning btn-md-width "
              onClick={()=>this.onPreUpdateSparePart(sparePart)}
            >
              <i className="fa fa-edit"/> แก้ไข
            </button>

            <br/>

            <button
              className="btn btn-sm btn-danger btn-md-width mt-2"
              onClick={()=>this.onDeleteSparePart(sparePart)}
            >
              <i className="fa fa-remove"/> ลบ
            </button>


          </td>
        </tr>
      )
    })

    return (
      <div className="row">

        <Modal isOpen={this.state.preview_image_select} toggle={this.togglePreviewImage} className={'modal-lg '}>
          <ModalBody>
            <PreviewImage
              imageUrl={this.state.preview_image_url}
            />
          </ModalBody>
        </Modal>

        <div className="col-lg-8">
          <div className="card card-accent-warning">
            <div className="card-header card-sparepart">
              <strong className="text-title">รายการอะไหล่</strong>
            </div>
            <div className="card-block">
              <table className="table table-striped">
                <thead>
                <tr>
                  <td>#</td>
                  <td>ชื่ออะไหล่รถ</td>
                  <td>ราคา</td>
                  <td className="text-center">จำนวน</td>
                  <td>หน่วย</td>
                  <td>หมายเหตุ</td>
                  <td>&nbsp;</td>
                </tr>
                </thead>
                <tbody>
                {sparePartList}
                </tbody>
              </table>

            </div>
          </div>
        </div>

        <div className="col-lg-4">
          <div className={ this.state.actionType==="แก้ไข"? "card card-accent-warning" :"card card-accent-warning"}>
            <form action=""
                  method="post" onSubmit={this.onSparePartSubmit}
                  encType="multipart/form-data"
            >
              <div className="card-header card-sparepart flex-row justify-content-between">
                <strong className="text-title">{this.state.actionType==="แก้ไข"? "แก้ไข" : "เพิ่ม" }รายการอะไหล่</strong>
                &nbsp;&nbsp;
                {
                  this.state.actionType==="แก้ไข" ? (
                    <button
                      className="btn btn-sm btn-primary ml-3"
                      onClick={() => this.resetState()}
                    >
                      <i className="icon-refresh"/>
                    </button>
                  ) : null
                }

              </div>
              <div className="card-block">
                <div className="form-group">
                  <label htmlFor="name">ชื่ออะไหล่/วัสดุอุปกรณ์ </label>
                  <input name="name"
                         type="text"
                         className="form-control"
                         placeholder="ชื่ออะไหล่/วัสดุอุปกรณ์ "
                         value={this.state.name}
                         onChange={this.handleChange}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="name">ชื่อร้านค้า (เพิ่มร้านค้าที่แท็บข้อมูลร้านค้า) </label>
                  <select className="form-control"
                          id="retailer_id"
                          name="retailer_id"
                          value={this.state.retailer_id}
                          onChange={(e) => {
                            let val = e.target.value
                            this.setState({
                              retailer_id: val
                            })
                          }}
                  >
                    <option  value='0'>ไม่ระบุ</option>
                    {
                      this.state.retailers.map((retailer, index) => (
                        <option key={index} value={retailer.retailer_id}>{retailer.retailer_name}</option>
                      ))
                    }
                  </select>
                </div>

                <div className="form-group">
                  <label htmlFor="name">ราคาต่อหน่วย </label>
                  <input name="price"
                         type="text"
                         className="form-control hidden"
                         placeholder="ราคาต่อหน่วย "
                         value={this.state.price}
                         onChange={this.handleChange}
                  />
                  <NumberFormat
                    className="form-control"
                    thousandSeparator={true}
                    prefix={'฿'}
                    placeholder="ราคาต่อหน่วย"
                    min={0}
                    max={1000000000}
                    step={1000}
                    size={10}
                    allowNegative={false}
                    value={this.state.price}
                    onValueChange = {(values) => {
                      const {value} = values;
                      this.setState({price: value})
                    }}
                  />
                </div>
                <div className={this.state.actionType==='แก้ไข'? "hidden" : "form-group" }>
                  <label htmlFor="name">จำนวนเริ่มต้น </label>
                  <input name="quantity"
                         type="text"
                         className="form-control hidden"
                         placeholder="จำนวนเริ่มต้น "
                         value={this.state.quantity}
                         onChange={this.handleChange}
                  />
                  <NumberFormat
                    className="form-control"
                    thousandSeparator={true}
                    prefix={'฿'}
                    placeholder="จำนวนเริ่มต้น"
                    min={0}
                    max={1000000000}
                    step={1000}
                    size={10}
                    allowNegative={false}
                    value={this.state.quantity}
                    onValueChange = {(values) => {
                      const {value} = values;
                      this.setState({quantity: value})
                    }}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="center_name">หน่วย</label>
                  <input type="text"
                         className="form-control hidden"
                         value={this.state.unit}
                         name="unit"
                         placeholder="hidden"
                         onChange={this.handleChange}
                  />
                  <Autosuggest
                    suggestions={this.state.suggestions}
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestion}
                    inputProps={inputProps}
                    theme={{
                      input: 'form-control',
                      suggestion: suggestionDivStyle,
                      suggestionsList: 'list-unstyled ',
                      suggestionHighlighted: suggestionHighlightedDivStyle
                    }}

                  />
                </div>

                <div className="form-group">
                  <label htmlFor="note">หมายเหตุ</label>
                  <textarea
                    id="note"
                    name="note"
                    className="form-control"
                    value={this.state.note}
                    onChange={this.handleChange}
                    rows="3"
                    placeholder="หมายเหตุ"
                    maxLength={255}
                    />
                </div>

                    <div className={this.state.actionType === 'แก้ไข' ? "form-group" : "form-group hidden" }>
                      <label htmlFor="note">ไฟล์อะไหล่</label>
                      <input
                        className="form-control"
                        accept="image/*"
                        type="file"
                        id="spare_part_image"
                        name="spare_part_image"
                        ref="spare_part_image"
                        onChange={this.handleUploadFile}
                      />
                    </div>
              </div>

              <div className="card-footer text-right">
                <button type="reset"
                        className="btn btn-sm btn-danger mr-2"
                        onClick={this.resetFormState}
                >
                  <i className="fa fa-refresh"/> ยกเลิก
                </button>
                <button type="submit"
                        className={this.state.actionType==="แก้ไข"? "btn btn-sm btn-warning" :"btn btn-sm btn-primary"}
                >
                  <i className={this.state.actionType === "แก้ไข" ? "fa fa-edit" : "fa fa-save"}/>
                  &nbsp;{this.state.actionType}
                </button>

                <input type="hidden" name="id" value={this.state.id}/>

              </div>
            </form>
          </div>
        </div>

      </div>
    )
  }
}

export default SparePartList
