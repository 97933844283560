import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import 'moment/locale/th';
import { APIURL, IMAGE_FULL_URL } from '../../../config/config';
import axios from 'axios/index';
moment.locale('th');
// import TextareaAutosize from 'react-autosize-textarea';

export  default class CarReleaseForm extends Component {
  constructor (props) {
    super(props);
    this.state = {
      date_claim: new Date(),//moment(),
      claim_name: new Date(), //moment(),
      claim_mobile: '',
      claim1_list1: '1.\n2.\n3.',
      claim1_list2: '4.\n5.\n6.',
      car_release_list: [],
      line_error: false
    }

    this.handleDateClaim = this.handleDateClaim.bind(this);
    this.handleDateClaimName = this.handleDateClaimName.bind(this);
    this.handleChange = this.handleChange.bind(this);

  }

  componentDidMount() {
    this.loadCarRelease();
  }

  loadCarRelease() {
    var car_id = this.props.car.car_id;
    //console.log('car_id='+car_id);
    axios.get(APIURL + '/car_release/car/'+car_id)
      .then((res) => {
        // console.log(res);
        if (res.status === 200) {
          // this.toggleCarRelease()
          this.setState({
            car_release_list: res.data
          });
          // console.log(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleDateClaim(date) {
    this.setState({
      date_claim: date
    });
  }

  handleDateClaimName(date) {
    this.setState({
      claim_name: date
    });
  }

  handleChange(event) {
    var targetName = event.target.name;
    var value = event.target.value;
    var line = '';

    if(targetName === 'claim_mobile'){
      this.setState({
        claim_mobile: value
      });
    }else if(targetName === 'claim1_list1'){
       line = value.split('\n')
       // console.log('line='+line.length)
      if(line.length>3){
        this.setState({
          line_error: true
        });
        return;
      }
      this.setState({
        claim1_list1: value,
        line_error: false
      });
    }else if(targetName === 'claim1_list2'){
       line = value.split('\n')
      if(line.length>3){
        this.setState({
          line_error: true,
        });
        return;
      }
      this.setState({
        claim1_list2: value,
        line_error: false
      });
    }

  }

  render() {

    let tbodyList = this.state.car_release_list.map((carRelease, index) => {

      let image_url = ''
      if (carRelease.car_upload_filename.includes('https')) {
        image_url = carRelease.car_upload_filename
      } else {
        image_url = IMAGE_FULL_URL + carRelease.car_upload_filename
      }

      // <img id={'img_'+carRelease.car_release_id} src={IMAGE_THUMB_URL + carRelease.car_upload_filename} className="img-cars" alt="car"/>
      return (
        <tr key={index}>
          <td className="text-center" width="10%">{carRelease.car_release_id}</td>
          <td>
            วันที่ออกใบเคลม: {carRelease.release_date} <br/>
            ลูกค้ารับรถ: {carRelease.release_receive_date} <br/>
            เบอร์โทร: {carRelease.release_mobile} <br/>

            <img src={image_url}
                 className="img-cars"
                 alt="car"/>

          </td>
          <td>
            รายการ : <br/>
            {carRelease.release1_list1} <br/>
            {carRelease.release1_list1} <br/>

          </td>
          <td width="10%">
            {carRelease.status==="release"? 'ปล่อยรถ': ''}
          </td>
          <td className="align-top text-center" width="10%" >
            <button onClick={() => {
              this.props.onPrintClaim(carRelease, this.props.car)
            }}
                    type="button" className="btn btn-sm btn-primary btn-md-width">
              <i className="icon-printer"></i> พิมพ์
            </button>

            {/*<button
              className={carClaim.status==='complete'? 'hidden': 'btn btn-sm btn-warning btn-md-width'}
              onClick={() => {
                this.openClaimEdit(carClaim)
              }}
            >
              <i className="fa fa-edit"></i> แก้ไข
            </button>*/}

            {/*<button
              className={carClaim.status==='complete'? 'hidden': 'btn btn-sm btn-danger btn-md-width'}
              onClick={() => {
                this.openClaimDelete(carClaim)
              }}
            >
              <i className="fa fa-remove"></i> ลบ
            </button>*/}

          </td>
        </tr>
      );
    });


    let tbodyEmpty = ()=>{
      return (
        <tr className="text-center">
          <td colSpan={5}><span className="text-danger">ไม่พบข้อมูล</span></td>
        </tr>
      )};


    return (
      <div className="card card-accent-primary">
        <form action="" method="post" onSubmit={this.props.onSubmit}>
          <div className="card-header">
            <strong className="text-title">บันทึกปล่อยรถ</strong>
          </div>
          <div className="card-block">
            <div className="row">
              <div className="form-group col-sm-4">
              <label htmlFor="date_claim">วันที่ออกใบงาน</label>
              <DatePicker
                selected={this.state.date_claim}
                onChange={this.handleDateClaim}
                dateFormat="yyyy-MM-dd"
                type="text"
                className="form-control"
                id="claim_date"
                name="claim_date"
                placeholder="วันที่ออกใบงาน"
              />
              </div>

              <div className="form-group col-sm-4">
                <label htmlFor="claim_name">วันที่ลูกค้ารับรถ</label>
                <DatePicker
                  selected={this.state.claim_name}
                  onChange={this.handleDateClaimName}
                  dateFormat="YYYY-MM-dd"
                  type="text"
                  className="form-control"
                  id="claim_name"
                  name="claim_name"
                  placeholder="วันที่ลูกค้ารับรถ"
                />
              </div>

              <div className="form-group col-sm-4">
                <label htmlFor="claim_mobile">เบอร์โทร</label>
                <input
                  className="form-control"
                  id="claim_mobile"
                  name="claim_mobile"
                  placeholder="เบอร์โทร"
                  value={this.state.claim_mobile}
                  onChange={this.handleChange}
                />
              </div>

            </div>

            <div className="row">
              <div className="form-group col-sm-4">
                <label htmlFor="car_model_name">
                  รถยี่ห้อ : {this.props.car.car_brand_name}</label>
              </div>
              <div className="form-group col-sm-4">
                <label htmlFor="car_model_name">
                  รุ่น : {this.props.car.car_model_name} &nbsp;&nbsp;
                  สี : {this.props.car.car_color_name}
                </label>
              </div>
              <div className="form-group col-sm-4">
                <label htmlFor="car_model_name">ทะเบียน : {this.props.car.car_license_plate_new}</label>
              </div>
            </div>

            <div className="row">
              <div className="form-group col-sm-6">
                <label htmlFor="claim_mobile">รายการ</label>
                <textarea
                  id="claim1_list1"
                  name="claim1_list1"
                  className="form-control"
                  value={this.state.claim1_list1}
                  onChange={this.handleChange}
                  rows="8"
                  />

               {/* <TextareaAutosize
                  style={{ minHeight: 80, maxHeight: 120 }}
                  maxRows={3}
                  id="claim1_list1"
                  name="claim1_list1"
                  className="form-control"
                  value={this.state.claim1_list1}
                  onChange={this.handleChange}
                />*/}

              </div>
              <div className="form-group col-sm-6">
                <label htmlFor="claim_mobile">&nbsp;</label>
                <textarea
                  id="claim1_list2"
                  name="claim1_list2"
                  className="form-control"
                  value={this.state.claim1_list2}
                  onChange={this.handleChange}
                  rows="8"
                  />
              </div>
            </div>

            <div className={this.state.line_error? "row" : "hidden"}>
              <div className="form-group col-sm-12">
                <label className="text-danger">**พิมพ์ได้ไม่เกิน 3 บรรทัดต่อช่อง </label>
              </div>
            </div>


            <div className={this.state.car_release_list.length===0? "row hidden": "row"}>
              <div className="form-group col-sm-12">
                <table className="table table-striped">
                  <thead>
                  <tr>
                    <th className="text-center">#</th>
                    <th className="text-center" width="20%">วันออกใบงาน</th>
                    <th>รายการ</th>
                    <th>สถานะ</th>
                    <th className="text-center">จัดการ</th>
                  </tr>
                  </thead>
                  <tbody>
                  {(tbodyList.length>0)? tbodyList: tbodyEmpty()}
                  </tbody>
                </table>
              </div>
            </div>


          </div>
          <div className="card-footer text-right">
            <button type="reset"
                    onClick={this.props.onHandle.cancel}
                    className="btn btn-sm btn-danger mr-2">
              <i className="fa fa-refresh"/> ยกเลิก
            </button>
            <button type="submit"
                    className="btn btn-sm btn-primary"
            >
              <i className="fa fa-save"/> บันทึก
            </button>

            <input
              type="hidden"
              id="car_id"
              name="car_id"
              value={this.props.car.car_id}
            />

          </div>

        </form>
      </div>
    )
  }
}